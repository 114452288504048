import Router from 'vue-router'

import Dashboard from '@/views/Dashboard/Dashboard'
import Login from '@/views/Login/Login'
import NotFound from '@/views/NotFound'
import MealRoutes from '@/views/Meal/routes'
import ProfileRoutes from '@/views/Profile/routes'
import AdminRoutes from '@/views/Admin/routes'
//import ShopRoutes from '@/views/Shop/routes'
import OrderRoutes from '@/views/Orders/routes'
import ServiceRoutes from '@/views/Services/routes'
import EngagementRoutes from '@/views/Engagement/routes'
import MiscRoutes from '@/views/Misc/routes'


const router = new Router({
	mode: 'history',
	base: process.env.VUE_APP_ROUTER_BASE,
	routes: [
		// Main page
		{
			path: '/home',
			alias: '/',
			component: Dashboard,
			meta: {
				scopes: 'public'
			}
		},
		
		{
			path: '/login',
			component: Login,
			meta: {
				scopes: 'public'
			}
		},
		
		...AdminRoutes,
		...MealRoutes,
		...ProfileRoutes,
		...OrderRoutes,
		...ServiceRoutes,
		...EngagementRoutes,
		...MiscRoutes,
		
		// Misc pages
		{
			// 404 page
			path: '/404',
			component: NotFound
		}, {
			// Catch all remaining paths
			path: '/*',
			name: 'wildcard',
			component: NotFound
		},
	]
});

export default router;