<style lang="scss">
.video-container {
	position: relative;
	height: 100%;
	overflow: hidden;
	
	.scan-region-highlight {
		margin: 0 auto;
		border-radius: 30px;
		outline: rgba(0, 0, 0, .25) solid 50vmax;
	}
	.scan-region-highlight-svg {
		display: none;
	}
	.code-outline-highlight {
		stroke: rgba(255, 255, 255, .5) !important;
		stroke-width: 5 !important;
		stroke-dasharray: none !important;
	}
}

</style>
<template>
	<div class="h-full">
		
		<preloader v-if="loading" />
		
		<div v-if="attendance || activeError" class="text-center mt-14">
			<div v-if="attendance" class="text-3xl font-medium px-4 md:px-0">You have already claimed your attendance today</div>
			
			<div v-if="activeError">
				<div class="text-4xl font-medium">{{ activeError.message }}</div>
				
				<div class="text-2xl font-medium my-10">
					<div v-if="user"><span class="text-gray-400">{{ user.userID }}</span> {{ user.userName }}</div>
					<div class="text-gray-300" v-if="claimDate">{{ claimDate }}</div>
				</div>
				
				<hr class="hr">
				<div>
					<button class="button is-primary" @click="showCamera">Show Camera</button>
				</div>
			</div>
		</div>
		
		<!-- Camera container -->
		<div v-show="cameraShown" class="h-full overflow-x-hidden">
			<div class="video-container bg-black">
				<div class="absolute inset-x-0 text-center z-10 text-gray-200 bg-black bg-opacity-50 px-4 py-2">Please scan QR Code at V-Meal counter for attendance</div>
				<video class="object-cover h-full mx-auto" ref="camPreview"></video>
			</div>
		</div>
		
		
		
	</div>
</template>
<script>
import Container from '@/layouts/Container.vue'
import QRScanner from 'qr-scanner'
import { mapState } from 'vuex'

export default {
	components: {
		Container,
	},
	computed: {
		...mapState('Constants', [ 'MealErrorsUser' ]),
		activeError() {
			if (!this.errorCode) return null;
			return this.MealErrorsUser[this.errorCode];
		}
	},
	data() {
		return {
			loading: false,
			submitting: false,
			
			attendance: null,
			token: null,
			
			errorCode: '',
			user: null,
			claimDate: '',
			
			cameraShown: false,
			qrScanner: null,
			
			
		}
	},
	methods: {
		qrScanned(result) {
			this.qrScanner.stop();
			this.cameraShown = false;
			this.scanAttendance(result.data);
		},
		async scanAttendance(payload) {
			this.submitting = true;
			try {
				
				const res = await this.$api.request('POST', `meal/attendance/selfscan`, { payload });
				this.errorCode = res.code;
				
				// Display claim date if scan successful
				if (res.code == 'ok') {
					this.claimDate = res.date;
					this.user = res.user;
				}
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error scanning attendance');
				
			}
			this.submitting = false;
		},
		showCamera() {
			if (!this.qrScanner) {
				const videoEl = this.$refs.camPreview;
				this.qrScanner = new QRScanner(videoEl, result => this.qrScanned(result), {
					maxScansPerSecond: 5,
					highlightScanRegion: true,
					highlightCodeOutline: true,
				});
			}
			this.attendance = null;
			this.errorCode = '';
			this.qrScanner.start();
			this.cameraShown = true;
		}
	},
	async mounted() {
		this.loading = true;
		try {
			const res = await this.$api.request('GET', `meal/attendance`);
			if (!res.attendance) {
				this.attendance = null;
				this.token = res.token;
				
				this.showCamera();
				
			} else {
				this.attendance = res.attendance;
			}
			
		} catch(err) {
			console.error(err);
			this.$toast.error('Error fetching attendance record');
		}
		this.loading = false;
	},
	beforeDestroy() {
		if (this.qrScanner) {
			this.qrScanner.stop();
			this.qrScanner.destroy();
			this.qrScanner = null;
		}
	}
}
</script>