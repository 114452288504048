<template>
	<div>
		
		<div class="text-3xl font-semibold">Weekly Headcount</div>
		<hr class="hr">
		
		<preloader v-if="loading" />
		<div v-else class="grid grid-cols-5">
			<div v-for="(c, ci) in counts" :key="ci" class="text-center border">
				<div class="font-medium text-xl">{{ c.day }}</div>
				<div class="border-b font-medium">{{ c.date }}</div>
				<div class="text-4xl font-bold pt-2 text-gray-500">{{ c.total }}</div>
				<div class="text-lg font-medium text-gray-500">PAC: {{ c.pac || 0 }}, Non-PAC: {{ c.external || 0 }}</div>
			</div>
		</div>
		<hr class="hr">
		
		<div class="flex items-center justify-between">
			<button class="button is-primary" @click="advWeek(-1)">Prev Week</button>
			<button class="button is-primary" @click="advWeek(1)">Next Week</button>
		</div>
		
		
	</div>
</template>
<script>
import dayjs from 'dayjs'
export default {
	data() {
		return {
			loading: false,
			today: null,
			counts: [],
		}
	},
	methods: {
		async fetchData() {
			this.loading = true;
			try {
				
				if (!this.today) this.today = dayjs();
				const start = this.today.startOf('week').format('YYYY-MM-DD');
				const end = this.today.endOf('week').format('YYYY-MM-DD');
				
				const queries = [
					`start=${start}`,
					`end=${end}`,
				];
				const bins = await this.$api.request('GET',`meal/registration/count?${queries.join('&')}`);
				const weekdays = [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ]
				const weekCount = [];
				
				
				let d = dayjs(start);
				while (true) {
					const dateFmt = d.format('YYYY-MM-DD');
					if (d.day() > 0 && d.day() < 6) {
						
						const count = {
							total: 0,
							pacCount: 0,
							externalCount: 0,
							
							date: dateFmt,
							day: weekdays[d.day()],
							dayNum: d.day(),
						};
						
						const bin = bins[dateFmt];
						if (bin) {
							count.total = bin.total;
							count.pac = bin.pac;
							count.external = bin.external;
						}
						weekCount.push(count);
					}
					d = d.add(1, 'day');
					if (d.format('YYYY-MM-DD') == end) break;
				}
				
				this.counts = weekCount;
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error loading calendar');
			}
			this.loading = false;
		},
		advWeek(inc) {
			this.today = this.today.add(inc, 'week');
			this.fetchData();
		}
	},
	mounted() {
		this.fetchData();
	}
}
</script>