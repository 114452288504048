// Custom clickaway directive
export default {
	directives: {
		onClickaway: {
			bind(el, binding) {
				// Attach an event listener to the element
				el.clickOutsideEvent = function (event) {
					if (!(el == event.target || el.contains(event.target))) {
						if (binding.value) binding.value();
					}
				};
				document.body.addEventListener('click', el.clickOutsideEvent);
			},
			
			unbind(el) {
				document.body.removeEventListener('click', el.clickOutsideEvent);
			},
		}
	}
}