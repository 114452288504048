<template>
	<Container>
		<div class="text-3xl font-semibold">Cashier Settings</div>
		<hr class="hr">
		
		<preloader v-if="loading" />
		<div v-else class="space-y-4">
			<div>
				<div class="flex items-center space-x-2">
					<div class="flex-grow">
						<label class="font-medium mb-1">Terminal</label>
						<select class="input w-full" v-model="formData.terminalID">
							<option value="">No terminal connected</option>
							<option v-for="t in terminals" :key="t.terminalID" :value="t.terminalID">{{ t.name }}</option>
						</select>
					</div>
					<div>
						<label class="block">&nbsp;</label>
						<button class="button is-accent" :class="{ loading: submitting }" @click="ping">Test Connection</button>
					</div>
				</div>
			</div>
			
			
			<div class="">
				<label class="flex items-center space-x-2">
					<input type="checkbox" v-model="formData.queueNumEnabled">
					<span class="font-medium select-none">Enable Queue Number</span>
				</label>
			</div>
			
			
			<hr class="hr">
			<div>
				<button class="button is-primary" :class="{ loading: submitting }" @click="save">Save</button>
			</div>
			
		</div>
		
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import { mapState } from 'vuex'

export default {
	components: {
		Container,
	},
	computed: {
		...mapState('Cashier', [
			'terminalSettings',
			'queueNumEnabled',
		]),
	},
	data() {
		return {
			loading: false,
			submitting: false,
			
			terminals: [],
			formData: {
				terminalID: '',
				queueNumEnabled: false,
			},
		};
	},
	methods: {
		async ping() {
			if (!this.formData.terminalID) return;
			
			this.submitting = true;
			try {
				// Set terminal
				const terminal = this.terminals.find(t => t.terminalID == this.formData.terminalID);
				const res = await this.$devices.request('GET', `terminal/ping`, { host: terminal.host, port: terminal.port });
				if (res == 'ok') this.$toast.success('Connection success');
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Connection failed');
			}
			this.submitting = false;
		},
		
		async save() {
			this.submitting = true;
			try {
				if (this.formData.terminalID) {
					// Set terminal
					const terminal = this.terminals.find(t => t.terminalID == this.formData.terminalID);
					if (terminal) await this.$store.dispatch('Cashier/setTerminalIP', terminal);
				}
				this.$store.commit('Cashier/enableQueueNum', this.formData.queueNumEnabled);
				
				this.$emit('close');
				this.$toast.success('Settings saved');
			} catch(err) {
				console.error(err);
				this.$toast.error('Failed to save settings');
			}
			this.submitting = false;
		}
	},
	async mounted() {
		this.loading = true;
		// Load settings and initialize
		try {
			// Fetch terminal list
			this.terminals = await this.$api.request('GET', `terminals`);
			
			
			await this.$store.dispatch('Cashier/init');
			this.formData.queueNumEnabled = this.queueNumEnabled;
			this.formData.terminalID = '';
			
			
			if (this.terminalSettings) {
				const { host, port } = this.terminalSettings;
				const activeTerminal = this.terminals.find(t => t.host == host && t.port == port);
				if (activeTerminal) this.formData.terminalID = activeTerminal.terminalID;
			}
			
			
			
		} catch(err) {
			console.error(err);
			this.$toast.error('Failed to load settings');
		}
		
		this.loading = false;
	}
}
</script>