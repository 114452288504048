<template>
	<Container>
		
		<div class="text-3xl font-semibold">Request Service</div>
		<hr class="hr">
		
		<preloader v-if="loading" />
		<div v-else class="md:flex md:items-start md:space-x-2 space-x-0 space-y-2 md:space-y-0">
			<div class="md:w-1/2 md:flex-shrink-0 space-y-2">
				<div>
					<label class="label">Event Name</label>
					<input type="text" class="input w-full" v-model.trim="formData.eventName" placeholder="Event name">
				</div>
				
				<div>
					<label class="label">Purpose</label>
					<select v-model="formData.eventType" class="input w-full">
						<option value="" disabled>Select event type</option>
						<option v-for="t in ServiceEventType" :value="t">{{ t }}</option>
					</select>
				</div>
				<div v-if="formData.eventType == 'Others'">
					<label class="label">Real Purpose</label>
					<input type="text" class="input w-full" v-model.trim="formData.eventTypeRemarks" placeholder="Please fill in your purpose">
				</div>
				
				<div>
					<label class="label">Event Date</label>
					<Datepicker v-model="formData.eventDate" v-bind="{
						inputClass: 'input w-full',
						placeholder: 'Event date',
						minDate: limitStartDate,
					}" />
				</div>
				
				
				<div>
					<label class="label">Customer Type</label>
					<select v-model="formData.customerType" class="input w-full">
						<option value="" disabled>Select customer type</option>
						<option v-for="t in CustomerType" :value="t">{{ t }}</option>
					</select>
				</div>
				<div v-if="hasCustomerType2">
					<label class="label">Customer Type (2)</label>
					<select v-model="formData.customerType2" class="input w-full">
						<option value="" disabled>Select customer type (2)</option>
						<option value="old">Existing</option>
						<option value="new">Potential</option>
					</select>
				</div>
				<div v-if="formData.customerType == 'Others'">
					<label class="label">Real Customer Type</label>
					<input type="text" class="input w-full" v-model.trim="formData.customerTypeRemarks" placeholder="Please fill in actual customer type">
				</div>
				
				
				<div>
					<label class="label">Customer Name</label>
					<input type="text" class="input w-full" v-model.trim="formData.customerName" placeholder="Customer name">
				</div>
				<div>
					<label class="label">Customer Company</label>
					<input type="text" class="input w-full" v-model.trim="formData.customerCompany" placeholder="Customer company">
				</div>
				<div>
					<label class="label">Remarks</label>
					<textarea class="input w-full" v-model.trim="formData.remarks" placeholder="eg: venue of event, extra arrangement, etc"></textarea>
				</div>
				
			</div>
			
			
			
			<div class="md:flex-grow space-y-2">
				
				<div v-if="onBehalf" class="space-y-2">
					<div>
						<label class="label">On Behalf</label>
						<div class="flex items-center space-x-2">
							<input type="text" class="input w-full" disabled :value="onBehalf.userName">
							<button class="button" @click="onBehalf = null">Clear</button>
						</div>
					</div>
					
					<!-- Company selection -->
					<div>
						<label class="label">Company</label>
						<input type="text" class="input w-full" :value="formData.company" readonly>
					</div>
					
					
					<!-- BU selection -->
					<div>
						<label class="label">BU</label>
						<input type="text" class="input w-full" :value="formData.bu" readonly>
					</div>
					
					<!-- Dept selection -->
					<div>
						<label class="label">Department</label>
						<input type="text" class="input w-full" :value="formData.dept" readonly>
					</div>
				</div>
				<div v-else>
					<label class="label">On Behalf</label>
					<SuggestionInput
						@selected="setOnBehalf"
						v-bind="{
							inputClass: 'input w-full',
							placeholder: 'Scan or type user name to search',
							suggest: suggestUser,
						}">
						
						<template v-slot="{ item }">
							<span class="font-medium mr-2">{{ item.userID }}</span>
							<span class="">{{ item.userName }}</span>
						</template>
						
					</SuggestionInput>
				</div>
				
				<!-- Group selections -->
				<div v-for="(group, key) in productGroups" :key="key">
					<label class="label">{{ groupNames[key] }} ({{ groupTimes[key] }})</label>
					<div class="flex items-center gap-2">
						<select class="input w-full" v-model="formData[key]">
							<option value="">Not required</option>
							<option v-for="item in group" :value="`${item.productID}:${item.variantID}`">
								{{ item.productName }}: {{ item.variantName }} (RM {{ item.price }})
							</option>
						</select>
						
						<input type="number" class="input w-1/3" v-model="formData[`${key}Count`]" placeholder="Pax">
					</div>
				</div>
				
				<div v-if="formData.vmeal && isVVIPMeal">
					<label class="label">V-Meal Cuisine</label>
					<select class="input w-full" v-model="formData.mealCuisine">
						<option :value="''">Follow menu</option>
						<option v-for="ct in cuisineTypes" :value="ct">{{ ct }}</option>
					</select>
				</div>
				
				
			</div>
			
		</div>
		<hr class="hr">
		
		<div>
			<button class="button is-primary" :class="{ loading: submitting }" @click="save">Save</button>
		</div>
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import Datepicker from '@/components/Datepicker'
import SuggestionInput from '@/components/SuggestionInput.vue'
import dayjs from 'dayjs'
import { mapState } from 'vuex'

export default {
	props: [ 'serviceID' ],
	components: {
		Container,
		Datepicker,
		SuggestionInput,
	},
	computed: {
		...mapState('Constants', [ 'ServiceEventType', 'CustomerType' ]),
		isVVIPMeal() {
			if (!this.formData.vmeal) return false;
			const [ productID, variantID ] = this.formData.vmeal.split(':');
			const pg = this.productGroups.vmeal;
			
			const v = pg.find(p => p.productID == productID && p.variantID == variantID);
			if (!v) return false;
			return v.variantName == 'VVIP';
		},
		limitStartDate() {
			let startDate = dayjs().toDate();
			if (this.$store.state.Auth.scopes.includes('admin:service')) return startDate;
			
			return dayjs().add(4, 'day').toDate();
		},
		hasCustomerType2() {
			if (!this.formData.customerType) return false;
			return [ 'Customer', 'Vendor', 'SCP/Distributor' ].includes(this.formData.customerType);
		}
	},
	data() {
		return {
			loading: false,
			submitting: false,
			
			locations: [
				'Cafeteria',
				'VIP Dining Room',
				'V-Lounge',
				'Anywhere you like',
			],
			cuisineTypes: [
				'Japanese',
				'Western',
				'Chinese',
				'Local',
				'Thai',
				'Korea',
				'Malay',
				'Malay (Nasi Kandar)',
			],
			groupNames: {
				vmeal: 'V-Meal',
				vrefreshment: 'V-Refreshment',
				vsavor: 'V-Savor',
			},
			groupTimes: {
				vmeal: '12:00 pm',
				vrefreshment: '9:00 am',
				vsavor: '3:30 pm',
			},
			productGroups: {},
			companyList: [],
			buList: [],
			deptList: [],
			
			onBehalf: null,
			formData: {
				eventName: '',
				eventType: '',
				eventTypeRemarks: '',
				eventDate: null,
				customerType: '',
				customerType2: '',
				customerTypeRemarks: '',
				customerName: '',
				customerCompany: '',
				mealCuisine: '',
				remarks: '',
				
				onBehalf: '',
				company: '',
				bu: '',
				dept: '',
				
				vmeal: '',
				vrefreshment: '',
				vsavor: '',
				vmealCount: '',
				vrefreshmentCount: '',
				vsavorCount: '',
			},
		};
	},
	methods: {
		async setOnBehalf(user) {
			this.onBehalf = user;
			this.formData.onBehalf = user.userID;
			this.formData.company = user.company;
			this.formData.bu = user.bu;
			this.formData.dept = user.dept;
		},
		async suggestUser(q) {
			const res = await this.$api.request('GET', `suggest/users?q=${encodeURIComponent(q)}`);
			return res;
		},
		
		verify() {
			if (!this.formData.eventName) {
				this.$toast.error('Please enter event name');
				return false;
			}
			if (!this.formData.eventType) {
				this.$toast.error('Please select event type');
				return false;
			}
			if (this.formData.eventType == 'Others' && !this.formData.eventTypeRemarks) {
				this.$toast.error('Please enter real event type');
				return false;
			}
			if (!this.formData.eventDate) {
				this.$toast.error('Please select event date');
				return false;
			}
			if (!this.formData.customerType) {
				this.$toast.error('Please select customer type');
				return false;
			}
			if (this.hasCustomerType2 && !this.formData.customerType2) {
				this.$toast.error('Please select customer type 2');
				return false;
			}
			if (this.formData.customerType == 'Others' && !this.formData.customerTypeRemarks) {
				this.$toast.error('Please fill in actual customer type');
				return false;
			}
			if (!this.formData.customerName) {
				this.$toast.error('Please enter customer name');
				return false;
			}
			if (!this.formData.customerCompany) {
				this.$toast.error('Please select customer company');
				return false;
			}
			
			if (this.formData.vmeal && !this.formData.vmealCount) {
				this.$toast.error('Please enter headcount for all requested services');
				return false;
			}
			if (this.formData.vrefreshment && !this.formData.vrefreshmentCount) {
				this.$toast.error('Please enter headcount for all requested services');
				return false;
			}
			if (this.formData.vsavor && !this.formData.vsavorCount) {
				this.$toast.error('Please enter headcount for all requested services');
				return false;
			}
			
			
			return true;
		},
		async save() {
			if (!this.verify()) return;
			
			this.submitting = true;
			try {
				const formData = { ...this.formData };
				formData.eventDate = dayjs(formData.eventDate).format('YYYY-MM-DD');
				
				// Override others field
				if (formData.eventType == 'Others') formData.eventType = formData.eventTypeRemarks;
				if (formData.customerType == 'Others') formData.customerType = formData.customerTypeRemarks;
				if (!this.hasCustomerType2) formData.customerType2 = '';
				
				if (this.serviceID) {
					await this.$api.request('PUT', `services/${this.serviceID}`, formData);
					this.$toast.success('Service request updated');
				} else {
					await this.$api.request('POST', `services`, formData);
					this.$router.replace('/services');
					
					this.$toast.success('Service request created');
				}
				
				
				
				
			} catch(err) {
				this.$toast.error('Error submitting form');
				console.error(err);
			}
			this.submitting = false;
		}
	},
	async mounted() {
		this.loading = true;
		try {
			const { company, bu, dept } = await this.$api.request('GET', `misc/bu`);
			this.companyList = company.filter(c => c.company);
			this.buList = bu.filter(b => b.bu);
			this.deptList = dept.filter(d => d.dept);
			
			
			// Initialize product groups
			const productGroups = {};
			const products = await this.$api.request('GET', 'services/products');
			for (const i in products) {
				const p = products[i];
				if (!productGroups[p.serviceGroup]) productGroups[p.serviceGroup] = [];
				productGroups[p.serviceGroup].push(p);
			}
			this.productGroups = productGroups;
			
			
			const loadServiceID = this.serviceID || this.$route.query.copy;
			if (loadServiceID) {
				// Fetch service
				const res = await this.$api.request('GET', `services/${loadServiceID}`);
				this.formData.eventName = res.eventName;
				this.formData.eventType = res.eventType;
				this.formData.eventDate = res.eventDate;
				this.formData.customerType = res.customerType;
				this.formData.customerType2 = res.customerType2;
				this.formData.customerName = res.customerName;
				this.formData.customerCompany = res.customerCompany;
				this.formData.mealCuisine = res.mealCuisine;
				this.formData.remarks = res.remarks;
				this.formData.company = res.company;
				this.formData.bu = res.bu;
				this.formData.dept = res.dept;
				this.formData.vmeal = res.vmeal;
				this.formData.vrefreshment = res.vrefreshment;
				this.formData.vsavor = res.vsavor;
				this.formData.vmealCount = res.vmealCount;
				this.formData.vrefreshmentCount = res.vrefreshmentCount;
				this.formData.vsavorCount = res.vsavorCount;
				
				
				// Populate the conditional fields
				if (!this.ServiceEventType.includes(this.formData.eventType)) {
					this.formData.eventTypeRemarks = this.formData.eventType;
					this.formData.eventType = 'Others';
				}
				if (!this.CustomerType.includes(this.formData.customerType)) {
					this.formData.customerTypeRemarks = this.formData.customerType;
					this.formData.customerType = 'Others';
				}
				
				
				if (res.behalf) this.setOnBehalf(res.behalf);
			}
			
			
			
		} catch(err) {
			console.error(err);
			this.$toast.error('Error loading page');
		}
		this.loading = false;
	}
}
</script>