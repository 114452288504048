<template>
	<div>
		<div>
			<router-link to="/meal/registration"
				class="text-lg font-medium text-accent-500 hover:text-accent-300 my-3">
				Registration &raquo;
			</router-link>	
		</div>
		
		
		<div class="grid grid-cols-5 gap-2">
			<div v-for="(r, ri) in registrations"
				:key="ri"
				@click="selectDate(r)"
				:class="dateClass(r)"
				class="text-center md:text-left md:flex md:items-center select-none cursor-pointer p-4 rounded-lg">
				<div class="py-2 md:px-2 md:py-0">
					<icon v-if="r.registered" :data="mdiCheckCircle" class="w-10 h-10 text-success-500" />
					<icon v-else :data="mdiCloseCircle" class="w-10 h-10 text-danger-500" />
				</div>
				<div class="leading-tight">
					<div class="text-gray-500 text-sm font-medium">{{ r.day }}</div>
					<div class="font-medium text-xl">{{ r.date }}</div>
				</div>
			</div>
		</div>
		
	</div>
</template>
<script>
import { mdiCheckCircle, mdiCloseCircle } from '@mdi/js'
import dayjs from 'dayjs'

export default {
	props: [ 'selectedDate', 'registrations' ],
	computed: {
		today() {
			return dayjs().format('D MMM');
		}
	},
	data() {
		return {
			mdiCheckCircle,
			mdiCloseCircle,
		};
	},
	methods: {
		dateClass(r) {
			if (r.rawDate != this.selectedDate) {
				return 'hover:bg-accent-50 transition-colors';
			} else {
				return 'bg-accent-100';
			}
		},
		selectDate(r) {
			this.$emit('select', r.rawDate);
		}
	}
}
</script>