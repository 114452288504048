// Event bus
import mitt from 'mitt'
export const emitter = mitt();



export const debounce = (callback, delay = 250) => {
	let interval;
	return (...args) => {
		clearTimeout(interval);
		interval = setTimeout(() => {
			interval = null;
			callback(...args);
		}, delay);
	}
}


// Card utils
function reverseBytes(hexStr) {
	let hexStrReversed = '';
	while (hexStr != '') {
		hexStrReversed = `${hexStr.substr(0, 2)}${hexStrReversed}`;
		hexStr = hexStr.substr(2);
	}
	return hexStrReversed.toUpperCase();
}


export function decToHex(decStr) {
	if (!decStr) return '';
	let hexStr = reverseBytes(parseInt(decStr).toString(16).padStart(8, '0'));
	return hexStr.padStart(8, '0');
}

export function hexToDec(hexStr) {
	if (!hexStr) return '';
	let decStr = parseInt(reverseBytes(hexStr), 16).toString();
	return decStr.padStart(10, '0');
}
