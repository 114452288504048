import Engagement1v1 from './Engagement1v1/Engagement1v1.vue'
import EngagementStatus from './Engagement1v1/EngagementStatus'

export default [{
	path: '/engagement/1v1',
	component: Engagement1v1,
}, {
	path: '/engagement/1v1/status',
	component: EngagementStatus,
	meta: {
		scopes: [ 'engager' ]
	}
}]