<template>
	<Container>
		
		<div class="flex items-center justify-between">
			<div class="text-2xl font-semibold">V-Meal Attendance Scan</div>
			<div class="font-medium text-white">
				<div v-if="terminalConnected" class="bg-success-500 px-2 py-1 rounded">Connected</div>
				<div v-else class="bg-danger-500 px-2 py-1 rounded">Disconnected</div>
			</div>
		</div>
		<hr class="hr my-2">
		
		
		<div class="md:flex md:space-x-4">
			
			<!-- Left pane -->
			<div class="md:w-1/3 md:space-y-4">
				<div>
					<label class="label">Scan Badge</label>
					<input type="text" class="input w-full" placeholder="Scan badge here" ref="badgeInput" v-model.trim="badgeNum" @keydown.enter="scanBadge">
				</div>
				<hr class="hr">
				
				<!-- Active user -->
				<div class="px-4">
					<div v-if="user">
						<div class="text-2xl font-medium">{{ user.userID }} {{ user.userName }}</div>
						<div class="text-gray-400">{{ user.badgeNum }}</div>
						<div class="font-medium text-gray-400">{{ user.bu }} - {{ user.dept }}</div>
					</div>
					
					<div v-if="activeError" class="font-medium text-lg">
						<div :class="activeError.class">{{ activeError.message }}</div>
					</div>
				</div>
				<hr class="hr">
				
				<div class="space-y-2">
					<div v-for="(h, hi) in scanHistory" :key="hi" class="border-b border-gray-200 px-2 py-1">
						<div class="flex items-baseline justify-between">
							<div class="text-lg font-medium">{{ h.user.userID }} {{ h.user.userName }}</div>
							<div class="font-medium text-gray-400 inline-flex gap-2">
								<div>{{ h.user.badgeNum }}</div>
								<div>{{ h.user.bu }} - {{ h.user.dept }}</div>
							</div>
						</div>
						
						<div class="flex items-baseline justify-between">
							<div class="font-medium">
								<div v-if="h.errorCode" :class="MealErrorsAdmin[h.errorCode].class">{{ MealErrorsAdmin[h.errorCode].message }}</div>
								<div v-else class="text-success-500">Scan success</div>
							</div>
							<div class="text-sm text-gray-400">{{ h.timestamp }}</div>
						</div>
					</div>
				</div>
				
			</div>
			
			
			<!-- Right pane -->
			<div class="flex-grow">
				
				<TabBar v-model="activeTab" v-bind="{ tabs: visibleTabs }" />
				<transition mode="out-in" name="fade">
					<component
						:is="tabs[activeTab].component"
						v-bind="{
							user,
							socket,
						}" />
				</transition>
				
				
			</div>
			
		</div>
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import TabBar from '@/components/TabBar.vue'
import QRScan from './QRScan.vue'
import PenaltyPayment from './PenaltyPayment.vue'
import WalkInPayment from './WalkInPayment.vue'
import { createWS } from '@/utils/ws'
import { mapState } from 'vuex'
import dayjs from 'dayjs'

export default {
	components: {
		Container,
		TabBar,
		QRScan,
		PenaltyPayment,
		WalkInPayment,
	},
	computed: {
		...mapState('Constants', [ 'MealErrorsAdmin' ]),
		activeError() {
			if (!this.errorCode) return null;
			return this.MealErrorsAdmin[this.errorCode];
		},
		showPayment() {
			return this.errorCode == 'unregistered' && this.user;
		},
		visibleTabs() {
			let tabs = { ...this.tabs };
			if (!this.terminalConnected) {
				delete tabs.penalty;
				delete tabs.walkIn;
			}
			return tabs;
		}
	},
	data() {
		return {
			submitting: false,
			
			intervalFn: null,
			terminalConnected: false,
			
			errorCode: '',
			badgeNum: '',
			user: null,
			timestamp: null,
			
			scanHistory: [],
			
			invalidSound: null,
			
			activeTab: 'qr',
			tabs: {
				qr: {
					label: 'Self Scan QR',
					component: 'QRScan'
				},
				penalty: {
					label: 'Penalty Payment',
					component: 'PenaltyPayment',
				},
				walkIn: {
					label: 'Walk In Payment',
					component: 'WalkInPayment',
				},
			},
			
			socket: null,
			
			
		};
	},
	methods: {
		initSocket() {
			const s = createWS(this.$store.state.Auth.jwt);
			s.on('meal/meal_scan', res => this.onScan(res));
			
			this.socket = s;
		},
		onScan(res) {
			if (this.user) {
				// Add current record to history first
				this.scanHistory.unshift({
					errorCode: this.errorCode,
					user: this.user,
					timestamp: this.timestamp,
				});
				while (this.scanHistory.length > 5) {
					this.scanHistory.pop();
				}
			}
			
			
			this.errorCode = res.code;
			this.user = res.user;
			this.timestamp = dayjs().format('h:mm:ss a');
			
			// Switch context based on scan result
			if (this.errorCode == 'penalty') this.activeTab = 'penalty';
			else if (this.errorCode == 'unregistered') this.activeTab = 'walkIn';
			else this.activeTab = 'qr';
			
			// Play sound if invalid
			if (this.errorCode == 'penalty' || this.errorCode == 'unregistered' || this.errorCode == 'invalid_card') {
				this.invalidSound.play();
			}
			
			// Clear badgeNumber field on success
			this.$refs.badgeInput.select();
		},
		
		async scanBadge() {
			this.submitting = true;
			try {
				//this.errorCode = '';
				
				const res = await this.$api.request('POST', `meal/attendance/scan`, { badgeNum: this.badgeNum });
				this.onScan(res);
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error scanning badge');
			}
			this.submitting = false;
		},
		
		
		async pingTerminal() {
			if (this.paymentModalShown) return;
			
			try {
				await this.$store.dispatch('Cashier/pingTerminal');
				
			} catch(err) {
				console.error(err);
				this.terminalConnected = false;
				await this.$confirm({
					title: 'Terminal Disconnected',
					message: 'Please restart terminal service, then refresh this page',
					buttons: 'OK',
				});
			}
		}
	},
	async mounted() {
		// Load sound
		this.invalidSound = new Audio('/sfx/alarm.mp3');
		
		// Load settings and initialize
		try {
			await this.$store.dispatch('Cashier/init');
			
			// Init socket
			this.initSocket();
			
			this.terminalConnected = true;
			this.intervalFn = setInterval(this.pingTerminal, 60000);
			
			
		} catch(err) {
			if (err == 'terminal_disconnected') {
				this.terminalConnected = false;
				console.error('Terminal not connected');
				//await this.$confirm({
				//	title: 'Terminal Disconnected',
				//	message: 'Terminal is not connected.\nPayment will not function properly.',
				//	buttons: 'OK'
				//});
			} else {
				console.error(err);
				this.$toast.error('Failed to load settings');
			}
		}
	},
	beforeDestroy() {
		if (this.socket) this.socket.disconnect();
		if (this.intervalFn) {
			clearInterval(this.intervalFn);
			this.intervalFn = null;
		}
	}
}
</script>