
export default function($store) {
	
	return function (to, from, next) {
		if (to.meta.scopes == 'public') return next();
		//if (ignored.includes(to.path)) return next();
		
		// If not logged in, redirect to login page
		const authenticated = $store.getters['Auth/authenticated'];
		if (!authenticated) return next('/login');
		
		// If user status is pending, send to onboarding page
		const pending = $store.state.Auth.pending;
		if (pending) return next('/onboarding');
		
		
		// If no scopes set, proceed
		if (!to.meta.scopes) return next();
		else {
			let allowed = false;
			
			// Check if scope exists
			const userScopes = $store.state.Auth.scopes;
			for (const i in to.meta.scopes) {
				const s = to.meta.scopes[i];
				if (userScopes.includes(s)) {
					allowed = true;
					break;
				}
			}
			
			if (!allowed) return next('/404');
		}
		
		
		return next();
	}	
}