<template>
	<!-- Checkout list -->
	<div class="h-full overflow-y-auto px-2">
		<table class="table w-full">
			<thead>
				<tr>
					<th>Product</th>
					<th width="80px">Qty</th>
					<th width="160px">Unit Price (RM)</th>
					<th width="160px">Tax (RM)</th>
					<th width="160px">Total Price (RM)</th>
					<th width="40px"></th>
				</tr>
			</thead>
			<tbody>
				
				<tr v-if="!checkoutItems.length">
					<td colspan="5">No items added</td>
				</tr>
				
				<!-- Items -->
				<tr v-for="(it, ii) in checkoutItems" :key="ii">
					<td>
						<span>{{ it.productName }} : {{ it.variantName }}</span>
						<span v-if="it.isTaxable" class="font-medium text-white text-xs bg-danger-500 ml-2 px-1 rounded-sm">
							SST
						</span>
					</td>
					<td>
						<input type="number" class="input w-full text-sm px-2 py-1" v-model="it.qty">
					</td>
					<td>{{ it.price }}</td>
					<td>{{ taxPrice(it) }}</td>
					<td>{{ totalPrice(it) }}</td>
					<td>
						<button class="button is-danger px-2 py-1" @click="removeItem(ii)">
							<icon :data="mdiClose" class="w-5 h-5" />
						</button>
					</td>
				</tr>
				
				
			</tbody>
		</table>
	</div>
</template>
<script>
import { mdiClose } from '@mdi/js'
import { mapState } from 'vuex'
export default {
	props: [ 'checkoutItems' ],
	data() {
		return {
			mdiClose,
		};
	},
	computed: {
		...mapState('Constants', [ 'SST_RATE', 'SST_INCLUSIVE' ]),
	},
	methods: {
		removeItem(index) {
			this.checkoutItems.splice(index, 1);
		},
		taxPrice(it) {
			if (!it.isTaxable) return '0.00';
			
			const price = it.qty * it.price;
			let sstRate = this.SST_RATE / 100.0;
			if (this.SST_INCLUSIVE) return (price * sstRate).toFixed(2);
			else return (price * (this.SST_RATE / 100.0)).toFixed(2);
		},
		totalPrice(it) {
			const price = it.qty * it.price;
			if (this.SST_INCLUSIVE) return price.toFixed(2);
			
			let sstRate = 1;
			if (it.isTaxable) sstRate += (this.SST_RATE / 100);
			return (price * sstRate).toFixed(2);
		}
	}
}
</script>