
<template>
	<div>
		<div class="text-2xl font-semibold">Order #{{ order.label }}</div>
		<hr class="hr">
		
		<div class="text-center">
			<div class="font-medium text-2xl text-gray-400">Payment Amount</div>
			<div class="font-medium text-4xl">RM {{ order.paymentAmount }}</div>
		</div>
		<hr class="hr">
		
		<div class="text-center">
			<button class="button is-accent" :class="{ loading: submitting }" @click="makePayment">Pay Now</button>
		</div>
		
	</div>
</template>
<script>
export default {
	props: [ 'order' ],
	data() {
		return {
			submitting: false,
		};
	},
	methods: {
		async makePayment() {
			this.submitting = true;
			try {
				await this.$api.request('POST', `orders/${this.order.orderID}/paid`);
				this.$emit('paid', this.order.orderID);
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error making payment');
			}
			this.submitting = false;
		}
	}
}
</script>