<template>
	<div class="h-full flex flex-col">
		
		<div>
			<div class="flex space-x-2">
				<input type="text" class="input w-full" placeholder="Search or scan barcode" v-model.trim="query" @keydown.enter="search">
				<button class="button" @click="clear">Clear</button>
			</div>
			
			<hr class="hr">
		</div>
		<div class="overflow-y-auto">
			<!-- Search results -->
			<preloader v-if="fetching" />
			<div v-else>
				<div v-for="(it, ii) in items"
					:key="ii"
					@click="selectItem(it)"
					class="px-2 py-0.5 hover:bg-gray-100 cursor-pointer select-none border-b border-gray-200 flex items-center">
					<div class="flex-grow">
						<div class="font-medium">{{ it.product.productName }}</div>
						<div class="text-sm text-gray-400">{{ it.variantName }}</div>
						
						<div>
							<div v-if="it.isInventory && it.stockQty == '0.00'"
								class="font-medium text-sm text-white bg-danger-500 inline-block px-2 rounded">
								Out of Stock
							</div>
						</div>
					</div>
					<div class="font-medium">RM {{ it.price }}</div>
				</div>
			</div>
		</div>
		
	</div>
</template>
<script>
export default {
	data() {
		return {
			fetching: false,
			query: '',
			items: [],
		};
	},
	methods: {
		async search() {
			this.fetching = true;
			try {
				const res = await this.$api.request('GET', `products/search?q=${encodeURIComponent(this.query)}`);
				this.items = res;
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error fetching items');
			}
			this.fetching = false;
		},
		selectItem(it) {
			this.$emit('select', it);
		},
		clear() {
			this.query = '';
			this.items = [];
		}
	},
}
</script>