<template>
	<div>
		<div class="text-center py-2">
			<div class="text-2xl font-medium text-gray-400 my-4">Please scan QR for attendance</div>
			<transition mode="out-in" name="fade">
				<img width="400" height="400" class="mx-auto" :src="dataURL" :key="dataURL" />
			</transition>
		</div>
		
		<div class="text-center mt-4">
			<button class="button is-primary" :class="{ loading }" @click="generateQR">Refresh</button>
		</div>
		
	</div>
</template>
<script>
import QRCode from 'qrcode'

export default {
	data() {
		return {
			loading: false,
			dataURL: null,
			refreshFn: null,
		};
	},
	methods: {
		async generateQR() {
			this.loading = true;
			try {
				const hash = await this.$api.request('GET', `meal/attendance/qrhash`);
				this.dataURL = await QRCode.toDataURL(hash, {
					margin: 0,
					width: 400,
					color: {
						light: '#0000',
					}
				});
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error loading QR Code');
			}
			this.loading = false;
		}
	},
	mounted() {
		this.generateQR();
		this.refreshFn = setInterval(() => this.generateQR(), 30000);
	},
	beforeDestroy() {
		clearInterval(this.refreshFn);
	}
}
</script>