<template>
	<Container>
		<div class="text-3xl font-semibold">Voucher Issue</div>
		<hr class="hr">
		
		
		<div>
			<SuggestionInput
				@selected="addUser"
				v-bind="{
					inputClass: 'input w-full',
					placeholder: 'Start typing to search user',
					suggest: suggestUser,
				}">
				
				<template v-slot="{ item }">
					<span class="font-medium mr-4">{{ item.userID }}</span>
					<span class="">{{ item.userName }}</span>
				</template>
				
			</SuggestionInput>
		</div>
		<hr class="hr">
		
		
		<div class="flex justify-end space-x-2 mb-4">
			<input type="text" class="input" placeholder="Enter voucher amount" v-model="issueAmount">
			
			<select class="input" v-model="catID">
				<option value="">N/A</option>
				<option v-for="c in categories" :value="c.catID">{{ c.catName }}</option>
			</select>
			
			<input type="text" class="input flex-grow" placeholder="Remarks" v-model.trim="remarks">
			<button class="button is-accent" :class="{ loading: submitting }" @click="issueVoucher">Issue Voucher</button>
		</div>
		
		
		<table class="table w-full">
			<thead>
				<tr>
					<th width="150px">UserID</th>
					<th>Name</th>
					<th width="100px">BU</th>
					<th width="100px">Dept</th>
					<th width="100px"></th>
				</tr>
			</thead>
			<tbody>
				<tr v-if="!users.length">
					<td colspan="5">No users added</td>
				</tr>
				
				<tr v-for="u in users" :key="u.userID">
					<td>{{ u.userID }}</td>
					<td>{{ u.userName }}</td>
					<td>{{ u.bu }}</td>
					<td>{{ u.dept }}</td>
					<td class="text-right">
						<button class="button is-danger px-2 py-1" @click="removeUser(u.userID)">
							<icon :data="mdiClose" class="w-5 h-5" />
						</button>
					</td>
				</tr>
			</tbody>
		</table>
		
		
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import SuggestionInput from '@/components/SuggestionInput.vue'
import { mdiClose } from '@mdi/js'

export default {
	components: {
		Container,
		SuggestionInput,
	},
	data() {
		return {
			mdiClose,
			fetching: false,
			submitting: false,
			
			issueAmount: '',
			catID: '',
			remarks: '',
			users: [],
			categories: [],
		};
	},
	methods: {
		async suggestUser(q) {
			try {
				const users = await this.$api.request('GET', `suggest/users?q=${encodeURIComponent(q)}`)
				return users;
			} catch(err) {
				console.error(err);
				this.$toast.error('Error fetching user suggestions');
			}
			return [];
		},
		addUser(user) {
			// Check if user already exists in list
			const exist = this.users.find(u => u.userID == user.userID);
			if (exist) return;
			
			this.users.push(user);
		},
		removeUser(userID) {
			const index = this.users.findIndex(u => u.userID == userID);
			if (index >= 0) this.users.splice(index, 1);
		},
		
		async issueVoucher() {
			const amount = parseFloat(this.issueAmount);
			if (isNaN(amount)) {
				this.$toast.error('Please enter valid voucher amount');
				return;
			}
			
			if (!this.catID) {
				this.$toast.error('Please select voucher category');
				return;
			}
			
			if (!this.users.length) {
				this.$toast.error('No users in list');
				return;
			}
			
			const confirm = await this.$confirm({
				title: 'Confirm Voucher Issue',
				message: `Confirm to issue RM${amount.toFixed(2)} voucher to users in the list?`,
				buttons: 'YES_NO',
			});
			if (!confirm) return;
			
			
			this.submitting = true;
			try {
				
				await this.$api.request('POST', 'vouchers/issue', {
					amount,
					catID: this.catID,
					remarks: this.remarks,
					users: this.users.map(u => u.userID),
				});
				
				this.users = [];
				this.issueAmount = '';
				this.remarks = '';
				
				this.$toast.success('Vouchers issued successfully');
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Failed to issue voucher');
			}
			this.submitting = false;
		}
	},
	async mounted() {
		this.loading = true;
		try {
			this.categories = await this.$api.request('GET', `vouchers/categories`);
			
			
		} catch(err) {
			console.error(err);
			this.$toast.error('Error loading page');
		}
		this.loading = false;
	}
}
</script>