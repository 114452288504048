import ProductList from './ProductList.vue'
import ProductForm from './ProductForm/ProductForm.vue'
import CategoryList from './Categories/CategoryList.vue'

export default [{
	// List of all products
	path: '/admin/products',
	component: ProductList,
	props: true,
	meta: {
		scopes: [ 'admin:shops' ]
	}
}, {
	// Create new product
	path: '/admin/products/new',
	component: ProductForm,
	props: true,
	meta: {
		scopes: [ 'admin:shops' ]
	}
}, {
	// Edit product
	path: '/admin/products/edit/:productID',
	component: ProductForm,
	props: true,
	meta: {
		scopes: [ 'admin:shops' ]
	}
},


{
	// Category list
	path: '/admin/product/categories',
	component: CategoryList,
	meta: {
		scopes: [ 'admin:shops' ],
	}
}]