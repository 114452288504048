<template>
	<div class="border-b border-gray-300 flex">
		<div 
			v-for="(t, key) in tabs"
			:key="key"
			@click="$emit('input', key)"
			class="text-center select-none cursor-pointer px-4 py-2 border-b -mb-px hover:border-primary-500 transition-colors duration-200"
			:class="tabClass(key)">
			
			<slot :tab="t" :tabkey="key">{{ tabLabel(t) }}</slot>
			
		</div>
	</div>
</template>
<script>
export default {
	props: [
		'tabs',
		'value',		// This is the active tab
	],
	methods: {
		tabLabel(t) {
			if (t.label == undefined) return t;
			return t.label;
		},
		tabClass(key) {
			if (key == this.value) return 'border-primary-500';
			else return 'border-gray-300';
		}
	}
}
</script>