<template>
	<div class="px-4 py-2 border border-gray-300 flex flex-col">
		
		<!-- Header -->
		<div class="text-center">
			<div class="text-2xl font-medium">#{{ order.label }}</div>
			<div v-if="order.user" class="text-sm text-gray-400">{{ order.user.userName }}</div>
			<div class="text-sm text-gray-600">{{ order.paid }}</div>
		</div>
		<hr class="hr my-1">
		
		<!-- Remarks -->
		<template v-if="order.remarks">
			<div class="text-danger-300 text-center font-medium italic whitespace-pre-wrap">{{ order.remarks }}</div>
			<hr class="hr my-1">
		</template>
		
		<!-- Items -->
		<ul class="space-y-1 flex-grow">
			<li v-for="(it, ii) in order.items" :key="ii" class="px-2 py-1 border-b border-gray-200 flex items-center">
				<!-- Product name and variant -->
				<div class="flex-grow">
					<div class="font-medium">{{ it.productName }}</div>
					<div class="text-sm text-gray-500">{{ it.variantName }}</div>
				</div>
				<!-- Qty -->
				<div class="text-lg font-medium">{{ it.qty }}</div>
			</li>
		</ul>
		
		
		<!-- Buttons -->
		<div class="mt-4 space-y-2">
			<template v-if="mode == 'online'">
				<button v-if="order.status == 'pending'" class="button is-success w-full" @click="$emit('ready')">Ready</button>
				<button v-if="order.status == 'ready'" class="button is-success w-full" @click="$emit('pay')">Pay</button>
				<button v-if="order.status == 'paid'" class="button is-success w-full" @click="$emit('ready')">Ready</button>
			</template>
			<template v-else-if="mode == 'cashier'">
				<button v-if="order.status == 'paid'" class="button is-success w-full" @click="$emit('ready')">Ready</button>
				<button v-if="order.status == 'ready'" class="button is-success w-full" @click="$emit('collected')">Collected</button>
			</template>
		</div>
		
	</div>
</template>
<script>
export default {
	props: [ 'mode', 'order' ],
}
</script>