import { registration } from '@/plugins/ServiceWorker'



export async function subscribePush() {
	if (!'PushManager' in window) {
		console.error('Push notifications unsupported');
		return null;
	}
	if (!registration) {
		console.error('Service worker disabled');
		return null;
	}
	
	let existing = await registration.pushManager.getSubscription();
	if (existing) return existing;
	
	
	// Request notification permissions
	const permissions = await Notification.requestPermission();
	if (permissions != 'granted') {
		console.error('Notifications permission denied');
		throw 'permission_denied';
	}
	
	// Subscribe
	const subscription = await registration.pushManager.subscribe({
		userVisibleOnly: true,
		applicationServerKey: process.env.VUE_APP_VAPID_PUBLIC_KEY,
	});
	
	return subscription;
}


export async function unsubscribePush() {
	const existing = await registration.pushManager.getSubscription();
	if (existing) await existing.unsubscribe();
}