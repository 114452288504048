<template>
	<Container>
		
		<div class="text-3xl font-semibold mb-4">My Profile</div>
		
		<TabBar v-model="activeTab" v-bind="{ tabs: visibleTabs }" />
		<div class="pt-2">
			<transition mode="out-in" name="fade">
				<component :is="visibleTabs[activeTab].component" />
			</transition>
		</div>
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import TabBar from '@/components/TabBar.vue'
import ProfileDetails from './ProfileDetails.vue'
import VoucherHistory from './VoucherHistory.vue'
import VoucherPurchase from './VoucherPurchase.vue'
import UserSettings from './UserSettings.vue'
import Version from './Version.vue'

export default {
	components: {
		Container,
		TabBar,
		ProfileDetails,
		VoucherHistory,
		VoucherPurchase,
		UserSettings,
		Version,
	},
	computed: {
		visibleTabs() {
			const tabs = { ...this.tabs };
			if (!this.$store.state.Auth.scopes.includes('admin')) {
				delete tabs.version;
			}
			
			return tabs;
		}
	},
	data() {
		return {
			activeTab: 'details',
			tabs: {
				details: {
					label: 'Details',
					component: 'profile-details',
				},
				voucherHistory: {
					label: 'Voucher Issue History',
					component: 'voucher-history',
				},
				voucherPurchase: {
					label: 'Voucher Usage History',
					component: 'voucher-purchase',
				},
				settings: {
					label: 'Preferences',
					component: 'user-settings'
				},
				version: {
					label: 'Version',
					component: 'version',
				}
			},
		};
	},
}
</script>