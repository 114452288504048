<template>
	<div>
		<preloader v-if="loading" />
		<div v-else>
			<div class="space-y-2">
				<div>
					<label class="label">Product Name</label>
					<input type="text" class="input w-full" placeholder="Product name" v-model.trim="formData.productName">
				</div>
				
				<div>
					<label class="label">Description</label>
					<textarea class="input w-full" rows="5" placeholder="Product description" v-model.trim="formData.description"></textarea>
				</div>
				
				<div class="flex space-x-2">
					<div class="w-1/2">
						<label class="label">Category</label>
						<select class="input w-full" v-model="formData.catID">
							<option :value="null">Uncategorized</option>
							<option v-for="c in categories" :key="c.catID" :value="c.catID">{{ c.catName }}</option>
						</select>
					</div>
					
					<div class="w-1/2">
						<label class="label">Subcategory</label>
						<select class="input w-full" v-model="formData.subcatID" :disabled="fetching">
							<option :value="null">N/A</option>
							<option v-for="sc in subcategories" :key="sc.catID" :value="sc.catID">{{ sc.catName }}</option>
						</select>
					</div>
				</div>
				
				<div>
					<label class="label">Pre-order Days</label>
					<input type="number" class="input w-full" placeholder="Pre-order days" v-model.number="formData.preorderDays">
				</div>
				
				<div class="space-y-2 py-1">
					<label class="flex items-center space-x-2">
						<input type="checkbox" v-model="formData.isActive">
						<span class="font-medium select-none">Product Active</span>
					</label>
					
					<label class="flex items-center space-x-2">
						<input type="checkbox" v-model="formData.isOnline">
						<span class="font-medium select-none">Online Purchase</span>
					</label>
				</div>
				
			</div>
			<hr class="hr">
			
			<div>
				<button class="button is-primary" :class="{ loading: submitting }" @click="save">Save</button>
			</div>
		</div>
		
	</div>
</template>
<script>
export default {
	props: [ 'productID' ],
	watch: {
		'formData.catID': 'fetchSubcategory',
	},
	data() {
		return {
			loading: false,
			fetching: false,
			submitting: false,
			
			formData: {
				productName: '',
				description: '',
				catID: null,
				subcatID: null,
				preorderDays: 0,
				isActive: true,
				isOnline: true,
			},
			
			categories: [],
			subcategories: [],
		};
	},
	methods: {
		async fetchSubcategory() {
			const catID = this.formData.catID;
			if (!catID) {
				this.formData.subcatID = null;
				this.subcategories = [];
				return;
			}
			
			this.fetching = true;
			try {
				const res = await this.$api.request('GET', `products/categories?parentID=${catID}`);
				this.subcategories = res;
				this.formData.subcatID = null;
				
			} catch (err) {
				console.error(err);
				this.$toast.error('Failed to fetch subcategories');
			}
			this.fetching = false;
		},
		
		
		async save() {
			this.submitting = true;
			try {
				
				const formData = { ...this.formData };
				if (this.productID) {
					// Update existing
					await this.$api.request('PUT', `products/${this.productID}`, formData);
					
				} else {
					// Create new
					const res = await this.$api.request('POST', `products`, formData);
					const productID = res.productID;
					
					this.$router.replace(`/admin/products/edit/${productID}`);
				}
				
				this.$toast.success('Product saved');
				
			} catch(err) {
				if (err = 'empty_variants') {
					this.$toast.error('Please enter at least one variant for active product');
				} else {
					console.error(err);
					this.$toast.error('Error saving product');
				}
			}
			this.submitting = false;
		}
	},
	async mounted() {
		this.loading = true;
		try {
			
			// Fetch categories
			this.categories = await this.$api.request('GET', `products/categories`);
			await this.fetchSubcategory();
			
			// Fetch product details
			if (this.productID) {
				const res = await this.$api.request('GET', `products/${this.productID}`);
				this.formData.productName = res.productName;
				this.formData.description = res.description;
				this.formData.catID = res.catID;
				this.formData.subcatID = res.subcatID;
				this.formData.preorderDays = res.preorderDays;
				this.formData.isActive = res.isActive;
				this.formData.isOnline = res.isOnline;
			}
			
			
		} catch(err) {
			console.error(err);
			this.$toast.error('Error loading product');
		}
		this.loading = false;
	}
}
</script>