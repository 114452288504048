<template>
	<div class="px-4 py-2">
		
		<div v-if="order">
			
			
			<div class="text-center">
			
				<!-- Order pending -->
				<div v-if="order.status == 'pending'">
					<div class="text-lg font-medium">Payment pending, please pay via terminal</div>
					<preloader />
				</div>
				
				<!-- Payment complete -->
				<div v-if="order.status == 'paid'" class="flex items-center justify-center">
					<icon class="mr-4 w-8 h-8 text-success-500" :data="mdiCheckCircle" />
					<div class="text-2xl font-medium">Payment complete</div>
				</div>
			
			</div>
			<hr class="hr">
			
			<div class="text-center space-x-2">
				<button v-if="order.status == 'pending'" class="button is-danger" :class="{ loading: submitting }" @click="cancelOrder">Cancel</button>
				
				<button v-if="order.status == 'paid'" class="button is-primary" @click="printReceipt">Print Receipt</button>
				<button v-if="order.status == 'paid'" class="button" @click="done">Close</button>
			</div>
			
		</div>
		
	</div>
</template>
<script>
import { mdiCheckCircle } from '@mdi/js'
import { mapState } from 'vuex'

export default {
	props: [ 'order', 'items' ],
	computed: {
		...mapState('Constants', [ 'SST_RATE', 'SST_INCLUSIVE' ]),
	},
	data() {
		return {
			mdiCheckCircle,
			submitting: false,
			
		};
	},
	methods: {
		async cancelOrder() {
			if (!this.order) return;
			
			this.submitting = true;
			try {
				// Cancel order from terminal
				await this.$devices.request('DELETE', `pay/cashier/cancel`, {
					orderID: this.order.orderID,
					traceNum: null,
					amount: null,
				});
				
				this.$toast.info('Order cancelled');
				this.$emit('close');
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error cancelling order');
			}
			this.submitting = false;
		},
		taxPrice(it) {
			if (!it.isTaxable) return '0.00';
			
			const price = it.qty * it.price;
			let sstRate = this.SST_RATE / 100.0;
			if (this.SST_INCLUSIVE) return (price * sstRate).toFixed(2);
			else return (price * (this.SST_RATE / 100.0)).toFixed(2);
		},
		async printReceipt() {
			if (!this.order) return;
			
			this.submitting = true;
			try {
				let queueNum = null;
				if (this.order.queueNum != -1) queueNum = this.order.queueNum;
				
				const printData = {
					queueNum,
					orderNum: this.order.label,
					paymentAmt: this.order.paymentAmount,
					taxAmt: this.order.taxAmount,
					roundingAmt: this.order.roundingAmount,
					discount: this.order.voucherAmount,
					items: [],
				};
				printData.items = this.items.map(it => {
					const name = `${it.productName}: ${it.variantName}`.replace(/[^\u0000-\u007f]/g, '');
					const taxPrice = this.taxPrice(it);
					return {
						name,
						qty: it.qty,
						price: (it.price * it.qty).toFixed(2),
						tax: taxPrice,
					};
				});
				
				await this.$devices.request('POST', 'print', printData);
				
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error printing receipt');
			}
			this.submitting = false;
		},
		done() {
			this.$emit('close');
		}
	},
}
</script>