<template>
	<Container>
		
		<div class="text-3xl font-semibold">Voucher Balance Report</div>
		<hr class="hr">
		
		<div class="flex items-center justify-end space-x-2">
			<input type="text" class="input" placeholder="Search" v-model.trim="q" @keydown.enter="search">
			<button class="button is-primary" :class="{ loading }" @click="loadPage(0)">Search</button>
			
			<button class="button" :class="{ loading }" @click="generateCSV">Download CSV</button>
			<a class="hidden" ref="downloadLink"></a>
		</div>
		<hr class="hr">
		
		
		<preloader v-if="loading" />
		<div v-else>
			
			<table class="table w-full">
				<thead>
					<tr>
						<th width="150px">UserID</th>
						<th>Name</th>
						<th width="100px">Status</th>
						<th width="100px">Company</th>
						<th width="100px">BU</th>
						<th width="100px">Dept</th>
						<th width="200px" class="text-right">Balance (RM)</th>
					</tr>
				</thead>
				<tbody>
					<tr v-if="!reportRows.length">
						<td colspan="7">No records available</td>
					</tr>
					
					
					<tr v-for="r in reportRows" :key="r.logID">
						<td>{{ r.userID }}</td>
						<td>{{ r.userName }}</td>
						<td class="font-medium uppercase" :class="StatusClass[r.status]">{{ r.status }}</td>
						<td>{{ r.company }}</td>
						<td>{{ r.bu }}</td>
						<td>{{ r.dept }}</td>
						<td class="text-right">{{ r.balance }}</td>
					</tr>
					
				</tbody>
			</table>
			
			<Pagination class="mt-4" v-bind="{ curPage, totalRows, pageLimit }" @goto="loadPage" />
			
		</div>
		
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import Pagination from '@/mixins/Pagination'
import dayjs from 'dayjs'
import exportCSV from '@/utils/exportCSV'


export default {
	components: {
		Container,
	},
	mixins: [ Pagination ],
	data() {
		return {
			loading: false,
			searching: false,
			
			q: '',
			reportRows: [],
			
			StatusClass: {
				active: 'text-success-500',
				inactive: 'text-danger-500',
			}
		};
	},
	methods: {
		search() {
			this.searching = true;
			this.loadPage(0);
		},
		clear() {
			this.query = '';
			this.searching = false;
			this.loadPage(0);
		},
		
		async loadPage(page) {
			this.loading = true;
			try {
				const queries = [
					`page=${page}`,
					`limit=${this.pageLimit}`,
				];
				
				if (this.q) queries.push(`q=${encodeURIComponent(this.q)}`);
				
				const res = await this.$api.request('GET', `vouchers/reports/balance?${queries.join('&')}`);
				this.page = page;
				this.totalRows = res.total;
				this.reportRows = res.results.map(r => {
					return {
						userID: r.userID,
						userName: r.userName,
						status: r.status,
						company: r.company,
						bu: r.bu,
						dept: r.dept,
						balance: r.balance ? r.balance.vcafe : '0.00',
					}
				});
				
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error loading report');
			}
			this.loading = false;
		},
		async generateCSV() {
			this.loading = true;
			try {
				const queries = [
					`page=0`,
					`limit=1000000`,
				];
				if (this.q) queries.push(`q=${encodeURIComponent(this.q)}`);
				
				const res = await this.$api.request('GET', `vouchers/reports/balance?${queries.join('&')}`);
				const csvRows = [
					[ 'UserID', 'Name', 'Status', 'Company', 'BU', 'Dept', 'Balance (RM)' ]
				];
				
				for (const i in res.results) {
					const r = res.results[i];
					csvRows.push([
						r.userID,
						r.userName,
						r.status,
						r.company,
						r.bu,
						r.dept,
						r.balance ? r.balance.vcafe : '0.00',
					]);
				}
				
				exportCSV(csvRows, `VoucherBalance.csv`, this.$refs.downloadLink);
				
				
			} catch (err) {
				console.error(err);
				this.$toast.error('Failed to load report');
			}
			this.loading = false;
		}
	},
	mounted() {
		this.startDate = dayjs().startOf('month').toDate();
		this.endDate = dayjs().endOf('month').toDate();
		
		this.loadPage(0);
	}
}
</script>