export function generatePaymentURL({ order, payment, user }) {
	const paymentQueries = [
		`amount=${order.paymentAmount}`,
		`orderid=${order.orderID}`,
		`bill_name=${encodeURIComponent(user.userName)}`,
		`bill_email=${encodeURIComponent(user.email)}`,
		`bill_mobile=${encodeURIComponent(user.contact)}`,
		`bill_desc=${encodeURIComponent(`Purchase #${order.label}`)}`,
		`vcode=${payment.vcode}`,
		`returnurl=${encodeURIComponent(payment.returnURL)}`,
		`callbackurl=${encodeURIComponent(payment.callbackURL)}`,
		`country=MY`,
	];
	return `${payment.paymentURL}?${paymentQueries.join('&')}`;
}