<template>
	<Container>
		<div class="text-3xl font-semibold flex-grow">Interco Report</div>
		<hr class="hr">
		
		<div class="md:flex md:items-center md:space-x-2 mb-6">
			<Datepicker v-model="monthDate"  v-bind="{
				placeholder: 'Select month',
				minLevel: 'month',
				format: 'MMM YYYY',
			}" />
			<button class="button is-primary" :class="{ loading }" @click="update">Update</button>
			
			
			<button v-if="allowPosting" class="button is-accent" :class="{ loading: submitting }" @click="postInvoice">Post Invoice</button>
		</div>
		
		<preloader v-if="loading" />
		<div v-else class="overflow-x-auto">
			
			<!-- Show cost breakdown -->
			<div class="flex justify-between items-center">
				<div class="text-2xl font-medium mb-4">
					Grand Total: {{ grandTotal }}
				</div>
				<button class="button is-primary" @click="showBreakdown = true">Cost Breakdown</button>
			</div>
			
			<TabBar v-model="activeCompany" v-bind="{ tabs: companyTabs }" />
			
			<table class="table w-full" v-if="activeBin">
				<thead>
					<tr>
						<th colspan="5">{{ CompanyNames[activeCompany] }}</th>
						<th colspan="2" class="text-right">
							<div class="flex gap-2 justify-end">
								<label class="label items-center inline-flex gap-2">
									<div>Show Vouchers</div>
									<input type="checkbox" v-model="showVoucherLines">
								</label>
								
								<button class="button small is-accent inline-flex items-center gap-1" :class="{ loading }" @click="generateAPReport(activeCompany)">
									<icon :data="mdiFileDownload" />
									<span>CSV</span>
								</button>
								<a ref="downloadLink"></a>
							</div>
						</th>
					</tr>
					<tr>
						<th width="200px">Part ID</th>
						<th width="100px">BU</th>
						<th width="100px">Dept</th>
						<th>Line Description</th>
						<th width="120px" class="text-right">Unit Price</th>
						<th width="120px" class="text-right">Qty</th>
						<th width="120px" class="text-right">Total</th>
					</tr>
				</thead>
				<tbody>
					<tr class="font-semibold text-lg">
						<td class="text-right" colspan="6">Invoice Total</td>
						<td class="text-right">{{ totals[activeCompany].total.toFixed(2) }}</td>
					</tr>
					<tr v-for="(r, ri) in displayedRows">
						<td>{{ r.partID }}</td>
						<td>{{ r.bu }}</td>
						<td>{{ r.dept }}</td>
						<td>{{ r.desc }}</td>
						<td class="text-right">{{ r.unitPrice }}</td>
						<td class="text-right">{{ r.qty }}</td>
						<td class="text-right">{{ r.total }}</td>
					</tr>
					<tr class="font-semibold text-lg">
						<td class="text-right" colspan="6">Invoice Total</td>
						<td class="text-right">{{ totals[activeCompany].total.toFixed(2) }}</td>
					</tr>
				</tbody>
			</table>
			
		</div>
		
		<Modal v-model="showBreakdown">
			<table class="table w-full">
				<thead>
					<tr>
						<th width="200px">Type</th>
						<th class="text-right">Lines</th>
						<th class="text-right">Amount</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(b, key) in breakdown">
						<td class="uppercase">{{ key }}</td>
						<td class="text-right">{{ b.count }}</td>
						<td class="text-right">{{ b.sum }}</td>
					</tr>
				</tbody>
			</table>
		</Modal>
		
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import TabBar from '@/components/TabBar.vue'
import Modal from '@/components/Modal.vue'
import Datepicker from '@/components/Datepicker'
import dayjs from 'dayjs'
import exportCSV from '@/utils/exportCSV'
import { mapState } from 'vuex'
import { mdiFileDownload, mdiRobotConfusedOutline } from '@mdi/js'

export default {
	components: {
		Container,
		Datepicker,
		TabBar,
		Modal,
	},
	computed: {
		...mapState('Constants', [ 'CompanyNames' ]),
		companyTabs() {
			const tabs = {};
			for (const i in this.CompanyNames) {
				if (!i) continue;
				tabs[i] = this.CompanyNames[i];
			}
			
			return tabs;
		},
		activeBin() {
			if (!this.activeCompany) return [];
			return this.bins[this.activeCompany];
		},
		allowPosting() {
			if (!this.endDate) return false;
			if (!this.$store.state.Auth.scopes.includes('admin:posting')) return false;
			if (this.posting) return false;
			return true;
		},
		grandTotal() {
			let sum = 0;
			for (const i in this.totals) {
				const t = this.totals[i];
				sum += t.total;
			}
			return sum.toFixed(2);
		},
		displayedRows() {
			if (!this.activeBin) return [];
			
			return this.activeBin.filter(r => {
				if (!this.showVoucherLines && r.type == 'voucher') return false;
				return true;
			})
		}
	},
	watch: {
		monthDate() {
			const now = dayjs(this.monthDate);
			this.startDate = now.startOf('month').toDate();
			this.endDate = now.endOf('month').toDate();
			this.update();
		}
	},
	data() {
		return {
			mdiFileDownload,
			loading: false,
			submitting: false,
			showBreakdown: false,
			showVoucherLines: false,
			
			activeCompany: 'VT',
			monthDate: null,
			startDate: null,
			endDate: null,
			
			posting: null,
			
			glLookupBU: {
				'ABI-AIS': { bu: 'PCA', sub: 'AAIS' },
				'ABI-AOI': { bu: 'PCA', sub: 'AAOI' },
				'ABI-ARV': { bu: 'PCA', sub: 'AARV' },
				'ABI-AXI': { bu: 'PCA', sub: 'AAXI' },
				'ABI-MES': { bu: 'PCA', sub: 'AMES' },
				'AGT': { bu: 'AGT', sub: '' },
				'AGT-TP': { bu: 'AGT', sub: 'AGT' },
				'AGT-AGR': { bu: 'AGT', sub: 'AGR' },
				'AGT-AGT': { bu: 'AGT', sub: 'AGT' },
				'ECS': { bu: 'ECS', sub: '' },
				'EDU': { bu: 'EDU', sub: '' },
				'MVSS': { bu: 'MVS', sub: 'SCM' },
				'MVS-CM': { bu: 'MVS', sub: 'SCM' },
				'MVS-PX': { bu: 'MVS', sub: 'SPX' },
				'MVS-VR': { bu: 'MVS', sub: 'SVR' },
				'MVS-VS': { bu: 'MVS', sub: 'SVS' },
				'MVS-WF': { bu: 'MVS', sub: 'SWF' },
				'MVST': { bu: 'MVT', sub: '' },
				'SSV': { bu: 'SSV', sub: '' },
				
				
				// ?? (Not supposed to happen, as V-Green shouldn't be eligible)
				'GRN-FARM': { bu: 'GRN-FARM', sub: '' }
			},
			glLookupDept: {
				ADMIN: 'ADMIN',
				BD: 'BD',
				'CEO OFFICE': 'MGMT',
				CoE: 'COE',
				FACILITY: 'FACILITY',
				FIN: 'FIN',
				LDF: 'LEAN',
				LOG: 'LOG',
				MARCOM: 'MARCOM',
				MFG: 'MFG',
				MGMT: 'MGMT',
				MIS: 'MIS',
				OD: 'OD',
				OF: 'OF',
				OPERATION: 'OPR',
				OPR: 'OPR',
				PC: 'PC',
				PD: 'PD',
				PM: 'PM',
				PMO: 'PMO',
				PRO: 'PUR',
				QA: 'QA',
				'R&D': 'RnD',
				'RnD': 'RnD',
				STR: 'STR',
				TS: 'TS',
				XPL: 'XPL',
				CORPT: 'CORPT'
			},
			
			bins: {},
			totals: {},
			breakdown: {},
		};
	},
	methods: {
		async update() {
			if (!this.startDate || !this.endDate) return;
			
			const start = dayjs(this.startDate).format('YYYY-MM-DD');
			const end = dayjs(this.endDate).format('YYYY-MM-DD');
			
			this.loading = true;
			try {
				const queries = [
					`start=${start}`,
					`end=${end}`,
				];
				const res = await this.$api.request('GET', `reports/interco?${queries.join('&')}`);
				const companyBins = res.bins;
				this.breakdown = {
					voucher: res.voucher,
					voucher_issue: res.issue,
					//voucher: res.voucherSales,
					//voucher_tax: res.voucher.voucherTax,
					meal: res.meal,
					penalty: res.penalty,
					service: res.service,
					gift: res.gift,
				};
				for (const i in this.breakdown) {
					const b = this.breakdown[i];
					if (b.sum == null) continue;
					b.sum = b.sum.toFixed(2);
				}
				
				const typeTotals = {};
				const totals = {};
				for (const i in companyBins) {
					const rows = companyBins[i];
					totals[i] = {
						qty: 0,
						total: 0,
						vmeal: 0,
						voucher: 0,
						penalty: 0,
						tax: 0,
					};
					const companyTypeTotals = {};
					
					for (const j in rows) {
						const row = rows[j];
						row.total = row.qty * row.unitPrice;
						row.unitPrice = row.unitPrice.toFixed(2)
						totals[i].qty += row.qty;
						totals[i].total += row.total;
						
						if (!companyTypeTotals[row.type]) companyTypeTotals[row.type] = row.total;
						else companyTypeTotals[row.type] += row.total;
						
						if (!typeTotals[row.type]) typeTotals[row.type] = row.total;
						else typeTotals[row.type] += row.total;
						
						row.total = row.total.toFixed(2);
					}
					
					console.log(i, companyTypeTotals)
				}
				
				// Fetch posting record
				this.posting = null;
				if (this.$store.state.Auth.scopes.includes('admin:posting')) {
					this.posting = await this.$api.request('GET', `invoice/posting/interco/${end}`);
				}
				
				
				this.bins = companyBins;
				this.totals = totals;
				this.typeTotals = typeTotals;
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error loading report');
			}
			this.loading = false;
		},
		async postInvoice() {
			const confirm = await this.$confirm({
				title: 'Submit Interco Invoice',
				message: 'Confirm to submit interco invoice for posting?',
				buttons: 'YES_NO',
			});
			if (!confirm) return;
			
			this.submitting = true;
			try {
				this.posting = await this.$api.request('POST', `invoice/posting/interco`, {
					date: dayjs(this.endDate).format('YYYY-MM-DD'),
				});
				this.$toast.success('Invoice submitted for posting');
				
			} catch (err) {
				console.error(err);
				this.$toast.error('Failed to submit invoice for posting');
			}
			this.submitting = false;
		},
		resolveSeg1(r) {
			if (r.type == 'service' || r.taxType == 'service') {
				if (r.customerType == 'Customer') {
					if (r.customerType2 == 'old') {
						if (r.company == 'VA') return '42002';
						else return '70107';
					}
				}
				else if (r.customerType == 'Vitroxian') {
					return '60017';
				}
				return '60064';
				
			} else if (r.type == 'gift' || r.taxType == 'gift') {
				
				if (r.giftType == 'internal') return '60017';
				else if (r.customerType == 'Customer') {
					if (r.customerType2 == 'old') {
						if (r.company == 'VA') return '42002';
						else return '70107';
					}
				}
				return '60064';
			}
			
			return '60017';
		},
		generateAPReport(company) {
			const monthYear = dayjs(this.startDate).format('MMMYY');
			const lastDay = dayjs(this.startDate).endOf('month').format('DD/MM/YYYY');
			const csvLines = [
				[
					// Headers
					'JournalNum',
					'SegValue1',
					'SegValue2',
					'SegValue4',
					'SegValue3',
					'SegValue5',
					'SegValue6',
					'Description',
					'Desc2_c',
					'TransAmt',
					'DocTransAmt',
					'GLJrnHedDescription',
					'GLJrnHedReverse',
					'GLJrnHedReverseDate',
					'GLJrnHedJEDate',
					'JEDate',
				]
			];
			
			let sum = 0;
			const rows = this.displayedRows;
			for (const i in rows) {
				const r = rows[i];
				if (!r.bu) continue;
				
				const glBU = this.glLookupBU[r.bu];
				const glDept = this.glLookupDept[r.dept];
				
				if (!glBU) console.log(r);
				
				// Special handling for special department
				let finalBU = glBU.bu;
				if (glDept == 'COE') finalBU = 'COE';
				
				const typeDesc = {
					vmeal: 'V-Meal Program',
					voucher: 'Cash Voucher',
					service: 'Visitation F&B',
					gift: 'Guest F&B',
					tax: 'Tax',
				};
				
				const companyName = 'ViTrox Green Sdn Bhd';
				//let desc = `${companyName}_${typeDesc[r.type]}_${r.bu}_${r.dept}_${r.desc}_${monthYear}`;
				//if (r.type == 'gift' && r.purpose) desc = `${companyName}_${r.purpose}_${r.bu}_${r.dept}_${r.desc}_${monthYear}`;
				
				const segValue1 = this.resolveSeg1(r);
				
				let desc = `${companyName}_${r.bu}_${r.dept}_${r.desc}_${monthYear}`;
				desc = desc.replace(/[^\u0000-\u007f]/g, '');
				
				
				const line = [
					'1',
					segValue1,
					finalBU,
					'',
					glDept,
					'',
					glBU.sub,
					'Vitrox Green Interco Description',
					desc,
					r.total,
					r.total,
					'Vitrox Green Interco Description',
					'',
					'',
					lastDay,
					lastDay,
				];
				
				sum += parseFloat(r.total);
				csvLines.push(line);
			}
			
			
			// Add one more fixed line at the end
			csvLines.push([
				'1',
				'20049',
				'',
				'',
				'',
				'',
				'',
				'Vitrox Green Interco Description',
				'Vitrox Green Interco Description',
				-sum.toFixed(2),
				-sum.toFixed(2),
				'Vitrox Green Interco Description',
				'',
				'',
				lastDay,
				lastDay,
			]);
			
			const el = this.$refs.downloadLink;
			exportCSV(csvLines, `AP_${company}_${monthYear}.csv`, el);
			
			/*
			const monthYear = dayjs(this.startDate).format('MMMYY');
			const csvLines = [
				[ 'Line Type Description', 'Supplier Part', 'Description', 'Supplier Qty', 'UOM', 'GLAccount Segment Value 1', 'GLAccount Segment Value 2', 'GLAccount Segment Value 3', 'GLAccount Segment Value 4', 'GLAccount Segment Value 5', 'GLAccount Segment Value 6', 'Unit Cost', 'Cost Per', 'Extended Amount' ]
			];
			
			const rows = this.bins[company];
			for (const i in rows) {
				const r = rows[i];
				//const companyName = this.CompanyNames[company];
				const companyName = 'ViTrox Green Sdn Bhd';
				
				const glBU = this.glLookupBU[r.bu];
				const glDept = this.glLookupDept[r.dept];
				
				// Special handling for special department
				let finalBU = glBU.bu;
				if (glDept == 'COE') finalBU = 'COE';
				
				const typeDesc = {
					vmeal: 'V-Meal Program',
					voucher: 'Staff Appreciation',
					service: 'Visitation F&B',
					gift: 'Guest F&B',
				};
				
				
				
				
				let desc = `${companyName}_${typeDesc[r.type]}_${r.bu}_${r.dept}_${r.desc}_${monthYear}`;
				if (r.type == 'gift' && r.purpose) desc = `${companyName}_${r.purpose}_${r.bu}_${r.dept}_${r.desc}_${monthYear}`;
				
				const line = [
					'Miscellaneous Billing',
					'',
					desc,
					'',
					'PC',
					'',
					finalBU,
					glDept,
					'',
					'',
					glBU.sub,
					'',
					'',
					r.total,
				];
				csvLines.push(line);
			}
			const el = this.$refs[`download_${company}`][0];
			exportCSV(csvLines, `AP_${company}_${monthYear}.csv`, el);
			*/
		}
	},
	mounted() {
		this.monthDate = dayjs().startOf('month').toDate();
	}
}
</script>