<template>
	<div>
		<div class="text-2xl font-semibold">Add Volunteer</div>
		<hr class="hr">
		
		<SuggestionInput
			@selected="userSelected"
			v-bind="{
				inputClass: 'input w-full',
				placeholder: 'Start typing to search user',
				suggest: suggestUser,
			}">
			
			<template v-slot="{ item }">
				<span class="font-medium mr-4">{{ item.userID }}</span>
				<span class="">{{ item.userName }}</span>
			</template>
			
		</SuggestionInput>
		
		<table class="table w-full my-4">
			<thead>
				<tr>
					<th width="20%">UserID</th>
					<th>Name</th>
					<th width="100px">Company</th>
					<th width="100px">BU</th>
					<th width="100px">Dept</th>
					<th width="40px"></th>
				</tr>
			</thead>
			<tbody>
				<tr v-if="!selected.length">
					<td colspan="6">No users added</td>
				</tr>
				
				<tr v-for="u in selected" :key="u.userID">
					<td>{{ u.userID }}</td>
					<td>{{ u.userName }}</td>
					<td>{{ u.company }}</td>
					<td>{{ u.bu }}</td>
					<td>{{ u.dept }}</td>
					<td class="text-right">
						<button class="text-gray-500 hover:text-danger-500">
							<icon :data="mdiClose" />
						</button>
					</td>
				</tr>
				
			</tbody>
		</table>
		
		<div>
			<button class="button is-primary" :class="{ loading: submitting }" @click="addSelected">Add Selected</button>
		</div>
		
	</div>
</template>
<script>
import Container from '@/layouts/Container.vue'
import SuggestionInput from '@/components/SuggestionInput.vue'
import { mdiClose } from '@mdi/js'

export default {
	props: [ 'date' ],
	components: {
		Container,
		SuggestionInput,
	},
	data() {
		return {
			mdiClose,
			submitting: false,
			
			selected: [],
		};
	},
	methods: {
		async suggestUser(q) {
			try {
				const users = await this.$api.request('GET', `suggest/users?q=${encodeURIComponent(q)}`)
				return users;
			} catch(err) {
				console.error(err);
				this.$toast.error('Error fetching user suggestions');
			}
			return [];
		},
		// User selected from suggestion input
		userSelected(user) {
			const index = this.selected.findIndex(u => u.userID == user.userID);
			if (index >= 0) {
				this.$toast.error('User already added');
				return;
			}
			
			this.selected.push(user);
		},
		removeUser(userID) {
			const index = this.selected.findIndex(u => u.userID == userID);
			if (index >= 0) this.selected.splice(index, 1);
		},
		async addSelected() {
			if (!this.date) return;
			
			this.submitting = true;
			try {
				const userIDs = this.selected.map(s => s.userID);
				const res = await this.$api.request('POST', `volunteers`, {
					date: this.date,
					userIDs,
				});
				
				this.$emit('add', res);
				this.$toast.success('Volunteers added');
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Failed adding volunteer');
			}
			this.submitting = false;
		},
	}
}
</script>