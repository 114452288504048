<script>
import links from './allLinks';

import SingleLink from './SingleLink'
import MultiLink from './MultiLink'
import { mapState } from 'vuex'
export default {
	props: [ 'collapse' ],
	components: {
		SingleLink,
		MultiLink,
	},
	computed: {
		...mapState('Auth', [ 'scopes', 'pending' ])
	},
	methods: {
		hasScope(scopes) {
			for (const s of scopes) {
				if (this.scopes.includes(s)) return true;
			}
			return false;
		}
	},
	render(h) {
		const children = [];
		
		// Build children
		for (const i in links) {
			const link = links[i];
			
			if (this.pending && !link.showOnPending) continue;
			if (link.scopes && !this.hasScope(link.scopes)) continue;
			
			if (link.path) {
				children.push(h('SingleLink', {
					props: {
						icon: link.icon,
						path: link.path,
						title: link.title,
						collapse: this.collapse,
					}
				}));
				
			} else if (link.links && Array.isArray(link.links)) {
				
				// Check if each child link should be shown
				const shownLinks = [];
				for (const cl of link.links) {
					// Add if no scopes defined
					if (!cl.scopes) {
						shownLinks.push(cl);
						continue;
					}
					
					// Check and push to the list if scope is available
					if (this.hasScope(cl.scopes)) shownLinks.push(cl);;
				}
				
				// If the list of sublinks is not empty, show it
				if (shownLinks.length > 0) {
					children.push(h('MultiLink', {
						props: {
							icon: link.icon,
							title: link.title,
							links: shownLinks,
							collapse: this.collapse,
						}
					}));
				}
			}
		}
		return h('ul', {}, children);
	}
}
</script>