<template>
	<Container>
		
		<div class="h-10"></div>
		
		<div class="px-4 py-4 lg:w-1/5 md:w-1/2 mx-auto mb-4">
			<img class="mx-auto mb-6" alt="V-Green Logo" src="@/assets/vgreen-logo.png">
		</div>
		
		
		
		<div class="md:w-1/2 md:mx-auto">
			<div class="text-sm font-medium text-center text-gray-500 mb-2">Please sign in to proceed</div>
			
			<preloader v-if="loading" />
			<div v-else>
				<div ref="gsiBtn" class="w-fit mx-auto"></div>
			</div>
		</div>
		
		<!--
		<form class="w-1/3 mx-auto" @submit.prevent="login">
			<div class="space-y-2">
				<input type="text" :disabled="loading" class="input w-full" placeholder="Username" v-model.trim="uid">
				<input type="password" :disabled="loading" class="input w-full" placeholder="Password" v-model.trim="pwd">
			</div>
			<hr class="hr">
			
			<div class="flex items-center space-x-2 justify-end">
				<button
					type="button"
					@click="googleSignIn"
					class="inline-flex items-center px-4 py-2 outline-none select-none transition-colors duration-100 space-x-2 border border-gray-200 bg-white hover:bg-gray-100">
					<img src="@/assets/google.png" class="w-5 h-5">
					<span class="">Sign In with Google</span>
				</button>
				
				<button class="button is-primary" :class="{ loading }">Login</button>
			</div>
		</form>
		-->
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container'
import { mapState } from 'vuex'

export default {
	components: {
		Container,
	},
	computed: {
		...mapState('Icons', [ 'mdiChevronRight' ]),
	},
	data() {
		return {
			loading: false,
			
			uid: '',
			pwd: '',
		};
	},
	methods: {
		async onGoogleAuth(googleRes) {
			this.loading = true;
			try {
				const res = await this.$store.dispatch('Auth/authenticateGoogle', googleRes.credential);
				if (res) {
					this.$router.replace('/');
					this.$toast.success('Signed in successfully');
				} else {
					this.$toast.error('Sign in failed');
					
					this.$nextTick(() => {
						// Render the sign in button
						google.accounts.id.renderButton(this.$refs.gsiBtn, {
							type: 'standard',
							theme: 'outline',
							size: 'large',
							text: 'signin_with',
							shape: 'pill',
						});
					});
				}
			} catch(err) {
				console.error(err);
				this.$toast.error('Google Sign In failed');
			}
			this.loading = false;
			
			
			
		},
		googleSignIn() {
			google.accounts.id.prompt(notification => {
				console.log(notification);
			});
		},
		
		async login() {
			// Don't allow empty requests
			if (!this.uid || !this.pwd) {
				this.$toast.error('Please enter your username/password');
				return;
			}
			
			this.loading = true;
			try {
				const res = await this.$store.dispatch('Auth/authenticateLDAP', { uid: this.uid, pwd: this.pwd });
				if (res) {
					this.$router.replace('/');
					this.$toast.success('Signed in successfully');
				}
			} catch(err) {
				console.error(err)
				this.$toast.error('Error signing in');
			}
			this.loading = false;
		}
	},
	mounted() {
		// Render button for Google sign in
		google.accounts.id.initialize({
			client_id: process.env.VUE_APP_GAUTH_CLIENT_ID,
			callback: this.onGoogleAuth,
			ux_mode: 'popup',
			auto_select: true,
		});
		
		// Render the sign in button
		google.accounts.id.renderButton(this.$refs.gsiBtn, {
			type: 'standard',
			theme: 'outline',
			size: 'large',
			text: 'signin_with',
			shape: 'pill',
		});
		
		// Show the one tap prompt
		//google.accounts.id.prompt(n => {
		//	console.log(n);
		//});
	}
}
</script>