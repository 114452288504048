<template>
	<Container>
		<div class="flex items-center justify-end space-x-2">
			<div class="text-3xl font-semibold flex-grow">Manage Users</div>
			
			<button class="button" @click="downloadCSV">Download CSV</button>
			<button class="button" @click="showFilterModal">Filter</button>
			<router-link to="/admin/users/external/new" class="button is-accent">Create External User</router-link>
			<router-link to="/admin/usergroups" class="button is-primary">Manage User Groups</router-link>
		</div>
		<hr class="hr">
		
		<div class="flex space-x-2 mb-4">
			<input type="text" class="input flex-grow" placeholder="Search for users" v-model.trim="query" @keydown.enter="search">
			<button v-if="searching" class="button" :class="{ loading }" @click="clear">Clear</button>
			<button class="button is-primary" :class="{ loading }" @click="search">Search</button>
			
			<a ref="downloadLink" class="hidden"></a>
		</div>
		
		<preloader v-if="loading" />
		<div v-else>
			<table class="table w-full text-sm">
				<thead>
					<tr>
						<th width="140px">ID</th>
						<th>Name</th>
						<th>Email</th>
						<th width="200px">Status</th>
						<th width="100px">Company</th>
						<th width="100px">BU</th>
						<th width="100px">Dept</th>
						<th width="160px">Badge</th>
						<th width="160px">CardID</th>
					</tr>
				</thead>
				<tbody>
					<tr v-if="!users.length">
						<td colspan="20">No records available</td>
					</tr>
					<tr v-for="u in users" :key="u.userID">
						<td>{{ u.userID }}</td>
						<td>
							<router-link class="text-accent-500 font-medium" :to="`/admin/user/${u.userID}`">{{ u.userName }}</router-link>
						</td>
						<td>{{ u.email }}</td>
						<td class="font-medium uppercase" :class="StatusClass[u.status]">{{ u.status }}</td>
						<td>{{ u.company }}</td>
						<td>{{ u.bu }}</td>
						<td>{{ u.dept }}</td>
						<td>{{ u.badgeNum || '-' }}</td>
						<td>{{ u.cardID || '-' }}</td>
					</tr>
				</tbody>
			</table>
			
			<Pagination class="mt-4" v-bind="{ curPage, totalRows, pageLimit }" @goto="loadPage" />
		
		</div>
		
		
		<Modal v-model="filterModalShown" containerClass="md:w-3/5">
			<UserFilter v-bind="{ filters, companyList, buList, deptList }" @apply="applyFilter" />
		</Modal>
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import Modal from '@/components/Modal.vue'
import Pagination from '@/mixins/Pagination'
import { mapGetters } from 'vuex'
import UserFilter from './UserFilter.vue'
import exportCSV from '@/utils/exportCSV'
import dayjs from 'dayjs'

export default {
	components: {
		Container,
		Modal,
		UserFilter,
	},
	computed: {
		...mapGetters('Constants', [ 'walletMap' ]),
	},
	mixins: [ Pagination ],
	data() {
		return {
			loading: false,
			generating: false,
			
			filterModalShown: false,
			filterApplied: false,
			filters: {
				company: '',
				bu: '',
				dept: '',
				isExternal: null,
				status: '',
			},
			companyList: [],
			buList: [],
			deptList: [],
			
			query: '',
			searching: false,
			
			users: [],
			
			StatusClass: {
				active: 'text-success-500',
				inactive: 'text-danger-500',
			}
		};
	},
	methods: {
		search() {
			this.searching = true;
			this.loadPage(0);
		},
		clear() {
			this.query = '';
			this.searching = false;
			this.filterApplied = false;
			this.filters.company = '';
			this.filters.bu = '';
			this.filters.dept = '';
			this.filters.isExternal = null;
			this.filters.status = '';
			
			this.loadPage(0);
		},
		
		showFilterModal() {
			this.filterModalShown = true;
		},
		applyFilter() {
			this.filterModalShown = false;
			this.filterApplied = true;
			this.searching = true;
			this.loadPage(0);
		},
		
		async loadPage(page) {
			this.loading = true;
			try {
				const queries = [
					`page=${page}`,
					`limit=${this.pageLimit}`,
				];
				
				if (this.query) queries.push(`q=${encodeURIComponent(this.query)}`);
				if (this.filterApplied) {
					const f = this.filters;
					if (f.company) queries.push(`company=${f.company}`);
					if (f.bu) queries.push(`bu=${f.bu}`);
					if (f.dept) queries.push(`dept=${f.dept}`);
					if (f.isExternal !== null) queries.push(`isExternal=${f.isExternal}`);
					if (f.status) queries.push(`status=${f.status}`);
				}
				
				const res = await this.$api.request('GET', `users?${queries.join('&')}`);
				this.curPage = page;
				this.users = res.results;
				this.totalRows = res.total;
				
			} catch (err) {
				console.error(err);
				this.$toast.error('Error fetching users');
			}
			this.loading = false;
		},
		
		async downloadCSV() {
			this.generating = true;
			try {
				const csvRows = [
					[ 'EmpNo', 'Name', 'Email', 'Company', 'BU', 'Dept', 'BadgeNum', 'CardID' ],
				];
				const batchSize = 500;
				
				let rowsRemaining = 0;
				let page = 0;
				while (true) {
					const queries = [
						`page=${page}`,
						`limit=${batchSize}`,
						`status=active`,
					];
					const res = await this.$api.request('GET', `users?${queries.join('&')}`);
					
					++page;
					const rows = res.results;
					if (!rowsRemaining) rowsRemaining = res.total - rows.length;
					else rowsRemaining -= rows.length;
					
					for (const i in rows) {
						const r = rows[i];
						csvRows.push([
							r.userID,
							r.userName,
							r.email,
							r.company,
							r.bu,
							r.dept,
							r.badgeNum,
							r.cardID,
						]);
					}
					
					
					if (!rowsRemaining || !rows.length) break;
				}
				
				
				exportCSV(csvRows, `UserList_${dayjs().format('YYYYMMDD')}.csv`, this.$refs.downloadLink);
				
				
			} catch(err) {
				console.error(err);
				this.$toast.error(err);
			}
			this.generating = false;
		}
		
	},
	async mounted() {
		this.loading = true;
		try {
			const { company, bu, dept } = await this.$api.request('GET', `misc/bu`);
			this.companyList = company.filter(c => c.company);
			this.buList = bu.filter(b => b.bu);
			this.deptList = dept.filter(d => d.dept);
			
			
			await this.loadPage(0);
			
		} catch(err) {
			console.error(err);
			this.$toast.error('Error loading page');
		}
		this.loading = false;
	}
}
</script>