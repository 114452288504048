<template>
	<div>
		<preloader v-if="loading" />
		<table v-else class="table w-full">
			<thead>
				<tr>
					<th>Company</th>
					<th width="10%">BU</th>
					<th width="10%">Dept</th>
					<th class="text-right" width="10%">Count</th>
					<th class="text-right" width="10%">Total</th>
				</tr>
			</thead>
			<tbody>
				<tr v-if="!records.length">
					<td colspan="5">No records available</td>
				</tr>
				<tr v-for="(r, ri) in records" :key="ri">
					<td>{{ r.company }}</td>
					<td>{{ r.bu }}</td>
					<td>{{ r.dept }}</td>
					<td class="text-right">{{ r.count }}</td>
					<td class="text-right">{{ r.total }}</td>
					
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
import { mapState } from 'vuex'
import dayjs from 'dayjs'

export default {
	props: [ 'startDate', 'endDate', 'nonce', 'status' ],
	computed: {
		...mapState('Constants', [ 'CompanyNames' ]),
	},
	watch: {
		nonce() {
			this.loadPage()
		},
	},
	data() {
		return {
			loading: false,
			submitting: false,
			
			records: [],
		};
	},
	methods: {
		async loadPage() {
			this.loading = true;
			try {
				
				const queries = [
					`start=${dayjs(this.startDate).format('YYYY-MM-DD')}`,
					`end=${dayjs(this.endDate).format('YYYY-MM-DD')}`,
					`status=paid`,
				];
				
				//const date = dayjs(this.date).format('YYYY-MM-DD');
				const { penalties, users } = await this.$api.request('GET', `meal/penalties?${queries.join('&')}`);
				const userMap = {};
				for (const i in users) {
					const u = users[i];
					userMap[u.userID] = u;
				}
				
				const bins = {};
				for (const i in penalties) {
					const p = penalties[i];
					const user = userMap[p.userID];
					
					if (!bins[user.company]) bins[user.company] = {};
					if (!bins[user.company][user.bu]) bins[user.company][user.bu] = {};
					if (!bins[user.company][user.bu][user.dept]) bins[user.company][user.bu][user.dept] = 0;
					
					bins[user.company][user.bu][user.dept]++;
				}
				
				const records = [];
				for (const company in bins) {
					for (const bu in bins[company]) {
						for (const dept in bins[company][bu]) {
							const count = bins[company][bu][dept];
							
							records.push({
								company: this.CompanyNames[company],
								bu,
								dept,
								count,
								total: (count * 8).toFixed(2),
							});
						}
					}
				}
				this.records = records;
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error loading penalty list');
			}
			this.loading = false;
		},
		
	},
	async mounted() {
		this.loadPage();
	}
}
</script>