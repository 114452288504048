import {
	mdiHome,
	mdiAccount,
	mdiSilverware,
	mdiAccountSwitch,
	mdiCrown,
	mdiChartBox,
	mdiCart,
	mdiCash,
	mdiRoomService,
} from '@mdi/js'

export default [{
	icon: mdiHome,
	path: '/',
	title: 'V-Home',
	showOnPending: true,
},
{
	icon: mdiAccount,
	path: '/profile',
	title: 'V-Profile'
},


{
	icon: mdiSilverware,
	title: 'V-Meal',
	links: [{
		path: '/meal/registration',
		title: 'Registration',
	}, {
		path: '/meal/selfscan',
		title: 'Self Scan'
	}, {
		path: '/meal/appeal',
		title: 'Appeal Form',
	}, {
		path: '/admin/meal/scan',
		title: 'Attendance Scan',
		scopes: [ 'admin:vmeal' ]
	}, {
		path: '/admin/meal/list',
		title: 'Registration List',
		scopes: [ 'admin:vmeal' ]
	}, {
		path: '/admin/meal/menu',
		title: 'Menu Entry',
		scopes: [ 'admin:vmeal' ]
	}]
},

{
	icon: mdiAccountSwitch,
	title: 'V-Engagement',
	scopes: [ 'internal' ],
	links: [{
		path: '/engagement/1v1',
		title: 'Engagement Journey'
	}, {
		path: '/engagement/1v1/status',
		title: 'Engagement Status',
		scopes: [ 'engager' ]
	}]
},


{
	icon: mdiCart,
	title: 'V-Shop',
	scopes: [ 'cashier', 'admin:shops' ],
	links: [{
		path: '/admin/cashier',
		title: 'Cashier Page',
	}, {
		path: '/admin/orders',
		title: 'Order Dashboard',
	}, {
		path: '/admin/gift',
		title: 'Gift Claim'
	}]
	
},


{
	icon: mdiRoomService,
	title: 'V-Services',
	scopes: [ 'internal' ],
	links: [{
		path: '/service/request',
		title: 'Request Service'
	}, {
		path: '/services',
		title: 'Service Request History'
	}]
},

{
	icon: mdiCrown,
	title: 'V-Admin',
	links: [{
		path: '/admin/voucher/issue',
		title: 'Voucher Issue',
		scopes: [ 'admin:vouchers' ],
	}, /*{
		path: '/admin/services',
		title: 'Service Requests',
		scopes: [ 'admin:service' ]
	}, */{
		path: '/admin/products',
		title: 'Manage Products',
		scopes: [ 'admin:shops' ]
	}, {
		path: '/admin/users',
		title: 'Manage Users',
		scopes: [ 'admin:users' ]
	}, {
		path: '/admin/volunteers/manage',
		title: 'Manage Volunteers',
		scopes: [ 'admin:volunteers' ]
	}]
},

{
	icon: mdiChartBox,
	title: 'V-Reports',
	links: [{
		path: '/admin/reports/sales',
		title: 'Sales Report',
		scopes: [ 'admin:shops', 'cashier' ],
	}, {
		path: '/admin/reports/voucher',
		title: 'Voucher Issue',
		scopes: [ 'admin:vouchers' ]
	}, {
		path: '/admin/reports/voucher/balance',
		title: 'Voucher Balances',
		scopes: [ 'admin:vouchers' ]
	}, {
		path: '/admin/reports/meal',
		title: 'Meal Attendance',
		scopes: [ 'admin:vmeal', 'finance' ]
	}, {
		path: '/admin/reports/interco',
		title: 'Interco Report',
		scopes: [ 'finance' ]
	}, {
		path: '/admin/reports/penalty',
		title: 'Penalty Report',
		scopes: [ 'admin:vmeal' ]
	}, {
		path: '/admin/reports/appeal',
		title: 'Appeal Report',
		scopes: [ 'admin:vmeal' ]
	}, {
		path: '/admin/reports/invoice',
		title: 'Invoice Report',
		scopes: [ 'finance' ]
	}, {
		path: '/admin/reports/mrf',
		title: 'MRF Report',
		scopes: [ 'finance', 'cashier', 'admin:shops' ]
	}, {
		path: '/admin/reports/gift',
		title: 'Gift Claim Report',
		scopes: [ 'admin:gifts', 'finance' ]
	}, {
		path: '/admin/reports/products',
		title: 'Product Sales',
		scopes: [ 'cashier', 'admin:shops' ]
	}, {
		path: '/admin/reports/stock',
		title: 'Product Stocks',
		scopes: [ 'cashier', 'admin:shops' ]
	}]
}]