<template>
	<Container>
		
		<preloader v-if="loading" />
		<div v-else>
			
			<div class="md:flex md:items-end md:space-x-2 space-y-4 md:space-y-0">
				<div class="text-center md:text-left flex-grow">
					<div class="font-medium text-gray-500">{{ welcomePhrases[welcomePhraseIndex] }}</div>
					<div class="text-3xl font-medium">{{ userName }}</div>
				</div>
				
				<!-- Voucher balance -->
				<div class="text-center md:text-right">
					<div class="font-medium text-gray-400">Voucher Balance</div>
					<div class="md:text-3xl text-lg font-semibold">RM {{ voucherBalance }}</div>
				</div>
			</div>
			<hr class="hr">
			
			
			
			<div class="">
				<!-- This week's registrations -->
				<RegistrationList v-bind="{ selectedDate, registrations }" @select="dateSelected" />
				<hr class="hr">
				
				<preloader v-if="menuLoading" />
				<MenuView v-else v-bind="{ menu, menuDate }" />
			</div>
			
		</div>
		
		
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container'
import { mapState, mapGetters } from 'vuex'
import dayjs from 'dayjs'
import MenuView from './MenuView.vue'
import RegistrationList from './RegistrationList.vue'


export default {
	components: {
		Container,
		MenuView,
		RegistrationList,
	},
	computed: {
		...mapState('Auth', [ 'pending', 'userName' ]),
		...mapGetters('Auth', [ 'authenticated' ]),
		menuDate() {
			if (!this.selectedDate) return dayjs().format('YYYY-MM-DD');
			return this.selectedDate;
		}
	},
	watch: {
		selectedDate: 'loadMenu'
	},
	data() {
		return {
			loading: false,
			menuLoading: false,
			
			welcomePhraseIndex: 0,
			welcomePhrases: [
				'Welcome!',
				'Hello there',
				'Good day!',
				'How are you today?',
			],
			
			voucherBalance: '',
			menu: null,
			registrations: [],
			selectedDate: null,
		};
	},
	methods: {
		dateSelected(date) {
			this.selectedDate = date;
		},
		async loadMenu() {
			this.menuLoading = true;
			try {
				// Fetch menu
				const date = dayjs(this.selectedDate).format('YYYY-MM-DD');
				this.menu = await this.$api.request('GET', `meal/menu/types/${date}`);
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error loading menu');
			}
			this.menuLoading = false;
		}
	},
	async mounted() {
		if (!this.authenticated) {
			this.$router.replace('/login');
			return;
		}
		
		if (this.pending) {
			this.$router.replace('/onboarding');
			return;
		}
		
		this.welcomePhraseIndex = Math.floor(Math.random() * this.welcomePhrases.length);
		
		this.loading = true;
		try {
			// Fetch dashboard data
			const data = await this.$api.request('GET', `dashboard`);
			
			// Voucher balance
			this.voucherBalance = data.voucherBalance;
			
			
			// Registration status
			const registeredDates = data.registeredDates;
			this.registrations = [];
			let day = dayjs().startOf('week').add(1, 'day');
			for (let i = 0; i < 5; ++i) {
				const date = day.format('YYYY-MM-DD');
				const weekDay = day.format('ddd');
				const registered = registeredDates.includes(date);
				
				this.registrations.push({
					rawDate: date,
					date: day.format('D MMM'),
					day: weekDay,
					registered
				});
				day = day.add(1, 'day');
			}
			
			// Load menu
			this.selectedDate = dayjs().format('YYYY-MM-DD');
			await this.loadMenu();
			
			
		} catch(err) {
			console.error(err);
		}
		this.loading = false;
	}
}
</script>