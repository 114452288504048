import Faq from './Faq.vue'
import Unregister from './Unregister.vue'


export default [{
	path: '/faq',
	component: Faq,
	meta: {
		scopes: 'public'
	}
}, {
	path: '/unregister/:key',
	component: Unregister,
	props: true,
	meta: {
		scopes: 'public'
	}
}]