<template>
	<div class="px-2 py-4">
		<div class="flex space-x-4">
			
			<div class="w-1/2 space-y-2">
				<!-- Collected by user -->
				<div v-if="collectBy">
					<label class="label">Collected By</label>
					<div class="flex items-center space-x-2">
						<input type="text" class="input w-full" disabled :value="collectBy.userName">
						<button class="button" @click="collectBy = null">Clear</button>
					</div>
				</div>
				<div v-else>
					<label class="label">Collected By</label>
					<SuggestionInput
						@selected="setCollectBy"
						v-bind="{
							inputClass: 'input w-full',
							placeholder: 'Scan or type user name to search',
							suggest: suggestUser,
						}">
						
						<template v-slot="{ item }">
							<span class="font-medium mr-2">{{ item.userID }}</span>
							<span class="">{{ item.userName }}</span>
						</template>
						
					</SuggestionInput>
				</div>
				
				<!-- On behalf of user -->
				<div v-if="onBehalf">
					<label class="label">On Behalf</label>
					<div class="flex items-center space-x-2">
						<input type="text" class="input w-full" disabled :value="onBehalf.userName">
						<button class="button" @click="onBehalf = null">Clear</button>
					</div>
				</div>
				<div v-else>
					<label class="label">Charge To</label>
					<SuggestionInput
						@selected="setOnBehalf"
						v-bind="{
							inputClass: 'input w-full',
							placeholder: 'Scan or type user name to search',
							suggest: suggestUser,
						}">
						
						<template v-slot="{ item }">
							<span class="font-medium mr-2">{{ item.userID }}</span>
							<span class="">{{ item.userName }}</span>
						</template>
						
					</SuggestionInput>
				</div>
				
				
				<!-- Company selection -->
				<div>
					<label class="label">Company</label>
					<select class="input w-full" v-model="formData.company">
						<option value="" disabled>Select company</option>
						<option v-for="c in companyList" :key="c.company" :value="c.company">{{ c.company }}</option>
					</select>
				</div>
				
				
				<!-- BU selection -->
				<div>
					<label class="label">BU</label>
					<select class="input w-full" v-model="formData.bu">
						<option value="" disabled>Select BU</option>
						<option v-for="bu in buList" :key="bu.bu" :value="bu.bu">{{ bu.bu }}</option>
					</select>
				</div>
				
				<!-- Dept selection -->
				<div>
					<label class="label">Department</label>
					<select class="input w-full" v-model="formData.dept">
						<option value="" disabled>Select department</option>
						<option v-for="d in deptList" :key="d.dept" :value="d.dept">{{ d.dept }}</option>
					</select>
				</div>
			</div>
			
			
			<div class="w-1/2 space-y-2">
				
				<div>
					<label class="label">Type</label>
					<select class="input w-full" v-model="formData.type">
						<option value="" disabled>Select type</option>
						<option value="internal">Internal</option>
						<option value="external">External</option>
					</select>
				</div>
				
				
				<!-- Internal gifts -->
				<template v-if="formData.type == 'internal'">
					<div>
						<label class="label">Purpose</label>
						<select class="input w-full" v-model="formData.purpose">
							<option value="" disabled>Select gift purpose</option>
							<option v-for="p in PurposeList" :value="p">{{ p }}</option>
						</select>
					</div>
					<div v-if="formData.purpose.toLowerCase() == 'others'">
						<label class="label">Real Purpose</label>
						<input type="text" class="input w-full" placeholder="Enter real purpose" v-model.trim="formData.purposeOthers">
					</div>
				</template>
				
				<!-- External gifts -->
				<template v-if="formData.type == 'external'">
					<!-- Customer type -->
					<div>
						<label class="label">Customer Type</label>
						<select class="input w-full" v-model="formData.customerType">
							<option value="" disabled>Select customer type</option>
							<option v-for="t in CustomerType" :value="t">{{ t }}</option>
						</select>
					</div>
					<div v-if="formData.customerType == 'Others'">
						<label class="label">Real Customer Type</label>
						<input type="text" class="input w-full" v-model.trim="formData.customerTypeRemarks" placeholder="Please fill in actual customer type">
					</div>
					<div v-if="hasCustomerType2">
						<label class="label">Customer Type (2)</label>
						<select v-model="formData.customerType2" class="input w-full">
							<option value="" disabled>Select customer type (2)</option>
							<option value="old">Existing</option>
							<option value="new">Potential</option>
						</select>
					</div>
					
					<!-- Customer company -->
					<div>
						<label class="label">Customer Company</label>
						<input type="text" class="input w-full" v-model.trim="formData.customerCompany" placeholder="Customer's company">
					</div>
					
					<!-- Customer name -->
					<div>
						<label class="label">Customer Name</label>
						<input type="text" class="input w-full" v-model.trim="formData.customerName" placeholder="Customer name">
					</div>
					
					<div>
						<label class="label">Total Headcount (including claimee)</label>
						<input type="text" class="input w-full" v-model.trim="formData.headcount" placeholder="Total headcount">
					</div>
					
					<div>
						<label class="label">VGR #</label>
						<input type="text" class="input w-full" v-model.trim="formData.vgr" placeholder="VGR #">
					</div>
				
				</template>
				
				<div>
					<label class="label">Remarks</label>
					<textarea class="input w-full" v-model.trim="formData.remarks" placeholder="Additional remarks"></textarea>
				</div>
			</div>
			
		</div>
		
	</div>
</template>
<script>
import SuggestionInput from '@/components/SuggestionInput.vue'
import { mapState } from 'vuex'

export default {
	props: [ 'formData', 'companyList', 'buList', 'deptList' ],
	components: {
		SuggestionInput,
	},
	watch: {
		collectBy() {
			if (!this.collectBy) {
				this.onBehalf = null;
				this.formData.company = '';
				this.formData.bu = '';
				this.formData.dept = '';
			}
		},
		onBehalf() {
			if (!this.onBehalf && this.collectBy) {
				this.formData.company = this.collectBy.company;
				this.formData.bu = this.collectBy.bu;
				this.formData.dept = this.collectBy.dept;
			}
		}
	},
	computed: {
		...mapState('Constants', [ 'CustomerType' ]),
		hasCustomerType2() {
			if (!this.formData.customerType) return false;
			return [ 'Customer', 'Vendor', 'SCP/Distributor' ].includes(this.formData.customerType);
		}
	},
	data() {
		return {
			loading: false,
			q: '',
			
			collectBy: null,
			onBehalf: null,
			
			PurposeList: [
				'Hospitalization',
				'Newborn Baby',
				'Convocation',
				'V-Bingo',
				'Others',
			],
		};
	},
	methods: {
		async setCollectBy(user) {
			this.collectBy = user;
			
			if (!this.onBehalf) {
				this.formData.collectBy = user.userID;
				this.formData.company = user.company;
				this.formData.bu = user.bu;
				this.formData.dept = user.dept;
				try {
					const limit = await this.$api.request('GET', `gifts/limits/${user.userID}`);
					this.formData.claimLimit = limit;
					
				} catch(err) {
					console.error(err);
				}
			}
		},
		async setOnBehalf(user) {
			this.onBehalf = user;
			this.formData.onBehalf = user.userID;
			this.formData.company = user.company;
			this.formData.bu = user.bu;
			this.formData.dept = user.dept;
			try {
				const limit = await this.$api.request('GET', `gifts/limits/${user.userID}`);
				this.formData.claimLimit = limit;
				
			} catch(err) {
				console.error(err);
			}
		},
		async suggestUser(q) {
			const res = await this.$api.request('GET', `suggest/users?q=${encodeURIComponent(q)}`);
			return res;
		}
	}
	
}
</script>