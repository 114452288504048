<template>
	<div>
		<preloader v-if="loading" />
		<div v-else class="py-4">
			
			<div v-if="!user" class="text-2xl font-medium text-center text-gray-400 my-2">
				Please scan user to continue
			</div>
			
			<div v-else-if="penaltyAmount == '0.00'" class="text-2xl font-medium text-center text-gray-400 my-2">
				No pending penalties<br>
				Please scan again if penalty has been paid
			</div>
			
			<div v-else class="flex items-start">
				<!-- Penalty summary -->
				<div class="w-1/2">
					<div class="text-center">
						<div class="text-gray-500">Total Penalty Amount Payable:</div>
						<div class="text-3xl font-semibold">RM {{ penaltyAmount }}</div>
					</div>
					<hr class="hr">
					
					<div v-if="penalties.length">
						<div class="text-xl font-medium">Penalty Dates</div>
						<div>
							<div v-for="d in penalties">{{ d.date }}</div>
						</div>
					</div>
				</div>
				
				<!-- Payment -->
				<div class="w-1/2">
					<div class="text-center text-xl font-medium mb-2">Payment Method</div>
					<div class="flex item-center space-x-2 md:w-2/3 md:mx-auto">
						<button class="button is-primary w-1/2 text-xl font-medium" :class="{ loading: submitting }" @click="makePayment('card')">CARD</button>
						<button class="button is-primary w-1/2 text-xl font-medium" :class="{ loading: submitting }" @click="makePayment('qr')">QR</button>
					</div>
				</div>
			</div>
			
			
		</div>
		
		
		<Modal v-model="paymentDialogShown" containerClass="md:w-3/5">
			<PenaltyPaymentDialog v-bind="{ payment, penalties }" @close="closePaymentDialog" />
		</Modal>
		
		
	</div>
</template>
<script>
import Modal from '@/components/Modal.vue'
import PenaltyPaymentDialog from './PenaltyPaymentDialog.vue'
import { mapState } from 'vuex'

export default {
	props: [ 'user', 'socket' ],
	components: {
		Modal,
		PenaltyPaymentDialog,
	},
	computed: {
		...mapState('Constants', [ 'SST_RATE' ]),
	},
	watch: {
		user: 'fetchPenalties',
	},
	data() {
		return {
			loading: false,
			submitting: false,
			
			paymentDialogShown: false,
			
			payment: null,
			penalties: [],
			penaltyAmount: '0.00',
		};
	},
	methods: {
		async fetchPenalties() {
			if (!this.user) return;
			
			this.loading = true;
			try {
				const res = await this.$api.request('GET', `meal/penalties/user/${this.user.userID}`);
				this.penalties = res.penalties;
				this.penaltyAmount = res.penaltyAmount;
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error loading penalty for user');
			}
			this.loading = false;
		},
		
		
		
		// Make payment request
		async makePayment(method) {
			if (!this.user) return;
			
			this.submitting = true;
			try {
				// Calculate tax
				let taxAmount = 0;
				if (this.SST_RATE > 0) taxAmount = parseFloat(this.penaltyAmount) * (this.SST_RATE / 100);
				
				const payment = await this.$api.request('POST', 'meal/penalties/payment', {
					userID: this.user.userID,
				});
				
				
				
				this.payment = payment;
				this.paymentDialogShown = true;
				
				let paymentAmount = payment.paymentAmount;
				//if (taxAmount > 0) paymentAmount = parseFloat(payment.paymentAmount) + taxAmount;
				
				// Send request to terminal service
				await this.$devices.request('POST', `pay/penalty`, {
					paymentID: this.payment.paymentID,
					//amount: this.payment.paymentAmount,
					amount: paymentAmount,
					paymentMethod: method,
				});
				
				
			} catch(err) {
				if (err == 'Fail') {
					// Cancel order from API
					await this.$api.request('DELETE', `meal/penalties/payment/${this.payment.paymentID}`);
					this.$toast.info('Payment cancelled');
					this.closePaymentDialog();
					
				} else {
					console.error(err);
					this.$toast.error('Error making payment');
				}
			}
			this.submitting = false;
		},
		penaltyPaid(res) {
			if (!this.payment) return;
			if (res.paymentID != this.payment.paymentID) return;
			
			// Update status
			this.payment.status = res.status;
		},
		closePaymentDialog() {
			this.paymentDialogShown = false;
			this.payment = null;
			this.penaltyAmount = '0.00';
			
			this.$emit('penalty-paid');
		}
	},
	mounted() {
		this.fetchPenalties();
		if (this.socket) this.socket.on('meal/penalty_paid', res => this.penaltyPaid(res));
	},
	beforeDestroy() {
		if (this.socket) this.socket.off('meal/penalty_paid');
	}
}
</script>