import { emitter } from '@/utils'
import SidebarWrap from './SidebarWrap.vue'


export default {
	install(app) {
		// Register container as global component
		app.component('sidebar-wrap', SidebarWrap);
		
		const handler = {
			show() { emitter.emit('sidebar/show'); },
			hide() { emitter.emit('sidebar/hide'); },
			toggle() { emitter.emit('sidebar/toggle'); },
		};
		
		app.prototype.$sidebar = handler;
	}
}