<template>
	<div>
		
		<div v-for="t in menuTypes">
			<div class="flex justify-between px-2 py-2">
				<div class="text-2xl font-medium">{{ t.typeName }}</div>
				<div class="space-x-2">
					<button class="button is-accent" :class="{ loading: submitting }" @click="editType(t)">Edit</button>
					<button class="button is-danger" :class="{ loading: submitting }" @click="deleteType(t)">Delete</button>
				</div>
			</div>
			<hr>
		</div>
		
		
		<Modal v-model="typeFormShown" containerClass="md:w-3/5 px-3 py-2">
			<TypeForm v-if="activeType" v-bind="{ type: activeType }" @updated="updated" />
		</Modal>
		
	</div>
</template>
<script>
import Modal from '@/components/Modal'
import TypeForm from './TypeForm.vue'

export default {
	props: [ 'menuTypes' ],
	components: {
		Modal,
		TypeForm,
	},
	data() {
		return {
			submitting: false,
			typeFormShown: false,
			activeType: null,
		};
	},
	methods: {
		editType(t) {
			this.activeType = t;
			this.typeFormShown = true;
		},
		updated(res) {
			this.activeType = null;
			this.typeFormShown = false;
			this.$toast.success('Menu updated');
		},
		async deleteType(t) {
			const confirm = await this.$confirm({
				title: 'Delete Menu',
				message: 'Delete this menu entry?',
				buttons: 'YES_NO',
			});
			if (!confirm) return;
			
			this.submitting = true;
			try {
				await this.$api.request('DELETE', `meal/menu/types/${t.typeID}`);
				
				const index = this.menuTypes.findIndex(tt => tt.typeID == t.typeID);
				if (index >= 0) this.menuTypes.splice(index, 1);
				this.$toast.info('Menu deleted');
				
			} catch (err) {
				console.error(err);
				this.$toast.error('Failed to delete menu');
			}
			this.submitting = false;
		}
	}
}
</script>