<template>
	<Container>
		<div class="text-3xl font-semibold">Service Request History</div>
		<hr class="hr">
		
		<div class="md:flex md:gap-2 mb-4">
			<Datepicker v-model="startDate" v-bind="{
				placeholder: 'Start Date',
				inputClass: 'input'
			}" />
			
			<Datepicker v-model="endDate" v-bind="{
				placeholder: 'End Date',
				inputClass: 'input'
			}" />
		</div>
		
		<TabBar v-model="activeTab" v-bind="{ tabs }" class="mb-2" />
		<preloader v-if="loading" />
		<div v-else>
			<table class="table w-full text-sm">
				<thead>
					<tr>
						<th>Event Name</th>
						<th>CustCompany</th>
						<th>CustName</th>
						<th width="100px">Type</th>
						<th width="160px">Date</th>
						<th width="140px">VMeal</th>
						<th width="140px">VRefreshment</th>
						<th width="140px">VSavor</th>
						<th width="200px">OnBehalf</th>
						<th class="text-right" width="140px">Cost</th>
						<th width="180px">Timestamp</th>
						<th width="40px"></th>
					</tr>
				</thead>
				<tbody>
					<tr v-if="!records.length">
						<td colspan="9">No records available</td>
					</tr>
					
					<tr v-if="records.length">
						<td colspan="9">Total</td>
						<td class="text-right">{{ grandTotal }}</td>
					</tr>
					
					<tr v-for="r in records" :key="r.serviceID">
						<td>
							<router-link :to="`/service/view/${r.serviceID}`" class="font-medium text-accent-500 hover:text-accent-300">{{ r.eventName }}</router-link>
						</td>
						<td>{{ r.customerCompany }}</td>
						<td>{{ r.customerName }}</td>
						<td>{{ r.eventType }}</td>
						<td>{{ r.eventDate }}</td>
						<td>{{ resolveProduct('vmeal', r) }}</td>
						<td>{{ resolveProduct('vrefreshment', r) }}</td>
						<td>{{ resolveProduct('vsavor', r) }}</td>
						<td>{{ r.behalfName }}</td>
						<td class="text-right">{{ totalCost(r) }}</td>
						<td>{{ r.created }}</td>
						<td>
							<router-link
								v-if="canEdit"
								:to="`/service/edit/${r.serviceID}`"
								class="button small is-primary">Edit</router-link>
						</td>
					</tr>
					
				</tbody>
			</table>
			
			<Pagination class="mt-4" v-bind="{ curPage, totalRows, pageLimit }" @goto="loadPage" />
			
		</div>
		
		
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import Datepicker from '@/components/Datepicker'
import Pagination from '@/mixins/Pagination'
import TabBar from '@/components/TabBar.vue'
import { mapState } from 'vuex'
import dayjs from 'dayjs'

export default {
	components: {
		Container,
		TabBar,
		Datepicker,
	},
	mixins: [ Pagination ],
	computed: {
		...mapState('Constants', [ 'ServiceEventType' ]),
		canEdit() {
			return this.$store.state.Auth.scopes.includes('admin:service');
		},
		grandTotal() {
			let sum = 0;
			for (const i in this.records) {
				const r = this.records[i];
				sum += parseFloat(this.totalCost(r));
			}
			return sum.toFixed(2);
		}
	},
	watch: {
		activeTab() { this.loadPage(0); },
		startDate() { this.loadPage(0); },
		endDate() { this.loadPage(0); },
	},
	data() {
		return {
			loading: false,
			
			pageLimit: 100,
			startDate: null,
			endDate: null,
			
			activeTab: 'pending',
			tabs: {
				pending: 'Pending',
				complete: 'Complete',
			},
			
			productGroups: {},
			records: [],
		}
	},
	methods: {
		resolveProduct(type, r) {
			const group = this.productGroups[type];
			const count = r[`${type}Count`];
			if (!count || !r[type] || !group) return '-';
			
			const p = group.find(g => `${g.productID}:${g.variantID}` == r[type]);
			if (!p) return '-';
			
			if (type == 'vmeal' && p.variantName == 'VVIP') return `${p.variantName} (${r.mealCuisine}): ${count}`;
			
			return `${p.variantName}: ${count}`;
		},
		totalCost(r) {
			let total = 0;
			const types = [ 'vmeal', 'vsavor', 'vrefreshment' ];
			for (const i in types) {
				const t = types[i];
				
				const group = this.productGroups[t];
				const count = r[`${t}Count`];
				if (!count || !r[t] || !group) continue;
				
				const p = group.find(g => `${g.productID}:${g.variantID}` == r[t]);
				if (!p) continue
				
				total += count * parseFloat(p.price);
			}
			return total.toFixed(2);
		},
		async loadPage(page) {
			this.loading = true;
			try {
				
				const queries = [
					`page=${page}`,
					`limit=${this.pageLimit}`,
					`status=${this.activeTab}`,
				];
				
				if (this.startDate) queries.push(`start=${dayjs(this.startDate).format('YYYY-MM-DD')}`);
				if (this.endDate) queries.push(`end=${dayjs(this.endDate).format('YYYY-MM-DD')}`);
				
				const res = await this.$api.request('GET', `services?${queries.join('&')}`);
				this.curPage = page;
				this.totalRows = res.total;
				this.records = res.results.map(r => {
					return {
						...r,
						userName: r.requestor ? r.requestor.userName : '-',
						behalfName: r.behalf ? r.behalf.userName : '-',
					};
				});
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Failed to load page');
			}
			this.loading = false;
		}
	},
	async mounted() {
		const today = dayjs();
		this.startDate = today.startOf('month').toDate();
		this.endDate = today.endOf('month').toDate();
		
		this.loading = true;
		try {
			// Initialize product groups
			const productGroups = {};
			const products = await this.$api.request('GET', 'services/products');
			for (const i in products) {
				const p = products[i];
				if (!productGroups[p.serviceGroup]) productGroups[p.serviceGroup] = [];
				productGroups[p.serviceGroup].push(p);
			}
			this.productGroups = productGroups;
			
			this.loadPage(0);
			
		} catch(err) {
			console.error(err);
			this.$toast.error('Error loading data');
		}
		this.loading = false;
		
	}
}
</script>