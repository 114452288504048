const PINNED_ITEM_KEY = 'cashier_pinned_items';

export default {
	namespaced: true,
	state: {
		pinnedItems: [],
		terminalSettings: null,
		queueNumEnabled: false,
	},
	getters: {
	},
	mutations: {
		enableQueueNum(state, enable) {
			state.queueNumEnabled = enable;
			localStorage.setItem('cashier_queueNum', enable);
		}
	},
	actions: {
		async init({ state }) {
			try {
				// Load pinned items
				const pinned = localStorage.getItem(PINNED_ITEM_KEY);
				if (pinned) state.pinnedItems = JSON.parse(pinned);
				else state.pinnedItems = [];
				
				// Load terminal settings
				const $devices = this._vm.$devices;
				state.terminalSettings = await $devices.request('GET', `terminal/settings`);
				if (!state.terminalSettings) throw 'terminal_disconnected';
				
				const queries = [
					`host=${state.terminalSettings.host}`,
					`port=${state.terminalSettings.port}`,
				];
				await $devices.request('GET', `terminal/ping?${queries.join('&')}`);
				
			} catch(err) {
				console.error(err);
				throw 'terminal_disconnected'
			}
			
			// Load other settings
			state.queueNumEnabled = localStorage.getItem('cashier_queueNum') == 'true';
		},
		
		addPinnedItem({ state }, item) {
			// Check if existing
			const index = state.pinnedItems.findIndex(it => it.productID == item.productID && it.variantID == item.variantID);
			if (index >= 0) return;
			
			state.pinnedItems.push({
				productID: item.productID,
				variantID: item.variantID,
				productName: item.productName,
				variantName: item.variantName,
				price: item.price,
				isTaxable: item.isTaxable,
				isInventory: item.isInventory,
			});
			localStorage.setItem(PINNED_ITEM_KEY, JSON.stringify(state.pinnedItems));
		},
		removePinnedItem({ state }, item) {
			const index = state.pinnedItems.findIndex(it => it.productID == item.productID && it.variantID == item.variantID);
			if (index >= 0) {
				state.pinnedItems.splice(index, 1);
				localStorage.setItem(PINNED_ITEM_KEY, JSON.stringify(state.pinnedItems));	
			}
		},
		updatePinnedItems({ state }) {
			localStorage.setItem(PINNED_ITEM_KEY, JSON.stringify(state.pinnedItems));
		},
		
		async setTerminalIP({ state }, terminal) {
			const $devices = this._vm.$devices;
			
			await $devices.request('PUT', `terminal/settings`, {
				host: terminal.host,
				port: terminal.port,
			});
			state.terminalSettings = {
				host: terminal.host,
				port: terminal.port,
			};
		},
		
		async pingTerminal({ state }) {
			const $devices = this._vm.$devices;
			if (!state.terminalSettings) throw 'no_terminal';
			const host = state.terminalSettings.host;
			const port = state.terminalSettings.port;
			
			await $devices.request('GET', `terminal/ping`, { host, port });
		}
	}
}