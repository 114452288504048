<template>
	<Container>
		<div class="flex items-center space-x-2">
			<div class="text-3xl font-semibold flex-grow">V-Meal Registration List</div>
			
			<button class="button is-info" @click="weekDialogShown = true">Week View</button>
			
			<router-link v-if="canModify" :to="`/admin/meal/register/${dateStr}`" class="button is-primary">Register User</router-link>
		</div>
		<hr class="hr">
		
		<div class="md:flex md:items-center">
			<div class="inline-flex space-x-2">
				<button class="button is-primary" @click="modifyDate(-1)">
					<icon :data="mdiArrowLeft" class="w-5 h-5" />
				</button>
				<Datepicker v-model="date" />
				<button class="button is-primary" @click="modifyDate(1)">
					<icon :data="mdiArrowRight" class="w-5 h-5" />
				</button>
			</div>
			<div class="md:flex-grow md:flex md:items-center md:justify-evenly md:mx-0 md:my-0 mx-2 my-2 ">
				<div v-for="(s, sk) in statistics" :key="sk" class="md:text-center md:space-x-0 space-x-2 font-medium">
					<div class="md:block inline-block md:text-3xl">{{ s }}</div>
					<div class="md:block inline-block uppercase text-gray-400">{{ sk }}</div>
				</div>
			</div>
		</div>
		<hr class="hr">
		
		<preloader v-if="loading" />
		<div v-else class="overflow-x-auto">
			<table class="table w-full">
				<thead>
					<tr>
						<th width="10%">ID</th>
						<th>Name</th>
						<th width="10%">Company</th>
						<th width="10%">BU</th>
						<th width="10%">Dept</th>
						<th width="10%">Status</th>
						<th width="40px"></th>
					</tr>
				</thead>
				<tbody>
					<tr v-if="!rows.length">
						<td colspan="6">No registered users</td>
					</tr>
					<tr v-for="(r, ri) in rows" :key="ri">
						<td>{{ r.userID }}</td>
						<td>{{ r.userName }}</td>
						<td>{{ r.company }}</td>
						<td>{{ r.bu }}</td>
						<td>{{ r.dept }}</td>
						<td class="uppercase">{{ r.status }}</td>
						<td class="text-right">
							<button v-if="canModify && r.status == 'unclaimed'" class="px-2 hover:text-danger-500" @click="unregisterUser(r)">
								<icon :data="mdiClose" class="w-5 h-5" />
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		
		<Modal v-model="weekDialogShown" containerClass="md:w-4/5">
			<WeeklyHeadcount />
		</Modal>
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import Datepicker from '@/components/Datepicker'
import Modal from '@/components/Modal.vue'
import WeeklyHeadcount from './WeeklyHeadcount.vue'
import dayjs from 'dayjs'
import { mdiArrowLeft, mdiArrowRight, mdiClose } from '@mdi/js'

export default {
	components: {
		Container,
		Datepicker,
		Modal,
		WeeklyHeadcount,
	},
	watch: {
		date: 'fetchDate',
	},
	computed: {
		dateStr() {
			if (!this.date) return null;
			return dayjs(this.date).format('YYYY-MM-DD');
		},
		canModify() {
			return this.$store.state.Auth.scopes.includes('admin:vmeal:register');
		}
	},
	data() {
		return {
			mdiClose,
			mdiArrowLeft,
			mdiArrowRight,
			loading: false,
			
			weekDialogShown: false,
			
			date: null,
			statistics: {
				unregistered: 0,
				unclaimed: 0,
				claimed: 0,
				waived: 0,
				penalized: 0,
				total: 0,
			},
			
			rows: [],
		};
	},
	methods: {
		modifyDate(step) {
			if (!this.date) return;
			this.date = dayjs(this.date).add(step, 'day').toDate();
		},
		async fetchDate() {
			if (!this.date) return;
			
			this.loading = true;
			try {
				const date = dayjs(this.date).format('YYYY-MM-DD');
				const rows = await this.$api.request('GET', `meal/registrations/list?date=${date}`);
				this.rows = rows;
				
				// Reset statistics
				for (const i in this.statistics) {
					this.statistics[i] = 0;
				}
				
				// Count statistics
				for (const i in rows) {
					const row = rows[i];
					if (this.statistics[row.status] !== undefined) {
						if (row.status != 'total') {
							++this.statistics[row.status];
							++this.statistics.total;
						}
					}
				}
				
				// Sort rows
				const sortOrder = {
					unclaimed: 0,
					claimed: 1,
					unregistered: 2,
					waived: 3,
					penalized: 4,
					outsider: 5,
				};
				this.rows.sort((a, b) => {
					if (a.status != b.status) return sortOrder[a.status] - sortOrder[b.status];
					if (!a.userName) return 1;
					else if (!b.userName) return -1;
					return a.userName.localeCompare(b.userName);
				});
				
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error fetching list');
			}
			this.loading = false;
		},
		
		async unregisterUser(user) {
			if (!user) return;
			const confirm = await this.$confirm({
				title: 'Unregister User',
				message: `Unregister V-Meal for [${user.userName}]?`,
				buttons: 'YES_NO',
			});
			if (!confirm) return;
			
			
			
			this.submitting = true;
			try {
				
				const date = dayjs(this.date).format('YYYY-MM-DD');
				await this.$api.request('DELETE', `meal/registrations/${user.userID}/${date}`);
				
				const index = this.rows.findIndex(u => u.userID == user.userID);
				if (index >= 0) this.rows.splice(index, 1);
				
				
				this.$toast.info('Unregistered user');
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error unregistering user');
			}
			this.submitting = false;
			
		}
	},
	mounted() {
		this.date = new Date();
	}
}
</script>