<template>
	<Container>
		<div class="text-3xl font-semibold">V-Meal Appeal Form</div>
		<hr class="hr">
		
		
		<preloader v-if="loading" />
		<div v-else-if="!penalties.length">
			<div class="text-center text-2xl text-gray-500">No penalty records</div>
		</div>
		<div v-else class="md:flex md:items-stretch">
			<div class="md:w-1/4">
				<div class="text-lg mb-2">Select dates to appeal:</div>
				<div v-for="p in penalties" :key="p.date">
					<label class="inline-flex items-center space-x-2">
						<input type="checkbox" :value="p.date" v-model="selectedDates">
						<span class="font-medium select-none">{{ p.date }}</span>
					</label>
				</div>	
			</div>
			
			<div class="md:flex-grow">
				<!--
				<div>
					<label class="font-medium mb-1">Reason</label>
					<textarea class="input w-full" v-model.trim="reason" placeholder="Enter reason for appeal" rows="6"></textarea>
				</div>
				-->
				<div>
					<label class="font-medium mb-1">Reason</label>
					<select v-model="reason" class="input w-full">
						<option value="" disabled>Select reason</option>
						<option v-for="ar in acceptedReasons">{{ ar }}</option>
					</select>
				</div>
				<div class="font-medium px-2 py-2 text-danger-700">
					If your reason is not listed in the selection list above, then it is not acceptable.<br>
					Please pay your penalty fees at the counter.
				</div>
				<hr class="hr">
				
				<div class="text-right space-x-2">
					<button class="button is-primary" :class="{ loading: submitting }" @click="submit">Submit</button>
				</div>
				
			</div>
			
		</div>
		
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
export default {
	components: {
		Container,
	},
	data() {
		return {
			loading: false,
			submitting: false,
			
			penalties: [],
			selectedDates: [],
			reason: '',
			
			acceptedReasons: [
				'Emergency Leave',
				'Medical Leave',
				'Compassionate Leave',
				'Hospitalization Leave',
				'Calamity Leave',
				'Work From Home Special',
				'Work From Home (Covid-19)',
				'Business Trip',
				'Paid Time-off',
				'Urgent Customer Support',
				'Forgot to Scan',
			],
		};
	},
	methods: {
		async submit() {
			if (!this.selectedDates.length) {
				this.$toast.error('Please select date(s) for appeal');
				return;
			}
			if (!this.reason) {
				this.$toast.error('Please enter reason for appeal');
				return;
			}
			
			this.submitting = true;
			try {
				await this.$api.request('POST', `meal/appeal`, {
					dates: this.selectedDates,
					reason: this.reason,
				});
				
				// Remove dates from selection list
				for (let i = 0; i < this.penalties.length; ++i) {
					if (this.selectedDates.includes(this.penalties[i].date)) {
						this.penalties.splice(i, 1);
						--i;
					}
				}
				
				// Clear form state
				this.selectedDates = [];
				this.reason = '';
				
				this.$toast.success('Appeal form submitted');
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error submitting appeal form');
			}
			this.submitting = false;
		}
	},
	async mounted() {
		this.loading = true;
		try {
			
			this.penalties = await this.$api.request('GET', `meal/appeal`);
			
		} catch(err) {
			console.error(err);
			this.$toast.error('Error loading records');
		}
		this.loading = false;
	}
}
</script>