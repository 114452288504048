import Vuex from 'vuex'
import Auth from '@/store/Auth'
import Cashier from '@/store/Cashier'
import Constants from '@/store/Constants'


export default () => {
	return new Vuex.Store({
		state: {
			storedPath: null,
		},
		mutations: {
			// Stores the target path to redirect to after authentication
			storePath(state, path) { state.storedPath = path; },
		},
		actions: {
			
		},
		modules: {
			// Auth module
			Auth,
			// App wide constants
			Constants,
			
			// Cashier settings
			Cashier,
		}
	})
	
}