<template>
	<div>
		<div class="text-2xl font-semibold">{{ type ? 'Edit' : 'Add' }} Menu</div>
		<hr class="hr">
		
		<div class="space-y-4">
			<div>
				<label class="label">Menu Name</label>
				<input type="text" class="input w-full" placeholder="Enter menu name" v-model.trim="typeName">
			</div>
			
			
			<div class="flex items-center gap-2">
				<input type="file" ref="fileInput" accept=".jpg,.png" class="hidden" @change="fileSelected">
				<button class="button is-primary" @click="selectFile">Select File</button>
				<div v-if="image">{{ image.name }}</div>
			</div>
			
			<img v-if="!image && imageURL" :src="imageURL" style="height:400px">
			
			<div v-if="type && image" class="text-sm italic text-info-500">Old image will be replaced</div>
			
			
		</div>
		<hr class="hr">
		
		<div>
			<button class="button is-primary" :class="{ loading: submitting }" @click="save">Save</button>
		</div>
		
	</div>
</template>
<script>
import axios from 'axios'
import ImageTools from '@/utils/ImageTools'


export default {
	props: [ 'type' ],
	data() {
		return {
			submitting: false,
			typeName: '',
			imageURL: null,
			image: null,
		};
	},
	methods: {
		selectFile() { this.$refs.fileInput.click(); },
		async fileSelected(e) {
			if (!e.target.files.length) return;
			
			const allowedTypes = [ 'image/png', 'image/jpeg' ];
			const file = e.target.files[0];
			if (!allowedTypes.includes(file.type)) {
				this.$toast.error('Invalid file type');
				return;
			}
			
			// Resize image before upload
			this.image = file;
		},
		
		async uploadImage(uploadURL, typeID) {
			if (!this.image) return;
			
			this.submitting = true;
			const image = await ImageTools.resize(this.image, { width: 400, height: 400 });
			await axios.request({
				method: 'PUT',
				url: uploadURL,
				data: image,
				headers: {
					'Content-Disposition': `inline; filename="menu_${encodeURIComponent(typeID)}"`,
					'Content-Type': 'application/octet-stream',
				}
			});
			this.submitting = false;
		},
		
		async save() {
			if (!this.typeName) {
				this.$toast.error('Please enter menu name');
				return;
			}
			
			
			this.submitting = true;
			try {
				
				if (!this.type) {
					// Insert new type
					const res = await this.$api.request('POST', `meal/menu/types`, {
						typeName: this.typeName,
					});
					if (res.uploadURL) await this.uploadImage(res.uploadURL, res.typeID);
					
					this.$emit('added', res);
				} else {
					// Update existing
					const res = await this.$api.request('POST', `meal/menu/types/${this.type.typeID}`, {
						typeName: this.typeName,
					});
					if (res.uploadURL) await this.uploadImage(res.uploadURL, this.type.typeID);
					this.type.typeName = res.typeName;
					this.type.imageURL = res.imageURL;
					this.$emit('updated', res);
				}
				
				
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error uploading image');
			}
			this.submitting = false;
		}
	},
	mounted() {
		if (!this.type) return;
		this.typeName = this.type.typeName;
		this.imageURL = this.type.imageURL;
	}
}

</script>