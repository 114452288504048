<template>
	<div>
		
		<div class="text-right mb-2">
			<button class="button is-primary" @click="addVariant">Add Variant</button>
		</div>
		
		
		<preloader v-if="loading" />
		<table v-else class="table w-full">
			<thead>
				<tr>
					<th>Variant Name</th>
					<th width="200px">Barcode</th>
					<th width="200px" class="text-right">Price (RM)</th>
					<th width="200px">Epicor PartID</th>
					<th width="60px" class="text-center">Inventory</th>
					<th width="150px"></th>
				</tr>
			</thead>
			<tbody>
				<tr v-if="!variants.length">
					<td colspan="6">No variants added</td>
				</tr>
				
				<tr v-for="(v, vi) in variants" :key="vi">
					<td>{{ v.variantName }}</td>
					<td>{{ v.barcode }}</td>
					<td class="text-right">{{ v.price }}</td>
					<td>{{ v.epicorPartID }}</td>
					<td class="text-center">
						<icon v-if="v.isInventory" :data="mdiCheck" class="w-5 h-5" />
					</td>
					<td class="space-x-2 text-right">
						<button class="button is-primary text-sm px-2 py-1" @click="editVariant(v)">Edit</button>
						<button class="button is-danger text-sm px-2 py-1" :class="{ loading: submitting }" @click="deleteVariant(v)">Delete</button>
					</td>
				</tr>
			</tbody>
		</table>
		
		
		<Modal v-model="formShown" containerClass="w-3/5">
			<div class="text-3xl font-medium mb-4">Edit Variant</div>
			<div class="space-y-2">
				<div>
					<label class="label">Variant Name</label>
					<input type="text" class="input w-full" placeholder="Variant name" v-model.trim="formData.variantName">
				</div>
				
				<div>
					<label class="label">Barcode</label>
					<input type="text" class="input w-full" placeholder="Barcode" v-model.trim="formData.barcode">
				</div>
				
				<div>
					<label class="label">Epicor PartID</label>
					<input type="text" class="input w-full" placeholder="Epicor PartID" v-model.trim="formData.epicorPartID">
				</div>
				
				<div>
					<label class="label">Price (RM)</label>
					<input type="text" class="input w-full" placeholder="Price" v-model.number="formData.price">
				</div>
				
				<div>
					<label class="flex items-center space-x-2">
						<input type="checkbox" v-model="formData.isInventory">
						<span class="font-medium select-none">Inventory Control</span>
					</label>
				</div>
			</div>
			<hr class="hr">
			
			
			<div>
				<button class="button is-primary" :class="{ loading: submitting }" @click="save">Save</button>
			</div>
			
		</Modal>
		
	</div>
</template>
<script>
import Modal from '@/components/Modal.vue'
import { mdiCheck } from '@mdi/js'

export default {
	props: [ 'productID' ],
	components: {
		Modal,
	},
	data() {
		return {
			mdiCheck,
			loading: false,
			submitting: false,
			
			variants: [],
			
			formShown: false,
			activeVariantID: '',
			formData: {
				variantName: '',
				barcode: '',
				price: '',
				epicorPartID: '',
				isActive: false,
				isInventory: false,
			},
		};
	},
	methods: {
		addVariant() {
			this.activeVariantID = '';
			this.formData.variantName = '';
			this.formData.barcode = '';
			this.formData.price = '';
			this.formData.epicorPartID = '';
			this.formData.isInventory = false;
			this.formShown = true;
		},
		editVariant(v) {
			this.activeVariantID = v.variantID;
			this.formData.variantName = v.variantName;
			this.formData.barcode = v.barcode;
			this.formData.price = v.price;
			this.formData.epicorPartID = v.epicorPartID;
			this.formData.isInventory = v.isInventory == '1';
			this.formShown = true;
		},
		async deleteVariant(v) {
			const confirm = await this.$confirm({
				title: 'Delete Product Variant',
				message: 'Confirm to delete this variant?',
				buttons: 'YES_NO',
			});
			if (!confirm) return;
			
			
			this.submitting = true;
			try {
				// Delete from backend
				await this.$api.request('DELETE', `products/${this.productID}/variants/${v.variantID}`);
				
				// Remove from list
				const vi = this.variants.findIndex(vv => vv.variantID == v.variantID);
				if (vi >= 0) this.variants.splice(vi, 1);
				
				this.$toast.info('Variant deleted');
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error deleting variant');
			}
			this.submitting = false;
		},
		async save() {
			if (!this.formData.variantName) {
				this.$toast.error('Please enter variant name');
				return;
			}
			if (!this.formData.price) {
				this.$toast.error('Please enter price');
				return;
			}
			
			
			this.submitting = true;
			try {
				
				if (this.activeVariantID) {
					// Update variant
					await this.$api.request('PUT', `products/${this.productID}/variants/${this.activeVariantID}`, {
						variantName: this.formData.variantName,
						barcode: this.formData.barcode,
						price: this.formData.price,
						epicorPartID: this.formData.epicorPartID,
						isInventory: this.formData.isInventory,
					});
					
					const vi = this.variants.findIndex(vv => vv.variantID == this.activeVariantID);
					if (vi >= 0) {
						this.variants[vi].variantName = this.formData.variantName;
						this.variants[vi].barcode = this.formData.barcode;
						this.variants[vi].price = this.formData.price;
						this.variants[vi].epicorPartID = this.formData.epicorPartID;
						this.variants[vi].isInventory = this.formData.isInventory;
						
						if (typeof this.variants[vi].price == 'number') this.variants[vi].price = this.variants[vi].price.toFixed(2);
					}
					
				} else {
					// New variant
					const v = await this.$api.request('POST', `products/${this.productID}/variants`, {
						variantName: this.formData.variantName,
						barcode: this.formData.barcode,
						price: this.formData.price,
						epicorPartID: this.formData.epicorPartID,
						isInventory: this.formData.isInventory,
					});
					
					this.variants.push(v);
				}
				
				
				this.formShown = false;
				this.$toast.success('Variant saved');
				
			} catch(err) {
				if (err == 'barcode_exists') {
					this.$toast.error('Barcode already exists for another product');
				} else {
					console.error(err);
					this.$toast.error('Error saving variant');
				}
			}
			this.submitting = false;
		}
	},
	async mounted() {
		this.loading = true;
		try {
			// Fetch list of variants
			this.variants = await this.$api.request('GET', `products/${this.productID}/variants`);
			
		} catch(err) {
			console.error(err);
			this.$toast.error('Failed to fetch variants');
		}
		this.loading = false;
	}
}
</script>