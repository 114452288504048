<style lang="scss" scoped>
.list-enter-active,
.list-leave-active {
	transition: all 0.5s ease;
}
.list-enter,
.list-leave-to {
	opacity: 0;
	transform: translateY(30px);
}
</style>
<template>
	<Container>
		
		<div class="text-right">
			<button @click="enterFullscreen" class="button is-primary">
				<icon :data="mdiPresentation" class="w-6 h-6" />
			</button>
		</div>
		
		<preloader v-if="loading" />
		<div v-else class="h-full flex items-stretch bg-white" ref="orderList">
			<div class="w-1/2 px-2">
				<div class="text-center text-3xl font-semibold my-4">Preparing</div>
				<transition-group name="list" tag="div" class="grid grid-cols-4 gap-2">
					<div
						v-for="o in paidList"
						:key="`${o.orderID}-paid`"
						class="font-medium text-center text-3xl border border-gray-300 py-2">
						{{ o.queueNum }}
					</div>
				</transition-group>
				
			</div>
			
			<div class="w-px bg-gray-300"></div>
			
			<div class="w-1/2 px-2">
				<div class="text-center text-3xl font-semibold my-4">Ready</div>
				<transition-group name="list" tag="div" class="grid grid-cols-4 gap-2">
					<div
						v-for="o in readyList"
						:key="`${o.orderID}-ready`"
						class="font-medium text-center text-3xl border border-gray-300 py-2">
						{{ o.queueNum }}
					</div>
				</transition-group>
			</div>
			
		</div>
		
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import { mdiPresentation } from '@mdi/js'
import { createWS } from '@/utils/ws'


export default {
	components: {
		Container,
	},
	data() {
		return {
			mdiPresentation,
			loading: false,
			
			socket: null,
			readySound: null,
			paidList: [],
			readyList: [],
		};
	},
	methods: {
		initSocket() {
			const s = createWS(this.$store.state.Auth.jwt);
			
			// Add to paid list
			s.on('order/order_paid', o => {
				const existing = this.paidList.find(it => it.orderID == o.orderID);
				if (!existing) this.paidList.unshift(o);
			});
			
			// Move from paid list to ready list
			s.on('order/order_ready', o => {
				const paidIndex = this.paidList.findIndex(p => p.orderID == o.orderID);
				if (paidIndex >= 0) this.paidList.splice(paidIndex, 1);
				
				// Check if existing in ready list, add in if not
				const ready = this.readyList.find(r => r.orderID == o.orderID);
				if (!ready) {
					this.readyList.push(o);
					if (!this.readySound.ended) {
						this.readySound.currentTime = 0;
					}
					this.readySound.play();
				}
			});
			
			// Remove from ready list
			s.on('order/order_collected', o => {
				const readyIndex = this.readyList.findIndex(r => r.orderID == o.orderID);
				if (readyIndex >= 0) this.readyList.splice(readyIndex, 1);
			});
			
			this.socket = s;
		},
		
		async enterFullscreen() {
			const orderList = this.$refs.orderList;
			await orderList.requestFullscreen();
		},
	},
	async mounted() {
		this.loading = true;
		try {
			// Init websocket
			this.initSocket();
			
			
			// Load sound
			this.readySound = new Audio('/sfx/slow_dingdong.mp3');
			
			// Fetch initial list of orders
			const orders = await this.$api.request('GET', `orders/display`);
			
			// Sort orders into the appropriate lists
			this.paidList = [];
			this.readyList = [];
			for (const i in orders) {
				const o = orders[i];
				if (o.status == 'paid') this.paidList.push(o);
				else if (o.status == 'ready') this.readyList.push(o);
			}
			
		} catch(err) {
			console.error(err);
			this.$toast.error('Error loading orders');
		}
		this.loading = false;
	}
}
</script>