<style lang="scss" scoped>

.modal-cover {
	@apply bg-black fixed z-10 inset-0 w-full h-full flex justify-center items-center transition-opacity bg-opacity-75;
	
	.modal-container {
		@apply bg-white transition-transform;
	}
}

// Modal transitions
.modal-enter { opacity: 0; }
.modal-leave-active { opacity: 0; }

.modal-enter .modal-container,
.modal-leave-active .modal-container {
	transform: scale(1.1);
}
</style>
<template>
	<div>
		<!-- The input -->
		<input readonly type="text" :class="inputClass" :disabled="disabled" :placeholder="placeholder" @click="showPicker = true" :value="displayedDate">
		
		<!-- Embedded modal -->
		<Modal v-model="showPicker" containerClass="p-0">
			<PickerHeader v-bind="{ enablePrev, enableNext }"
				@prev="movePage(-1)" @next="movePage(1)">
				<span class="cursor-pointer font-semibold hover:text-accent-500 transition-colors duration-200" @click="switchMode">
					{{ pickerTitle }}
				</span>
			</PickerHeader>
			
			<transition name="fade" mode="out-in">
				<div style="width:280px;height:220px" class="overflow-hidden mb-1 px-1" :key="`${mode}-${year}-${month}`">
					<component
						:is="activeComponent.component"
						@input="activeComponent.inputHandler"
						v-bind="{
							year,
							month,
							date,
							value: curDate,
							range: activeRange,
						}" />
				</div>
			</transition>
		</Modal>
	</div>
</template>
<script>
import Modal from '../Modal'
import PickerHeader from './PickerHeader'
import DateSelect from './DateSelect'
import MonthSelect from './MonthSelect'
import YearSelect from './YearSelect'
import dayjs from 'dayjs'

export default {
	props: {
		modelValue: [ String, Date ],
		format: {
			type: String,
			default: 'YYYY-MM-DD'
		},
		inputClass: {
			type: [ String, Array ],
			default: 'input'
		},
		
		
		minDate: {
			type: [ String, Date ],
			default: null,
		},
		maxDate: {
			type: [ String, Date ],
			default: null,
		},
		
		minLevel: {
			type: String,
			default: null,
			validator(value) {
				return ['date', 'month', 'year'].includes(value);
			}
		},
		
		placeholder: String,
		disabled: Boolean,
	},
	model: {
		prop: 'modelValue',
		event: 'update:modelValue',
	},
	components: {
		Modal,
		PickerHeader,
		DateSelect,
		MonthSelect,
		YearSelect,
	},
	computed: {
		
		pickerTitle() {
			if (this.mode == 'date') return `${this.monthNames[this.month]} ${this.year}`;
			else if (this.mode == 'month') return this.year.toString();
			else if (this.mode == 'year') {
				const from = Math.floor(this.year / 12) * 12;
				const to = from + 11;
				return `${from} - ${to}`;
			}
		},
		
		activeRange() {
			const from = dayjs(this.minDate);
			const to = dayjs(this.maxDate);
			return {
				from: from.isValid() ? from : null,
				to: to.isValid() ? to : null,
			}
		},
		enableNext() {
			const to = this.activeRange.to;
			if (!to) return true;
			
			if (this.mode == 'date') {
				return `${this.year}${(this.month + 1).toString().padStart(2, '0')}` < to.format('YYYYMM');
			} else if (this.mode == 'month') {
				return this.year < to.year();
			} else if (this.mode == 'year') {
				const toYear = Math.floor(this.year / 12) * 12 + 11;
				return toYear < to.year();
			}
			
			return false;
		},
		enablePrev() {
			const from = this.activeRange.from;
			if (!from) return true;
			
			if (this.mode == 'date') {
				return `${this.year}${(this.month + 1).toString().padStart(2, '0')}` > from.format('YYYYMM');
			} else if (this.mode == 'month') {
				return this.year > from.year();
			} else if (this.mode == 'year') {
				const fromYear = Math.floor(this.year / 12) * 12;
				return fromYear > from.year();
			}
			
			return false;
		},
		
		displayedDate() {
			const date = dayjs(this.modelValue);
			if (!date.isValid()) return '';
			return date.format(this.format);
		},
		
		curDate() {
			const date = dayjs(this.modelValue);
			if (!date.isValid()) return '';
			return date.toDate();
		},
		
		activeComponent() {
			const components = {
				date: {
					component: 'DateSelect',
					inputHandler: this.dateSelected,
				},
				month: {
					component: 'MonthSelect',
					inputHandler: this.monthSelected,
				},
				year: {
					component: 'YearSelect',
					inputHandler: this.yearSelected,
				},
			};
			return components[this.mode];
		}
	},
	data() {
		return {
			showPicker: false,
			monthNames: [
				'January', 'February', 'March',
				'April', 'May', 'June',
				'July', 'August', 'September',
				'October', 'November', 'December'
			],
			
			modeLevels: [ 'date', 'month', 'year' ],
			
			mode: 'date',
			year: null,
			month: null,
			date: null,
		};
	},
	methods: {
		dateSelected(d) {
			this.date = d;
			this.$emit('update:modelValue', new Date(this.year, this.month, this.date));
			this.$nextTick(() => { this.showPicker = false; });
		},
		monthSelected(m) {
			this.month = m;
			if (this.minLevel != 'month') this.mode = 'date';
			else {
				this.$emit('update:modelValue', new Date(this.year, this.month, 1));
				this.$nextTick(() => { this.showPicker = false; });
			}
		},
		yearSelected(y) {
			this.year = y;
			if (this.minLevel != 'year') this.mode = 'month';
			else {
				this.$emit('update:modelValue', new Date(this.year, 0, 1));
				this.$nextTick(() => { this.showPicker = false; });
			}
		},
		
		switchMode() {
			if (this.mode == 'date') this.mode = 'month';
			else if (this.mode == 'month') this.mode = 'year';
			else if (this.mode == 'year') this.mode = 'month';
		},
		
		movePage(n) {
			n = Math.sign(n);
			if (this.mode == 'date') {
				this.month += n;
				if (this.month >= 12) {
					this.month = 0;
					++this.year;
				} else if (this.month < 0) {
					this.month = 11;
					--this.year;
				}
			} else if (this.mode == 'month') {
				this.year += n;
			} else if (this.mode == 'year') {
				this.year += n * 12;
			}
		}
		
	},
	mounted() {
		let today = dayjs(this.modelValue);
		if (!today.isValid()) today = dayjs();
		
		this.year = today.year();
		this.month = today.month();
		this.date = today.date();
		
		if (this.minLevel) this.mode = this.minLevel;
	}
}
</script>