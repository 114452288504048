<template>
	<div>
		
		<div v-if="activeDate" class="space-x-2 mb-2">
			<button class="button is-primary" :class="{ loading: submitting }" @click="loadSuggestions">Load More Suggestions</button>
			<button class="button is-accent" :class="{ loading: submitting }" @click="addAllSuggestions">Add All</button>
			<button class="button is-accent" @click="addModalShown = true">Add Specific</button>
		</div>
		
		<preloader v-if="fetching" />
		<div v-else>
			<div v-for="(s, si) in suggestions"
				:key="si"
				class="px-2 py-1 flex items-center gap-2 cursor-pointer select-none hover:bg-gray-200">
				
				<div class="flex-grow">
					<div class="font-medium">{{ s.userName }}</div>
					<div class="text-gray-600">{{ s.bu }} - {{ s.dept }}</div>
					<div class="text-sm text-gray-400">Volunteer count: {{ s.count }}</div>
				</div>
				
				<button class="button is-primary px-2 py-1" :class="{ loading: submitting }" @click="addVolunteer(s.userID)">Add</button>
				
			</div>
		</div>
		
		
		<Modal v-model="addModalShown" containerClass="md:w-3/5">
			<AddVolunteer v-bind="{ date: activeDate }" @add="volunteersAdded" />
		</Modal>
	</div>
</template>
<script>
import Modal from '@/components/Modal.vue'
import AddVolunteer from './AddVolunteer.vue'

export default {
	props: [ 'activeDate', 'assigned', 'suggestions', 'limit', 'offset' ],
	components: {
		Modal,
		AddVolunteer,
	},
	data() {
		return {
			fetching: false,
			submitting: false,
			
			addModalShown: false,
		};
	},
	methods: {
		
		// Add single volunteer (from suggestion list "add" button)
		async addVolunteer(userID) {
			if (!this.activeDate) return;
			this.submitting = true;
			try {
				const res = await this.$api.request('POST', `volunteers`, {
					date: this.activeDate,
					userIDs: [ userID ],			// Only one
				});
				
				// Remove from suggestion list
				const si = this.suggestions.findIndex(s => s.userID == userID);
				if (si >= 0) this.suggestions.splice(si, 1);
				
				// Add to assigned list
				this.assigned.push(...res);
				
				this.$toast.success('Volunteer added');
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Failed adding volunteer');
			}
			this.submitting = false;
		},
		
		// Add all current suggestions to list if not exists yet
		async addAllSuggestions() {
			if (!this.activeDate) return;
			this.submitting = true;
			try {
				const userIDs = this.suggestions.map(s => s.userID);
				
				const res = await this.$api.request('POST', `volunteers`, {
					date: this.activeDate,
					userIDs,
				});
				
				
				// Add to assigned list
				this.suggestOffset = 0;		// Reset counter
				this.suggestions.splice(0);
				this.assigned.push(...res);
				
				this.$toast.success('Volunteer added');
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Failed adding volunteer');
			}
			this.submitting = false;
		},
		
		async loadSuggestions() {
			if (!this.activeDate) return;
			this.fetching = true;
			try {
				const queries = [
					`offset=${this.offset}`,
					`limit=${this.limit}`,
				];
				
				// Fetch suggestions
				const suggest = await this.$api.request('GET', `volunteers/suggest/${this.activeDate}?${queries.join('&')}`);
				this.suggestions.push(...suggest);
				
				// Increment offset
				this.$emit('suggested', suggest.length);
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Failed to load records');
			}
			this.fetching = false;
		},
		
		
		// Specific volunteers added
		volunteersAdded(volunteers) {
			this.assigned.push(...volunteers);
			this.addModalShown = false;
		}
	}
}
</script>