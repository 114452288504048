import GiftClaimForm from './GiftClaimForm.vue'
import GiftClaimView from './GiftClaimView.vue'


export default [{
	path: '/admin/gift',
	component: GiftClaimForm,
	meta: {
		scopes: [ 'admin:shops', 'cashier' ]
	}
}, {
	path: '/admin/gift/view/:giftID',
	component: GiftClaimView,
	props: true,
	meta: {
		scopes: [ 'admin:shops', 'cashier', 'finance' ]
	}
}]