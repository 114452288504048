import UserList from './UserList.vue'
import UserPage from './UserPage.vue'
import UserGroup from './UserGroups.vue'
import CreateExternalUser from './CreateExternalUser.vue'

export default [{
	path: '/admin/users',
	component: UserList,
	meta: {
		scopes: [ 'admin:users' ]
	}
}, {
	path: '/admin/users/external/new',
	component: CreateExternalUser,
	meta: {
		scopes: [ 'admin:users' ],
	}
}, {
	path: '/admin/user/:userID',
	component: UserPage,
	props: true,
	meta: {
		scopes: [ 'admin:users' ]
	}
}, {
	path: '/admin/usergroups',
	component: UserGroup,
	meta: {
		scopes: [ 'admin:users' ],
	}
}]