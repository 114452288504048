import OrderDashboard from './OrderDashboard/OrderDashboard.vue'
import OrderDisplay from './OrderDisplay/OrderDisplay.vue'
import CashierPage from './CashierPage/CashierPage.vue'

export default [{
	path: '/admin/orders',
	component: OrderDashboard,
	meta: {
		scopes: [ 'admin:shops' ]
	}
}, {
	path: '/admin/orders/display',
	component: OrderDisplay,
	meta: {
		scopes: [ 'admin:shops' ]
	}
}, {
	path: '/admin/cashier',
	component: CashierPage,
	meta: {
		scopes: [ 'admin:shops', 'cashier' ],
	}
}];