<template>
	<Container>
		
		<div class="text-3xl font-medium">My Order History</div>
		<hr class="hr">
		
		<preloader v-if="loading" />
		<div v-else>
			<table class="table w-full">
				<thead>
					<tr>
						<th>Order #</th>
						<th width="100px">Status</th>
						<th width="200px" class="text-right">Amount (RM)</th>
						<th width="200px" class="text-right">Voucher (RM)</th>
						<th width="15%">Timestamp</th>
					</tr>
				</thead>
				<tbody>
					<tr v-if="!orders.length">
						<td colspan="5">No records available</td>
					</tr>
					
					<tr v-for="o in orders" :key="o.orderID">
						<td>
							<router-link :to="`/order/${o.orderID}`" class="text-accent-500 hover:text-accent-300 font-medium">{{ o.label }}</router-link>
						</td>
						<td class="uppercase font-medium" :class="OrderStatusClass[o.status]">{{ o.status }}</td>
						<td class="text-right">{{ o.paymentAmount }}</td>
						<td class="text-right">{{ o.voucherAmount }}</td>
						<td>{{ o.created }}</td>
					</tr>
				</tbody>
			</table>
			
			
			<Pagination v-bind="{ totalRows, curPage, pageLimit }" @goto="loadPage" />
			
		</div>
		
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import Pagination from '@/mixins/Pagination'
import { mapState } from 'vuex'
export default {
	components: {
		Container,
	},
	computed: {
		...mapState('Constants', [ 'OrderStatusClass' ]),
	},
	mixins: [ Pagination ],
	data() {
		return {
			loading: false,
			orders: [],
		};
	},
	methods: {
		async loadPage(page) {
			this.loading = true;
			try {
				const queries = [
					`page=${page}`,
					`limit=${this.pageLimit}`,
				];
				
				const res = await this.$api.request('GET', `orders?${queries.join('&')}`);
				
				this.curPage = page;
				this.totalRows = res.total;
				this.orders = res.results;
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Failed to load page');
			}
			this.loading = false;
		}
	},
	mounted() {
		this.loadPage(0);
	}
}
</script>