<template>
	<Container>
		<div class="text-3xl font-semibold">Invoice Report</div>
		<hr class="hr">
		
		
		<div class="md:flex md:items-center md:space-x-2 mb-4">
			<!-- Start date -->
			<Datepicker
				v-model="date"
				v-bind="{
					inputClass: 'input w-full',
					placeholder: 'Date',
				}" />
		</div>
		
		<TabBar v-model="activeTab" v-bind="{ tabs }" />
		<transition name="fade" mode="out-in">
			<component :is="tabs[activeTab].component" v-bind="{ date }" />
		</transition>
		
		
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import Datepicker from '@/components/Datepicker'
import TabBar from '@/components/TabBar.vue'
import GeneratedLines from './GeneratedLines.vue'
import InvoiceLogs from './InvoiceLogs.vue'
//import IntercoLines from './IntercoLines.vue'

export default {
	components: {
		Container,
		Datepicker,
		TabBar,
		GeneratedLines,
		//IntercoLines,
		InvoiceLogs,
	},
	data() {
		return {
			loading: false,
			
			date: new Date(),
			
			activeTab: 'daily',
			tabs: {
				daily: {
					label: 'Daily Records',
					component: 'GeneratedLines',
				},
				//interco: {
				//	label: 'Interco Records',
				//	component: 'IntercoLines',
				//},
				logs: {
					label: 'Invoice Logs',
					component: 'InvoiceLogs',
				}
			},
			
		};
	},
	methods: {
		
		
	},
	mounted() {
		
	}
}
</script>