<style lang="scss" scoped>

// Sidebar transitions
.sidebar-enter-active, .sidebar-leave-active {
	transition: all 0.2s ease;
	.sidebar-container {
		transition: all 0.2s ease;
	}
}

.sidebar-enter-from, .sidebar-enter-active, .sidebar-leave-to {
	opacity: 0;
	.sidebar-container { transform: translateX(-100%); }	
}

.sidebar-enter-to, .sidebar-leave-from {
	opacity: 1;
	.sidebar-container { transform: translateX(0%); }
}

.sidebar-container {
	max-width: 300px;
}

</style>
<template>
	<transition name="sidebar">
		<!-- Cover -->
		<div v-if="shown" @click="hide" class="sidebar-cover bg-black fixed z-20 inset-0 w-full h-full bg-opacity-75">
			
			<!-- Sidebar wrapper -->
			<div @click.stop class="sidebar-container left-0 h-full inset-y-0 bg-white w-4/5">
				<slot></slot>
			</div>
			
		</div>
	</transition>
</template>
<script>
import { emitter } from '@/utils'
export default {
	data() {
		return {
			shown: false,
		};
	},
	methods: {
		show() { this.shown = true; },
		hide() { this.shown = false; },
		toggle() { this.shown = !this.shown;},
	},
	mounted() {
		emitter.on('sidebar/show', this.show);
		emitter.on('sidebar/hide', this.hide);
		emitter.on('sidebar/toggle', this.toggle);
	}
}
</script>