<template>
	<Container>
		
		<div class="text-3xl font-semibold">Order Report</div>
		<hr class="hr">
		
		<div class="flex items-center justify-end space-x-2">
			<select v-model="status" class="input">
				<option value="">All</option>
				<option v-for="(s, key) in OrderStatus" :key="key" :value="key">{{ s }}</option>
			</select>
			
			<!-- Start date -->
			<Datepicker
				v-model="startDate"
				v-bind="{
					inputClass: 'input w-full',
					placeholder: 'Start date',
				}" />
			
			<!-- End date -->
			<Datepicker
				v-model="endDate"
				v-bind="{
					inputClass: 'input w-full',
					placeholder: 'End date',
				}" />
			
			<button class="button is-primary" :class="{ loading }" @click="loadPage(0)">Update</button>
		</div>
		<hr class="hr">
		
		
		<preloader v-if="loading" />
		<div v-else>
			
			<table class="table w-full">
				<thead>
					<tr>
						<th>Order #</th>
						<th width="200px">Type</th>
						<th width="200px">Status</th>
						<th width="300px">Name</th>
						<th width="200px" class="text-right">Payment (RM)</th>
						<th width="200px" class="text-right">Voucher (RM)</th>
						<th width="200px" class="text-right">Order Timestamp</th>
					</tr>
				</thead>
				<tbody>
					<tr v-if="!reportRows.length">
						<td colspan="8">No records available</td>
					</tr>
					
					
					<tr v-for="r in reportRows" :key="r.orderID">
						<td>
							<router-link class="font-medium text-accent-500 hover:text-accent-300" :to="`/admin/reports/order/${r.orderID}/invoice`">{{ r.label }}</router-link>
						</td>
						<td>{{ OrderTypeMap[r.type] }}</td>
						<td class="font-medium uppercase" :class="OrderStatusClass[r.status]">{{ r.status }}</td>
						<td>{{ r.user ? r.user.userName : '-' }}</td>
						<td class="text-right">{{ r.paymentAmount }}</td>
						<td class="text-right">{{ r.voucherAmount }}</td>
						<td class="text-right">{{ r.created }}</td>
					</tr>
					
				</tbody>
			</table>
			
			
			<Pagination class="mt-4" v-bind="{ curPage, totalRows, pageLimit }" @goto="loadPage" />
			
			
		</div>
		
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import Datepicker from '@/components/Datepicker'
import Pagination from '@/mixins/Pagination'
import dayjs from 'dayjs'
import { mapState } from 'vuex'


export default {
	components: {
		Container,
		Datepicker,
	},
	mixins: [ Pagination ],
	computed: {
		...mapState('Constants', [ 'OrderTypeMap', 'OrderStatus', 'OrderStatusClass' ]),
	},
	data() {
		return {
			loading: false,
			
			startDate: null,
			endDate: null,
			status: '',
			
			
			reportRows: [],
		};
	},
	methods: {
		async loadPage(page) {
			if (!this.startDate || !this.endDate) {
				this.$toast.error('Please select start/end date');
				return;
			}
			
			
			this.loading = true;
			try {
				const startDate = dayjs(this.startDate).format('YYYY-MM-DD');
				const endDate = dayjs(this.endDate).format('YYYY-MM-DD');
				const queries = [
					`page=${page}`,
					`limit=${this.pageLimit}`,
					`start=${startDate}`,
					`end=${endDate}`,
				];
				
				if (this.status) queries.push(`status=${this.status}`);
				
				const res = await this.$api.request('GET', `orders/reports?${queries.join('&')}`);
				this.page = page;
				this.totalRows = res.total;
				this.reportRows = res.results;
				
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error loading report');
			}
			this.loading = false;
		}
	},
	mounted() {
		this.startDate = dayjs().startOf('month').toDate();
		this.endDate = dayjs().endOf('month').toDate();
		
		this.loadPage(0);
	}
}
</script>