<template>
	<div class="h-full px-2 py-4 flex items-stretch space-x-2">
		<div class="w-1/4">
			<!-- Item search -->
			<ItemList @select="addItem" />
		</div>
		<div class="flex-grow">
			<table class="table w-full">
				<thead>
					<tr>
						<th>Product</th>
						<th width="80px">Qty</th>
						<th width="160px">Unit Price (RM)</th>
						<th width="160px">Total Price (RM)</th>
						<th width="40px"></th>
					</tr>
				</thead>
				<tbody>
					
					<tr v-if="!formData.items.length">
						<td colspan="5">No items added</td>
					</tr>
					
					<!-- Items -->
					<tr v-for="(it, ii) in formData.items" :key="ii">
						<td>{{ it.productName }} : {{ it.variantName }}</td>
						<td>
							<input type="text" class="input w-full text-sm px-2 py-1" v-model="it.qty">
						</td>
						<td>{{ it.price }}</td>
						<td>{{ (it.qty * it.price).toFixed(2) }}</td>
						<td>
							<button class="button is-danger px-2 py-1" @click="removeItem(ii)">
								<icon :data="mdiClose" class="w-5 h-5" />
							</button>
						</td>
					</tr>
					
					
				</tbody>
			</table>
		</div>
		
		
	</div>
</template>
<script>
import ItemList from './ItemList.vue'
import { mdiClose } from '@mdi/js'
export default {
	props: [ 'formData' ],
	components: {
		ItemList,
	},
	data() {
		return {
			mdiClose,
		};
	},
	methods: {
		addItem(it) {
			if (it.isInventory && it.stockQty == '0.00') {
				this.$toast.error('Item out of stock');
				return;
			}
			
			const existing = this.formData.items.find(itt => itt.productID == it.productID && itt.variantID == it.variantID);
			if (existing) {
				this.$toast.error('Item already added');
				return;
			}
			
			this.formData.items.push({
				productID: it.productID,
				variantID: it.variantID,
				productName: it.product.productName,
				variantName: it.variantName,
				price: it.price,
				qty: 1,
			});
		},
		removeItem(index) {
			this.formData.items.splice(index, 1);
		}
	}
}
</script>