<template>
	<div>
		<div v-if="order">
			<div class="text-3xl font-semibold">#{{ order.label }}</div>
			<div class="text-sm text-gray-400">
				<div>Created: {{ order.created }}</div>
				<div>Paid: {{ order.paid || '-' }}</div>
			</div>
			
			<hr class="hr">
			
			<table class="table w-full">
				<thead>
					<tr>
						<th>Item</th>
						<th class="text-right" width="200px">Qty</th>
						<th class="text-right" width="200px">Unit Price</th>
						<th class="text-right" width="200px">Total Price</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(it, ii) in order.items" :key="ii">
						<td>{{ it.productName }}: {{ it.variantName }}</td>
						<td class="text-right">{{ it.qty }}</td>
						<td class="text-right">{{ it.unitPrice }}</td>
						<td class="text-right">{{ it.totalPrice }}</td>
					</tr>
				</tbody>
			</table>
			
			<div class="mt-2">
				<div class="flex items-center justify-end text-xl font-semibold">
					<div class="w-1/4">Payment Amount</div>
					<div>RM {{ order.paymentAmount }}</div>
				</div>
				
				<div class="flex items-center justify-end text-xl font-semibold">
					<div class="w-1/4">Voucher Amount</div>
					<div>RM {{ order.voucherAmount }}</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: [ 'order' ],
}
</script>