<template>
	<Container>
		
		<div class="text-3xl font-semibold">V-Engagement Journey</div>
		<hr class="hr">
		
		<preloader v-if="loading" />
		<div v-else class="px-2">
			
			<!--<div class="text-2xl font-semibold mb-4">Current Quarter: {{ season }}</div>-->
			<div class="mb-4">
				<label class="font-medium mb-1">Select Quarter</label>
				<select class="input md:w-1/4 w-full" v-model="season" @change="fetchRecords">
					<option v-for="s in seasons" :key="s">{{ s }}</option>
				</select>
			</div>
			
			<!-- My record -->
			<div v-if="selfRecord" class="text-gray-400">
				<div v-if="selfRecord.completed">Your 1-1 session for <span class="font-medium text-black">{{ season }}</span> has been completed at {{ selfRecord.completed }}.</div>
				<div v-else>Your 1-1 session for <span class="font-medium text-black">{{ season }}</span> is yet to be completed.</div>
			</div>
			
			
			<!-- Subordinate records -->
			<div v-if="subordinateRecords.length > 0">
				<hr class="hr">
				
				<div class="flex items-baseline justify-between space-x-4">
					<div class="text-2xl font-semibold mb-2">My Subordinates</div>
					<div class="text-2xl font-medium mb-2 text-info-500">{{ percentageComplete }}% Completed</div>
				</div>
				
				<!-- Instruction for idiots -->
				<ol class="px-6 list-decimal mb-6">
					<li class="px-2">
						Please conduct 1-on-1 session with your subordinates quarterly and click on the "Acknowledge" button<br>
						<span class="text-gray-400">(Deadline: Last day of each Quarter, 31 Mar / 30 June / 30 Sept / 31 Dec)</span>
					</li>
					<li class="px-2">
						V-Cafe Voucher of RM5 will be credited to you and your subordinate's account after acknowledgement.
					</li>
				</ol>
				
				
				<table class="table w-full">
					<thead>
						<tr>
							<th width="120px">EmployeeID</th>
							<th>Name</th>
							<th width="200px" class="text-center">Status</th>
							<th width="200px">Timestamp</th>
							<th width="100px"></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="r in subordinateRecords" :key="r.userID">
							<td>{{ r.userID }}</td>
							<td>{{ r.userName }}</td>
							<td class="text-center font-medium uppercase"
								:class="statusClass[r.completed ? 'completed' : 'pending']">
								{{ r.completed ? 'completed' : 'pending' }}
							</td>
							<td>{{ r.completed || '-' }}</td>
							<td>
								<button v-if="isActiveSeason && !r.completed"
									@click="acknowledge(r)"
									class="button is-primary px-2 py-1 text-sm"
									:class="{ loading: submitting }">Acknowledge</button>
							</td>
						</tr>
					</tbody>
				</table>
				
			</div>
			
			
		</div>
		
		
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import dayjs from 'dayjs'

export default {
	components: {
		Container,
	},
	computed: {
		percentageComplete() {
			if (!this.subordinateRecords.length) return 0;
			
			// Calculate percentage complete
			let numComplete = 0;
			for (const i in this.subordinateRecords) {
				if (this.subordinateRecords[i].completed) ++numComplete;
			}
			
			return Math.round(numComplete / this.subordinateRecords.length * 100);
		},
		isActiveSeason() {
			const today = dayjs();
			const curYear = today.year();
			const curQ = Math.floor(today.month() / 3) + 1;
			const curSeason = `Q${curQ} ${curYear}`;
			return this.season == curSeason;
		}
	},
	data() {
		return {
			loading: false,
			submitting: false,
			
			statusClass: {
				pending: 'text-warning-500',
				completed: 'text-success-500',
			},
			
			
			season: '',
			seasons: [],
			selfRecord: null,
			subordinateRecords: [],
		};
	},
	methods: {
		async acknowledge(s) {
			const confirm = await this.$confirm({
				title: 'Acknowledgement',
				message: `Confirm that 1-1 session is done with ${s.userName}?`,
				buttons: 'YES_NO',
			});
			if (!confirm) return;
			
			this.submitting = true;
			try {
				await this.$api.request('PUT', `engagement/1v1`, {
					season: this.season,
					subordinateID: s.userID,
				});
				
				// Update completion time
				s.completed = dayjs().format('YYYY-MM-DD HH:mm:ss');
				this.$toast.success('Acknowledgement successful');
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error submitting acknowledgement');
			}
			this.submitting = false;
		},
		async fetchRecords() {
			this.loading = true;
			try {
				const res = await this.$api.request('GET', `engagement/1v1/status?season=${this.season}`);
				this.season = res.season;
				this.selfRecord = res.selfRecord;
				this.subordinateRecords = res.subordinateRecords;
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error loading records');
			}
			this.loading = false;
		}
	},
	async mounted() {
		// Generate seasons
		const today = dayjs();
		const curYear = today.year();
		const curQ = Math.floor(today.month() / 3) + 1;
		
		const seasons = [];
		let startYear = 2023;
		while (startYear <= curYear) {
			for (let i = 0; i < 4; ++i) {
				seasons.push(`Q${i + 1} ${startYear}`);
			}
			++startYear;
		}
		this.seasons = seasons;
		this.season = `Q${curQ} ${curYear}`;
		
		this.fetchRecords();
	}
}
</script>