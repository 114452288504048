<template>
	<Container>
		<div>
			<div class="text-3xl font-semibold">Volunteer Management</div>
			<hr class="hr">
		</div>
		
		<preloader v-if="loading" />
		<div v-else class="flex items-start gap-2">
			
			<div class="md:w-1/6">
				<div class="mb-2">
					<Datepicker v-model="curMonth"
						v-bind="{
							inputClass: 'input w-full',
							minLevel: 'month',
							placeholder: 'Select month',
							format: 'MMMM YYYY',
						}" />
				</div>
				<div>
					<div v-for="d in dates"
						:key="d.date"
						@click="selectDate(d)"
						class="flex gap-2 px-2 py-0.5 cursor-pointer transition-colors select-none"
						:class="(d.date == activeDate) ? 'bg-accent-500 text-white hover:bg-accent-500' : 'hover:bg-gray-200'">
						<span class="inline-block w-16 font-medium">{{ weekdays[d.weekday] }}</span>
						<span class="inline-block text-right">{{ d.label }}</span>
					</div>
				</div>
				
			</div>
			
			<div class="flex-grow">
				<preloader v-if="fetching" />
				<div v-else-if="activeDate">
					<div class="flex items-end justify-evenly">
						<!-- Date -->
						<div class="text-2xl font-medium">{{ activeDateFmt }}</div>
						
						<!-- Counts -->
						<div v-for="(c, key) in counts" class="text-center">
							<div class="font-medium text-sm text-gray-400 uppercase">{{ key }}</div>
							<div class="font-semibold text-3xl">{{ c }}</div>
						</div>
						
						
					</div>
					<hr class="hr my-2">
					
					<div class="text-right space-x-2 mb-4">
						<button class="button px-3 py-1 is-primary" @click="copyEmail">Copy Email Addresses</button>
						<button class="button px-3 py-1 is-success" :class="{ loading: submitting }" @click="markAll('done')">Complete All</button>
						<button class="button px-3 py-1 is-danger" :class="{ loading: submitting }" @click="removeAll">Remove All</button>
					</div>
					
					<table class="table w-full">
						<thead>
							<tr>
								<th width="100px">ID</th>
								<th>Name</th>
								<th width="140px">Company</th>
								<th width="140px">BU</th>
								<th width="140px">Dept</th>
								<th width="140px" class="text-center">Status</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr v-if="!assigned.length">
								<td colspan="6">No volunteers assigned</td>
							</tr>
							<tr v-for="(v, vi) in assigned" :key="vi">
								<td>{{ v.user.userID }}</td>
								<td>{{ v.user.userName }}</td>
								<td>{{ v.user.company }}</td>
								<td>{{ v.user.bu }}</td>
								<td>{{ v.user.dept }}</td>
								<td class="font-medium text-center uppercase">{{ v.status }}</td>
								<td>
									<div class="text-right space-x-2">
										<button class="button small is-success" :class="{ loading: submitting }" @click="markAttendance(v.userID, 'done')">
											<icon :data="mdiCheck" />
										</button>
										<button class="button small is-danger" :class="{ loading: submitting }" @click="markAttendance(v.userID, 'absent')">
											<icon :data="mdiClose" />
										</button>
										<button class="button small" :class="{ loading: submitting }" @click="removeVolunteer(v.userID)">
											<icon :data="mdiDelete" />
										</button>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
					
					
				</div>
			</div>
			
			<div class="w-1/4 overflow-y-auto">
				<SuggestionsPanel v-bind="{
					activeDate,
					assigned,
					suggestions,
					limit: suggestLimit,
					offset: suggestOffset,
				}"
				@suggested="suggestionsLoaded"/>
			</div>
		</div>
		
		
		
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import Datepicker from '@/components/Datepicker'
import SuggestionsPanel from './SuggestionsPanel.vue'
import dayjs from 'dayjs'
import { mdiCheck, mdiClose, mdiDelete } from '@mdi/js'

export default {
	components: {
		Container,
		Datepicker,
		SuggestionsPanel,
	},
	computed: {
		activeDateFmt() {
			if (!this.activeDate) return '';
			return dayjs(this.activeDate).format('D MMM YYYY');
		},
		counts() {
			const counts = {
				pending: 0,
				absent: 0,
				done: 0,
				total: 0,
			};
			
			for (const i in this.assigned) {
				const a = this.assigned[i];
				counts[a.status]++;
				counts.total++;
			}
			
			return counts;
			
		}
	},
	data() {
		return {
			mdiCheck,
			mdiClose,
			mdiDelete,
			
			loading: false,
			fetching: false,
			submitting: false,
			
			curMonth: null,
			
			activeDate: '',
			
			weekdays: [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ],
			
			suggestOffset: 0,
			suggestLimit: 10,
			
			dates: [],
			assigned: [],
			suggestions: [],
		};
	},
	watch: {
		curMonth: 'getDatesForMonth'
	},
	methods: {
		async getDatesForMonth() {
			if (!this.curMonth) return;
			
			const today = dayjs().format('YYYY-MM-DD');
			let todayIndex = -1;
			
			const dates = [];
			this.loading = true;
			try {
				let date = dayjs(this.curMonth).startOf('month');
				const end = dayjs(this.curMonth).endOf('month');
				while (date.isBefore(end, 'day') || date.isSame(end, 'day')) {
					
					// Skip weekends
					if (![0, 6].includes(date.day())) {
						const d = {
							label: date.format('D MMM YYYY'),
							weekday: date.day(),
							date: date.format('YYYY-MM-DD'),
						};
						
						// Record index if date is today, so we can select it later
						if (d.date == today) todayIndex = dates.length;
						
						// Add to list
						dates.push(d);
					}
					date = date.add(1, 'day');
				}
				this.dates = dates;
				
				
				if (todayIndex >= 0) await this.selectDate(this.dates[todayIndex]);
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error loading dates');
			}
			this.loading = false;
		},
		
		async selectDate(d) {
			this.activeDate = d.date;
			
			this.fetching = true;
			try {
				// Load registered volunteers
				const assigned = await this.$api.request('GET', `volunteers/date/${this.activeDate}`);
				
				// Fetch suggestions
				this.suggestOffset = 0;
				const queries = [
					`offset=${this.suggestOffset}`,
					`limit=${this.suggestLimit}`,
				];
				const suggest = await this.$api.request('GET', `volunteers/suggest/${this.activeDate}?${queries.join('&')}`);
				
				// Increment offset
				this.suggestOffset = suggest.length;
				this.assigned = assigned;
				this.suggestions = suggest;
				
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Failed to load records');
			}
			this.fetching = false;
		},
		suggestionsLoaded(count) {
			this.suggestOffset += count;
		},
		
		async markAll(action) {
			const confirm = await this.$confirm({
				title: 'Complete All Attendance',
				message: 'Confirm to mark all volunteer records as DONE?\nOnly pending records will be changed.',
				buttons: 'YES_NO',
			});
			if (!confirm) return;
			
			if (!this.activeDate) return;
			this.submitting = true;
			try {
				
				await this.$api.request('PUT', `volunteers/${this.activeDate}?action=${action}`);
				for (const i in this.assigned) {
					const a = this.assigned[i];
					if (a.status == 'pending') a.status = action;
				}
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Failed to mark attendance');
			}
			this.submitting = false;
		},
		
		// Mark single attendance
		async markAttendance(userID, action) {
			if (!this.activeDate) return;
			this.submitting = true;
			try {
				
				await this.$api.request('PUT', `volunteers/${this.activeDate}/${userID}?action=${action}`);
				const r = this.assigned.find(a => a.userID == userID);
				if (r) r.status = action;
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Failed to mark attendance');
			}
			this.submitting = false;
		},
		
		
		// Remove single volunteer record
		async removeVolunteer(userID) {
			if (!this.activeDate) return;
			this.submitting = true;
			try {
				await this.$api.request('DELETE', `volunteers/${this.activeDate}/${userID}`);
				
				// Remove from suggestion list
				const index = this.assigned.findIndex(a => a.userID == userID);
				if (index >= 0) this.assigned.splice(index, 1);
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Failed to remove volunteer');
			}
			this.submitting = false;
		},
		async removeAll() {
			const confirm = await this.$confirm({
				title: 'Remove All Volunteers',
				message: 'Confirm to remove all volunteer records for this date?',
				buttons: 'YES_NO',
			});
			if (!confirm) return;
			
			
			if (!this.activeDate) return;
			this.submitting = true;
			try {
				await this.$api.request('DELETE', `volunteers/${this.activeDate}`);
				
				this.assigned = [];
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Failed to remove volunteer');
			}
			this.submitting = false;
		},
		
		copyEmail() {
			const emails = this.assigned.map(a => a.user.email);
			navigator.clipboard.writeText(emails.join(','));
			this.$toast.info('Email addresses copied to clipboard');
		}
	},
	mounted() {
		this.curMonth = new Date();
	}
}
</script>