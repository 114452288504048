<template>
	<Container>
		<div class="text-3xl font-semibold">Product Sales Report</div>
		<hr class="hr">
		
		<div>
			<div class="flex gap-2">
				<Datepicker v-model="startDate" />
				<Datepicker v-model="endDate" />
				
				<input type="text" class="input flex-grow" v-model.trim="query" placeholder="Search partID, product, variant name">
				
				<button class="button is-primary" @click="loadPage">
					Update
				</button>
				
				<button class="button is-accent" @click="downloadCSV">Download CSV</button>
				<a class="hidden" ref="downloadLink"></a>
			</div>
		</div>
		<hr class="hr">
		
		<preloader v-if="loading" />
		<div v-else>
			
			<table class="table w-full">
				<thead>
					<tr>
						<th>Order#</th>
						<th>PartID</th>
						<th>Product</th>
						<th>Variant</th>
						<th class="text-right">Qty</th>
						<th class="text-right">Unit Price</th>
						<th class="text-right">Total Price</th>
						<th>Timestamp</th>
					</tr>
				</thead>
				<tbody>
					<tr v-if="!orderItems.length">
						<td colspan="6">No records available</td>
					</tr>
					
					<tr v-for="(o, oi) in orderItems" :key="oi">
						<td>{{ o.label }}</td>
						<td>{{ o.epicorPartID }}</td>
						<td>{{ o.productName }}</td>
						<td>{{ o.variantName }}</td>
						<td class="text-right">{{ o.qty }}</td>
						<td class="text-right">{{ o.unitPrice }}</td>
						<td class="text-right">{{ o.totalPrice }}</td>
						<td>{{ o.timestamp }}</td>
					</tr>
					
				</tbody>
			</table>
			
			
		</div>
		
		
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import Datepicker from '@/components/Datepicker'
import dayjs from 'dayjs'
import exportCSV from '@/utils/exportCSV'

export default {
	components: {
		Container,
		Datepicker,
	},
	data() {
		return {
			loading: false,
			
			startDate: null,
			endDate: null,
			query: '',
			
			orderItems: [],
		};
	},
	methods: {
		async loadPage() {
			this.loading = true;
			try {
				
				const queries = [
					`start=${dayjs(this.startDate).format('YYYY-MM-DD')}`,
					`end=${dayjs(this.endDate).format('YYYY-MM-DD')}`,
				];
				if (this.query) queries.push(`q=${encodeURIComponent(this.query)}`);
				
				const orders = await this.$api.request('GET', `orders/reports/products?${queries.join('&')}`);
				
				// Consolidate item lines
				const orderItems = [];
				for (const i in orders) {
					const o = orders[i];
					for (const j in o.items) {
						const item = o.items[j];
						
						const line = {
							label: o.label,
							productName: item.productName,
							variantName: item.variantName,
							epicorPartID: item.variant ? item.variant.epicorPartID : '',
							qty: item.qty,
							unitPrice: item.unitPrice,
							totalPrice: item.totalPrice,
							timestamp: o.created,
						};
						orderItems.push(line);
					}
				}
				
				orderItems.sort((a, b) => {
					if (a.productName == b.productName) {
						if (a.variantName == b.variantName) {
							return a.timestamp.localeCompare(b.timestamp);
						} else {
							return a.variantName.localeCompare(b.variantName);
						}
						
					}
					else return a.productName.localeCompare(b.productName);
				});
				this.orderItems = orderItems;
				
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error loading report');
			}
			this.loading = false;
		},
		
		
		downloadCSV() {
			const rows = [
				[ 'OrderNum','EpicorPartID', 'ProductName', 'VariantName', 'Qty', 'Unit Price', 'Total Price', 'Timestamp' ],
			];
			for (const i in this.orderItems) {
				const it = this.orderItems[i];
				rows.push([
					it.label,
					it.epicorPartID,
					it.productName,
					it.variantName,
					it.qty,
					it.unitPrice,
					it.totalPrice,
					it.timestamp,
				]);
			}
			
			exportCSV(rows, `ProductSales.csv`, this.$refs.downloadLink);
		}
		
	},
	mounted() {
		this.startDate = dayjs().toDate();
		this.endDate = dayjs().toDate();
		
		this.loadPage();
	}
}
</script>