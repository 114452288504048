<template>
	<Container>
		<div class="text-3xl font-semibold">Gift Claim Report</div>
		<hr class="hr">
		
		<div class="md:flex md:gap-2 mb-4">
			<div class="md:flex md:gap-2 md:flex-grow">
				<Datepicker v-model="startDate" v-bind="{
					placeholder: 'Start Date',
					inputClass: 'input'
				}" />
				
				<Datepicker v-model="endDate" v-bind="{
					placeholder: 'End Date',
					inputClass: 'input'
				}" />
			</div>
			<button class="button is-primary" :class="{ loading: fetching }" @click="generateCSV">Export CSV</button>
			<a class="hidden" ref="downloadLink"></a>
		</div>
		
		<TabBar v-model="activeTab" v-bind="{ tabs }" />
		
		<preloader v-if="loading" />
		<div v-else class="pt-2 pb-4">
			<component :key="activeTab" :is="tabs[activeTab].component" v-bind="{ records }" />
			
			<Pagination class="mt-4" v-bind="{ curPage, totalRows, pageLimit }" @goto="loadPage" />
		</div>
		
		
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import Pagination from '@/mixins/Pagination'
import TabBar from '@/components/TabBar.vue'
import Datepicker from '@/components/Datepicker'
import InternalRows from './InternalRows.vue'
import ExternalRows from './ExternalRows.vue'
import dayjs from 'dayjs'
import exportCSV from '@/utils/exportCSV'

export default {
	components: {
		Container,
		TabBar,
		Datepicker,
		InternalRows,
		ExternalRows,
	},
	mixins: [ Pagination ],
	watch: {
		activeTab() { this.loadPage(0); },
		startDate() { this.loadPage(0); },
		endDate() { this.loadPage(0); },
	},
	data() {
		return {
			loading: false,
			fetching: false,
			
			activeTab: 'internal',
			tabs: {
				internal: {
					label: 'Internal',
					component: 'InternalRows'
				},
				external: {
					label: 'External',
					component: 'ExternalRows'
				}
			},
			
			startDate: null,
			endDate: null,
			
			pageLimit: 100,
			records: [],
		};
	},
	methods: {
		async loadPage(page) {
			this.loading = true;
			try {
				const queries = [
					`page=${page}`,
					`limit=${this.pageLimit}`,
					`type=${this.activeTab}`,
				];
				if (this.startDate) queries.push(`start=${dayjs(this.startDate).format('YYYY-MM-DD')}`);
				if (this.endDate) queries.push(`end=${dayjs(this.endDate).format('YYYY-MM-DD')}`);
				
				
				const res = await this.$api.request('GET', `gifts/records?${queries.join('&')}`);
				this.curPage = page;
				this.totalRows = res.total;
				this.records = res.results;
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error loading page')
			}
			this.loading = false;
		},
		async generateCSV() {
			this.fetching = true;
			try {
				const startDate = dayjs(this.startDate).format('YYYY-MM-DD');
				const endDate = dayjs(this.endDate).format('YYYY-MM-DD');
				const queries = [
					`page=0`,
					`limit=1000000`,
					`type=${this.activeTab}`,
					`start=${startDate}`,
					`end=${endDate}`,
					`lines=1`,
				];
				
				if (this.q) queries.push(`q=${encodeURIComponent(this.q)}`);
				
				const res = await this.$api.request('GET', `gifts/records?${queries.join('&')}`);
				const csvRows = [];
				if (this.activeTab == 'internal') {
					csvRows.push([
						'Type', 'CollectByID', 'CollectedBy', 'ChargeToID', 'ChargeTo',
						'Company', 'BU', 'Dept',
						'Item', 'Qty', 'UnitPrice', 'TotalPrice',
						'Purpose', 'Remarks', 'Timestamp'
					]);
				} else if (this.activeTab == 'external') {
					csvRows.push([
						'Type', 'CollectByID', 'CollectedBy', 'ChargeToID', 'ChargeTo',
						'Company', 'BU', 'Dept',
						'CustomerType', 'CustomerCompany', 'CustomerName', 'VGR', 
						'Item', 'Qty', 'UnitPrice', 'TotalPrice',
						'Purpose', 'Remarks', 'Timestamp'
					]);
				}
				
				
				for (const i in res.results) {
					const r = res.results[i];
					
					const extLines = [];
					if (this.activeTab == 'external') extLines.push(r.customerType, r.customerCompany, r.customerName, r.vgr);
					
					const lines = r.lines;
					for (const j in lines) {
						const l = lines[j];
						
						const row = [
							this.activeTab.toUpperCase(),
							r.collectBy,
							r.user ? r.user.userName : '',
							r.onBehalf,
							r.behalf ? r.behalf.userName : '',
							r.company,
							r.bu,
							r.dept,
							
							// Additional info for external gift goes here
							...extLines,
							
							`${l.productName}: ${l.variantName}`,
							l.qty,
							l.unitPrice,
							l.totalPrice,
							r.purpose,
							r.remarks,
							r.created,
						];
						csvRows.push(row);
					}
				}
				
				const startDateFmt = dayjs(this.startDate).format('YYYYMMDD');
				const endDateFmt = dayjs(this.endDate).format('YYYYMMDD');
				exportCSV(csvRows, `GiftReport_${this.activeTab.toUpperCase()}_${startDateFmt}-${endDateFmt}.csv`, this.$refs.downloadLink);
				
			} catch (err) {
				console.error(err);
				this.$toast.error('Failed to generate CSV file');
			}
			this.fetching = false;
		}
	},
	mounted() {
		const today = dayjs();
		this.startDate = today.startOf('month').toDate();
		this.endDate = today.endOf('month').toDate();
		
		
		this.loadPage(0);
	}
}
</script>