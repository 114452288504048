<template>
	<div>
		<div v-if="order">
			<div class="text-3xl font-semibold">#{{ order.label }}</div>
			<div class="text-sm text-gray-400">
				<div>Created: {{ order.created }}</div>
				<div>Paid: {{ order.paid || '-' }}</div>
			</div>
			
			<hr class="hr">
			
			<table class="table w-full">
				<thead>
					<tr>
						<th>Item</th>
						<th class="text-right" width="200px">Qty</th>
						<th class="text-right" width="200px">Unit Price</th>
						<th class="text-right" width="200px">Tax</th>
						<th class="text-right" width="200px">Total Price</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(it, ii) in order.items" :key="ii">
						<td>{{ it.productName }}: {{ it.variantName }}</td>
						<td class="text-right">{{ it.qty }}</td>
						<td class="text-right">{{ it.unitPrice }}</td>
						<td class="text-right">{{ it.taxPrice }}</td>
						<td class="text-right">{{ totalPrice(it) }}</td>
					</tr>
				</tbody>
			</table>
			
			<div class="mt-2 flex justify-between items-end">
				<div>
					<button class="button is-primary" :class="{ loading: submitting }" @click="printReceipt">Print Receipt</button>
				</div>
				<div class="flex-grow">
					<div class="flex items-center justify-end font-semibold">
						<div class="w-1/4">Tax Amount</div>
						<div>{{ order.taxAmount }}</div>
					</div>
					<div class="flex items-center justify-end font-semibold">
						<div class="w-1/4">Rounding Adjustment</div>
						<div>{{ order.roundingAmount }}</div>
					</div>
					
					<div class="flex items-center justify-end font-semibold text-lg text-info-500">
						<div class="w-1/4">Payment Amount</div>
						<div>{{ order.paymentAmount }}</div>
					</div>
					
					<div class="flex items-center justify-end font-semibold text-lg text-info-500">
						<div class="w-1/4">Voucher Amount</div>
						<div>{{ order.voucherAmount }}</div>
					</div>
					
					<div class="flex items-center justify-end font-semibold text-lg text-info-500">
						<div class="w-1/4">Grand Total</div>
						<div>{{ grandTotal }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

export default {
	props: [ 'order' ],
	data() {
		return {
			submitting: false,
		};
	},
	computed: {
		grandTotal() {
			if (!this.order) return '0.00';
			const o = this.order;
			return (parseFloat(o.paymentAmount) + parseFloat(o.voucherAmount)).toFixed(2);
		}
	},
	methods: {
		totalPrice(it) {
			let price = it.qty * it.unitPrice;
			price += it.taxPrice * 1;
			return price.toFixed(2);
		},
		async printReceipt() {
			if (!this.order) return;
			
			this.submitting = true;
			try {
				let queueNum = null;
				if (this.order.queueNum != -1) queueNum = this.order.queueNum;
				
				const printData = {
					queueNum,
					orderNum: this.order.label,
					paymentAmt: this.order.paymentAmount,
					taxAmt: this.order.taxAmount,
					roundingAmt: this.order.roundingAmount,
					discount: this.order.voucherAmount,
					items: [],
				};
				printData.items = this.order.items.map(it => {
					const name = `${it.productName}: ${it.variantName}`.replace(/[^\u0000-\u007f]/g, '');
					return {
						name,
						qty: it.qty,
						price: it.totalPrice,
						tax: it.taxPrice,
					};
				});
				await this.$devices.request('POST', 'print', printData);
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error printing receipt');
			}
			this.submitting = false;
		}
	}
}
</script>