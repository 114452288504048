<template>
	<Container>
		<div class="text-3xl font-semibold">Very Frequently Asked Questions</div>
		<hr class="hr">
		
		<div class="space-y-4">
			<div v-for="(l, li) in list" :key="li">
				<div class="text-lg font-medium">{{ l.title }}</div>
				<div class="text-gray-600">{{ l.body }}</div>
			</div>
			
			
		</div>
		
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
export default {
	components: {
		Container,
	},
	data() {
		return {
			list: [{
				title: 'Where is my previous V-Wallet credit?',
				body: 'It is pending Finance Department to process and will be returned to you soon. Please refer to Tan Hooi Yee for further information.'
			}, {
				title: 'Will the system not auto cancel my V-Meal registration when I apply leave?',
				body: 'No, it will not. Now that the site can be accessed without VPN, please remember to cancel your lunch yourself.'
			}, {
				title: 'How can I make payment at V-Cafe?',
				body: 'By using credit/debit card or major e-Wallets in Malaysia.'
			}, {
				title: 'Can I pay by cash?',
				body: 'No.'
			}, {
				title: 'How can I pay my V-Meal penalty?',
				body: 'You can pay at the counter during lunch, using the payment method above.'
			}, {
				title: 'What if I forgot to register for V-Meal?',
				body: 'You can either pay RM 8.00 at the counter during lunch to enjoy our V-Meal for that day, or you can have your lunch at any of the restaurants nearby our office.'
			}]
		};
	}
}
</script>