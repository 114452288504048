<template>
	<div>
		<preloader v-if="loading" />
		<div v-else class="md:flex md:items-start md:space-x-2">
			
			<!-- User details -->
			<div class="md:w-1/3 space-y-2">
				<div>
					<label class="label">Employee No.</label>
					<input type="text" class="input w-full" readonly :value="profile.userID">
				</div>
				
				<div>
					<label class="label">Name</label>
					<input type="text" class="input w-full" readonly :value="profile.userName">
				</div>
				
				<div>
					<label class="label">Account Name</label>
					<input type="text" class="input w-full" readonly :value="profile.accountName">
				</div>
				
				<div>
					<label class="label">Email</label>
					<input type="text" class="input w-full" readonly :value="profile.email">
				</div>
				
				<div>
					<label class="label">Voucher Balance</label>
					<input type="text" class="input w-full" readonly :value="profile.voucher">
				</div>
			</div>
			
			
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			loading: false,
			
			profile: {
				userID: '',
				userName: '',
				accountName: '',
				voucher: '',
				email: '',
			},
		};
	},
	async mounted() {
		this.loading = true;
		try {
			
			const res = await this.$api.request('GET', `profile`);
			this.profile.userID = res.userID;
			this.profile.userName = res.userName;
			this.profile.accountName = res.accountName;
			this.profile.voucher = res.voucher;
			this.profile.email = res.email;
			
		} catch(err) {
			console.error(err);
			this.$toast.error('Error loading profile');
		}
		this.loading = false;
	}
}
</script>