<template>
	<Container>
		
		<div class="flex items-end justify-between">
			<div class="text-3xl font-semibold">Service Details</div>
			<router-link :to="`/service/request?copy=${serviceID}`" class="button is-primary">Copy</router-link>
		</div>
		<hr class="hr">
		
		<preloader v-if="loading" />
		<div v-else class="md:flex md:items-start md:space-x-2 space-x-0 space-y-2 md:space-y-0">
			<div class="md:w-1/2 md:flex-shrink-0 space-y-2">
				<div>
					<label class="label">Event Name</label>
					<input type="text" class="input w-full" readonly :value="formData.eventName" placeholder="Event name">
				</div>
				
				<div>
					<label class="label">Purpose</label>
					<input type="text" class="input w-full" readonly :value="formData.eventType" placeholder="Event type">
				</div>
				
				<div>
					<label class="label">Event Date</label>
					<input type="date" class="input w-full" readonly :value="formData.eventDate">
				</div>
				
				<div>
					<label class="label">Customer Type</label>
					<input type="text" class="input w-full" readonly :value="formData.customerType" placeholder="Customer type">
				</div>
				<div v-if="hasCustomerType2">
					<label class="label">Customer Type 2</label>
					<input type="text" class="input w-full" readonly :value="formData.customerType2" placeholder="Customer type">
				</div>
				<div>
					<label class="label">Customer Name</label>
					<input type="text" class="input w-full" readonly :value="formData.customerName" placeholder="Customer name">
				</div>
				<div>
					<label class="label">Customer Company</label>
					<input type="text" class="input w-full" readonly :value="formData.customerCompany" placeholder="Customer company">
				</div>
				<div>
					<label class="label">Remarks</label>
					<textarea class="input w-full resize-none" readonly :value="formData.remarks" placeholder="Additional remarks"></textarea>
				</div>
				
			</div>
			
			
			
			<div class="md:flex-grow space-y-2">
				<div>
					<label class="label">Requestor</label>
					<input type="text" class="input w-full" :value="formData.requestor" readonly>
				</div>
				<div>
					<label class="label">On Behalf</label>
					<input type="text" class="input w-full" :value="formData.onBehalf" readonly>
				</div>
				
				<!-- Company selection -->
				<div>
					<label class="label">Company</label>
					<input type="text" class="input w-full" :value="formData.company" readonly>
				</div>
				
				
				<!-- BU selection -->
				<div>
					<label class="label">BU</label>
					<input type="text" class="input w-full" :value="formData.bu" readonly>
				</div>
				
				<!-- Dept selection -->
				<div>
					<label class="label">Department</label>
					<input type="text" class="input w-full" :value="formData.dept" readonly>
				</div>
				
				<!-- Group selections -->
				<div v-for="(group, key) in groupNames" :key="key">
					<label class="label">{{ group }} ({{ groupTimes[key] }})</label>
					<div class="flex items-center gap-2">
						<input type="text" class="input flex-grow" readonly :value="productGroups[formData[key]] || 'Not required'">
						<input type="text" class="input w-1/3" readonly :value="formData[`${key}Count`]">
					</div>
				</div>
				
				<div v-if="formData.vmeal">
					<label class="label">V-Meal Cuisine</label>
					<input type="text" class="input w-full" readonly :value="formData.mealCuisine || 'Cincai'">
				</div>
				
				
			</div>
			
		</div>
		
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import { mapState } from 'vuex'

export default {
	props: [ 'serviceID' ],
	components: {
		Container,
	},
	computed: {
		...mapState('Constants', [ 'ServiceEventType', 'CustomerType' ]),
		hasCustomerType2() {
			if (!this.formData.customerType) return false;
			return [ 'Customer', 'Vendor', 'SCP/Distributor' ].includes(this.formData.customerType);
		}
	},
	data() {
		return {
			loading: false,
			groupNames: {
				vmeal: 'V-Meal',
				vrefreshment: 'V-Breakfast',
				vsavor: 'V-Savor',
			},
			groupTimes: {
				vmeal: '12:00 pm',
				vrefreshment: '9:00 am',
				vsavor: '3:30 pm',
			},
			
			productGroups: {},
			formData: {
				eventName: '',
				eventType: '',
				eventDate: null,
				customerType: '',
				customerType2: '',
				customerName: '',
				customerCompany: '',
				mealCuisine: '',
				remarks: '',
				
				onBehalf: '',
				company: '',
				bu: '',
				dept: '',
				
				vmeal: '',
				vrefreshment: '',
				vsavor: '',
				vmealCount: '',
				vrefreshmentCount: '',
				vsavorCount: '',
			},
		};
	},
	methods: {
	},
	async mounted() {
		this.loading = true;
		try {
			// Initialize product groups
			const productGroups = {};
			const products = await this.$api.request('GET', 'services/products');
			for (const i in products) {
				const p = products[i];
				productGroups[`${p.productID}:${p.variantID}`] = `${p.productName}: ${p.variantName}`;
			}
			this.productGroups = productGroups;
			
			
			// Fetch service
			const service = await this.$api.request('GET', `services/${this.serviceID}`);
			this.formData.eventName = service.eventName;
			this.formData.eventType = service.eventType;
			this.formData.eventDate = service.eventDate;
			this.formData.customerType = service.customerType;
			this.formData.customerType2 = service.customerType2;
			this.formData.customerName = service.customerName;
			this.formData.customerCompany = service.customerCompany;
			this.formData.mealCuisine = service.mealCuisine;
			this.formData.remarks = service.remarks;
			
			
			this.formData.requestor = service.requestor ? service.requestor.userName : '',
			this.formData.onBehalf = service.behalf ? service.behalf.userName : '-';
			this.formData.company = service.company;
			this.formData.bu = service.bu;
			this.formData.dept = service.dept;
			
			this.formData.vmeal = service.vmeal;
			this.formData.vrefreshment = service.vrefreshment;
			this.formData.vsavor = service.vsavor;
			this.formData.vmealCount = service.vmealCount;
			this.formData.vrefreshmentCount = service.vrefreshmentCount;
			this.formData.vsavorCount = service.vsavorCount;
			
			
			// Populate the conditional fields
			if (!this.ServiceEventType.includes(this.formData.eventType)) {
				this.formData.eventTypeRemarks = this.formData.eventType;
				this.formData.eventType = 'Others';
			}
			if (!this.CustomerType.includes(this.formData.customerType)) {
				this.formData.customerTypeRemarks = this.formData.customerType;
				this.formData.customerType = 'Others';
			}
			
		} catch(err) {
			console.error(err);
			this.$toast.error('Error loading page');
		}
		this.loading = false;
	}
}
</script>