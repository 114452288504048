<template>
	<Container>
		<div class="md:flex md:space-x-4 space-y-4 md:space-y-0">
			<div class="md:w-1/4">
				<Terms />
			</div>
			
			<div class="md:flex-grow h-full">
				<div class="md:flex md:items-center md:justify-between mb-4">
					<!-- Month picker -->
					<div class="md:flex-grow-0 flex-grow flex space-x-2">
						<button class="button is-primary" @click="modifyMonth(-1)"><icon :data="mdiArrowLeft" class="w-5 h-5" /></button>
						<Datepicker
							class="flex-grow"
							:modelValue="selectedDate"
							@update:modelValue="dateChanged"
							v-bind="{
								inputClass: 'input w-full',
								minLevel: 'month',
								format: 'MMMM YYYY'
							}" />
						
						<button class="button is-primary" @click="modifyMonth(1)"><icon :data="mdiArrowRight" class="w-5 h-5" /></button>
					</div>
					
					<!-- Save button -->
					<button v-if="hasChanges"
						class="button is-success text-xl font-medium w-full md:w-auto my-2 md:my-0"
						:class="{ loading: submitting }"
						@click="saveChanges">
						Save
					</button>
					
				</div>
				
				<preloader v-if="loading" />
				<div v-else class="overflow-y-hidden">
					<CalendarList
						@change="registrationChanged"
						v-bind="{
							selectedDate,
							registeredDates,
							holidays,
						}" />
					
					<div class="my-4">
						<button v-if="hasChanges"
							class="button is-success text-xl font-medium w-full md:w-auto"
							:class="{ loading: submitting }"
							@click="saveChanges">
							Save
						</button>
					</div>
				</div>
			</div>
			
		</div>
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import Datepicker from '@/components/Datepicker'
import CalendarList from './CalendarList.vue'
import Terms from './Terms.vue'
import dayjs from 'dayjs'
import { mdiArrowDown, mdiArrowLeft, mdiArrowRight } from '@mdi/js'


export default {
	components: {
		Container,
		Datepicker,
		CalendarList,
		Terms,
	},
	data() {
		return {
			mdiArrowLeft,
			mdiArrowRight,
			mdiArrowDown,
			
			loading: false,
			submitting: false,
			hasChanges: false,
			
			selectedDate: null,
			
			registeredDates: [],
			holidays: [],
			holidayDates: [],
		};
	},
	methods: {
		registrationChanged() { this.hasChanges = true; },
		async modifyMonth(step) {
			if (this.hasChanges) {
				const confirm = await this.$confirm({
					title: 'Unsaved Change',
					message: 'There are unsaved changes that may be lost.\nProceed?',
					buttons: 'YES_NO',
				});
				if (!confirm) return;
			}
			
			this.selectedDate = dayjs(this.selectedDate).add(step, 'month').toDate();
			this.loadMonth();
		},
		async dateChanged(value) {
			if (this.hasChanges) {
				const confirm = await this.$confirm({
					title: 'Unsaved Change',
					message: 'There are unsaved changes that may be lost.\nProceed?',
					buttons: 'YES_NO',
				});
				if (!confirm) return;
			}
			this.selectedDate = value;
			this.loadMonth();
		},
		async loadMonth() {
			this.loading = true;
			try {
				let sd = this.selectedDate;
				const date = dayjs(sd).format('YYYY/MM');
				
				// Fetch registered dates
				const registeredDates = await this.$api.request('GET', `meal/registrations/${date}`);
				this.registeredDates = registeredDates;
				
				// Fetch holidays
				const holidays = await this.$api.request('GET', `holidays/${date}`);
				this.holidays = holidays;
				this.holidayDates = holidays.map(h => h.date);
				
				// No changes on load
				this.hasChanges = false;
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error loading registrations');
			}
			this.loading = false;
		},
		
		
		async saveChanges() {
			this.submitting = true;
			try {
				const date = dayjs(this.selectedDate).format('YYYY/MM');
				await this.$api.request('PUT', `meal/registrations/${date}`, { dates: this.registeredDates });
				
				this.hasChanges = false;
				this.$toast.success('Changes saved');
				
			} catch(err) {
				console.error(err);
				this.$toast.error(`Error saving changes`);
			}
			this.submitting = false;
		},
	},
	
	async mounted() {
		this.selectedDate = new Date();
		this.loadMonth();
	}
}
</script>