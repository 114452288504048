<template>
	<Container>
		
		<div class="text-3xl font-semibold">Sales Report</div>
		<hr class="hr">
		
		<div class="md:flex md:items-center md:space-x-2 mb-4">
			<!-- Start date -->
			<Datepicker
				v-model="date"
				v-bind="{
					inputClass: 'input w-full',
					placeholder: 'Date',
				}" />
			
			<select v-model="type" class="input">
				<option value="all">All</option>
				<option value="card">Card</option>
				<option value="qr">QR</option>
			</select>
			
			<select v-model="status" class="input">
				<option value="all">All</option>
				<option value="paid">Paid</option>
				<option value="pending">Pending</option>
				<option value="cancelled">Cancelled</option>
			</select>
			
			
			
			<button class="button is-primary" :class="{ loading }" @click="fetchRecords">Update</button>
			<button class="button is-accent" :class="{ loading }" @click="generateCSV">Download CSV</button>
			<a ref="downloadLink" class="hidden"></a>
		</div>
		
		
		
		<preloader v-if="loading" />
		<div v-else>
			<div class="flex items-center justify-between mb-2">
				<div class="px-2 text-xl font-medium">Total rows: {{ totalRows }}</div>
				
				<button v-if="allowPosting" class="button is-accent" :class="{ loading: submitting }" @click="submitPosting">Confirm Amount</button>
				<button v-else class="button is-danger" :class="{ loading: submitting }" @click="deletePosting">Delete Posting</button>
			</div>
			<table class="table w-full">
				<thead>
					<tr>
						<th width="140px">Order#</th>
						<th width="100px">Type</th>
						<th width="120px">Trace#</th>
						<th width="120px">Ref#</th>
						<th>User</th>
						<th width="140px" class="text-center">Status</th>
						<th width="140px" class="text-right">Payment</th>
						<th width="140px" class="text-right">Voucher</th>
						<th width="140px" class="text-right">Tax</th>
						<th width="140px" class="text-right">Rounding</th>
						<th width="200px">Order Time</th>
						<th width="200px"></th>
					</tr>
				</thead>
				<tbody>
					<tr v-if="!shownOrders.length">
						<td colspan="8">No orders available</td>
					</tr>
					
					<tr v-if="shownOrders.length > 0" class="font-medium text-lg">
						<td colspan="6">Total</td>
						<td class="text-right">{{ totals.payment }}</td>
						<td class="text-right">{{ totals.voucher }}</td>
						<td class="text-right">
							{{ totals.totalTax }}<br>
							<span class="text-sm text-gray-500">{{ totals.itemTax }} + {{ totals.mealTax }}</span>
						</td>
						<td class="text-right">{{ totals.rounding }}</td>
						<td colspan="2" class="text-right">
							<div>Invoice: {{ totals.invoice }}</div>
							<div class="text-gray-400 text-sm">Payment - (Tax + Rounding)</div>
						</td>
					</tr>
					
					<tr v-for="o in shownOrders" :key="o.orderID">
						<td>{{ o.label }}</td>
						<td class="uppercase">{{ o.terminalLog ? o.terminalLog.paymentMethod : '-' }}</td>
						<td>{{ o.terminalLog ? o.terminalLog.traceNum : '-' }}</td>
						<td>{{ o.refNum }}</td>
						<td>{{ o.user ? o.user.userName : '' }}</td>
						<td class="font-medium text-center uppercase" :class="OrderStatusClass[o.status]">{{ o.status }}</td>
						<td class="text-right">{{ o.paymentAmount }}</td>
						<td class="text-right">{{ o.voucherAmount }}</td>
						<td class="text-right">{{ o.taxAmount }}</td>
						<td class="text-right">{{ o.roundingAmount }}</td>
						<td>{{ o.created }}</td>
						<td class="text-right space-x-2">
							<button v-if="canCancel(o)" class="button small is-danger" @click="cancelOrder(o)">Void</button>
							<button v-if="canBackdate(o)" class="button small is-warning" @click="backdateOrder(o)">Backdate</button>
							<button v-if="canUncancel(o)" class="button small" @click="uncancelOrder(o)">Uncancel</button>
							
							<button class="button small is-accent" @click="showDetails(o)">View Details</button>
						</td>
					</tr>
					
					
					<tr v-for="p in shownMealPayments" :key="p.paymentID">
						<td>V-Meal Walk-In</td>
						<td class="uppercase">{{ p.terminalLog ? p.terminalLog.paymentMethod : '-' }}</td>
						<td>{{ p.terminalLog ? p.terminalLog.traceNum : '-' }}</td>
						<td>{{ p.refNum }}</td>
						<td></td>
						<td class="font-medium text-center uppercase" :class="OrderStatusClass[p.status]">{{ p.status }}</td>
						<td class="text-right">{{ p.paymentAmount }}</td>
						<td class="text-right">0.00</td>
						<td class="text-right">{{ p.taxAmount }}</td>
						<td class="text-right">{{ p.roundingAmount }}</td>
						<td>{{ p.created }}</td>
						<td class="text-right">
							<button v-if="canBackdatePayment(p)" class="button small is-warning"
								@click="backdatePayment(p, 'meal')">Backdate</button>
						</td>
					</tr>
					
					<tr v-for="p in shownMealPenalties" :key="p.paymentID">
						<td>V-Meal Penalty</td>
						<td class="uppercase">{{ p.terminalLog ? p.terminalLog.paymentMethod : '-' }}</td>
						<td>{{ p.terminalLog ? p.terminalLog.traceNum : '-' }}</td>
						<td>{{ p.refNum }}</td>
						<td></td>
						<td class="font-medium text-center uppercase" :class="OrderStatusClass[p.status]">{{ p.status }}</td>
						<td class="text-right">{{ p.paymentAmount }}</td>
						<td class="text-right">0.00</td>
						<td class="text-right">{{ p.taxAmount }}</td>
						<td class="text-right">{{ p.roundingAmount }}</td>
						<td>{{ p.created }}</td>
						<td class="text-right">
							<button v-if="canBackdatePayment(p)" class="button small is-warning"
								@click="backdatePayment(p, 'penalty')">Backdate</button>
						</td>
					</tr>
					
				</tbody>
			</table>
		</div>
		
		<!-- Details modal -->
		<Modal v-model="detailsShown" containerClass="md:w-3/5">
			<OrderDetails v-bind="{ order: activeOrder }" />
		</Modal>
		
		
		<!-- Pending modal -->
		<Modal v-model="pendingShown" containerClass="md:w-1/3">
			<div class="text-center">
				<!-- Pending -->
				<div v-if="submitting">
					<div class="text-lg font-medium">Refunding order, please wait</div>
					<preloader />
				</div>
				<div v-else>
					<div class="flex items-center justify-center">
						<icon class="mr-4 w-8 h-8 text-success-500" :data="mdiCheckCircle" />
						<div class="text-2xl font-medium">Refund complete</div>
					</div>
					<hr class="hr">
					<div>
						<button class="button is-primary" @click="pendingShown = false">Close</button>
					</div>
					
				</div>
			</div>
		</Modal>
		
		
		<Modal v-model="backdateOrderShown" containerClass="md:w-1/3">
			<BackdateOrder v-bind="{ order: curBackdateOrder }" @backdate="orderBackdated" />
		</Modal>
		
		<Modal v-model="backdatePaymentShown" containerClass="md:w-1/3">
			<BackdatePayment v-bind="{ payment: curBackdatePayment, type: curPaymentType }" @backdate="paymentBackdated" />
		</Modal>
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import Datepicker from '@/components/Datepicker'
import dayjs from 'dayjs'
import { mapState } from 'vuex'
import Modal from '@/components/Modal.vue'
import OrderDetails from './OrderDetails.vue'
import BackdateOrder from './BackdateOrder.vue'
import BackdatePayment from './BackdatePayment.vue'
import { mdiCheckCircle } from '@mdi/js'
import exportCSV from '@/utils/exportCSV'

export default {
	components: {
		Container,
		Datepicker,
		Modal,
		OrderDetails,
		BackdateOrder,
		BackdatePayment,
	},
	computed: {
		...mapState('Constants', [ 'OrderStatusClass' ]),
		shownOrders() {
			return this.orders.filter(o => {
				if (this.status != 'all' && o.status != this.status) return false;
				if (this.type != 'all') {
					if (!o.terminalLog) return false;
					if (o.terminalLog && o.terminalLog.paymentMethod != this.type) return false;
				}
				return true;
			});
		},
		shownMealPayments() {
			return this.mealPayments.filter(o => {
				if (this.status != 'all' && o.status != this.status) return false;
				if (this.type != 'all') {
					if (!o.terminalLog) return false;
					if (o.terminalLog && o.terminalLog.paymentMethod != this.type) return false;
				}
				return true;
			});
			//if (this.status == 'all') return this.mealPayments;
			//return this.mealPayments.filter(p => p.status == this.status);
		},
		shownMealPenalties() {
			return this.mealPenalties.filter(o => {
				if (this.status != 'all' && o.status != this.status) return false;
				if (this.type != 'all') {
					if (!o.terminalLog) return false;
					if (o.terminalLog && o.terminalLog.paymentMethod != this.type) return false;
				}
				return true;
			});
			//if (this.status == 'all') return this.mealPenalties;
			//return this.mealPenalties.filter(p => p.status == this.status);
		},
		totalRows() {
			return this.shownOrders.length + this.shownMealPayments.length + this.shownMealPenalties.length;
		},
		allowPosting() {
			if (!this.$store.state.Auth.scopes.includes('admin:posting')) return false;
			if (this.posting) return false;
			return true;
		},
		totals() {
			const sum = {
				payment: 0,
				voucher: 0,
				itemTax: 0,
				mealTax: 0,
				totalTax: 0,
				rounding: 0,
				invoice: 0,
				count: 0,
			};
			
			for (const i in this.shownOrders) {
				const o = this.shownOrders[i];
				if (o.status == 'paid') {
					sum.payment += parseFloat(o.paymentAmount);
					sum.voucher += parseFloat(o.voucherAmount);
					sum.itemTax += parseFloat(o.taxAmount);
					sum.rounding += parseFloat(o.roundingAmount);
				}
				if (o.status == 'paid' && o.terminalLog) ++sum.count;
			}
			
			for (const i in this.shownMealPayments) {
				const p = this.shownMealPayments[i];
				if (p.status != 'paid') continue;
				sum.payment += parseFloat(p.paymentAmount);
				sum.mealTax += parseFloat(p.taxAmount);
				sum.rounding += parseFloat(p.roundingAmount);
			}
			
			for (const i in this.shownMealPenalties) {
				const p = this.shownMealPenalties[i];
				if (p.status != 'paid') continue;
				sum.payment += parseFloat(p.paymentAmount);
				sum.mealTax += parseFloat(p.taxAmount);
				sum.rounding += parseFloat(p.roundingAmount);
			}
			
			sum.invoice = (sum.payment - (sum.itemTax + sum.mealTax) - sum.rounding).toFixed(2);
			
			sum.payment = sum.payment.toFixed(2);
			sum.voucher = sum.voucher.toFixed(2);
			sum.totalTax = (sum.itemTax + sum.mealTax).toFixed(2);
			sum.itemTax = sum.itemTax.toFixed(2);
			sum.mealTax = sum.mealTax.toFixed(2);
			sum.rounding = sum.rounding.toFixed(2);
			
			
			return sum;
		}
	},
	watch: {
		date: 'fetchRecords',
	},
	data() {
		return {
			mdiCheckCircle,
			
			loading: false,
			submitting: false,
			date: new Date(),
			status: 'all',
			type: 'all',
			
			posting: null,
			
			orders: [],
			mealPayments: [],
			mealPenalties: [],
			
			activeOrder: null,
			detailsShown: false,
			pendingShown: false,
			
			curBackdateOrder: null,
			backdateOrderShown: false,
			
			curPaymentType: '',
			curBackdatePayment: null,
			backdatePaymentShown: false,
		};
	},
	methods: {
		extractRefNum(tl) {
			if (!tl || !tl.payload) return '';
			if (tl.paymentMethod == 'qr') return tl.payload.pay_resp_qr_txn_id;
			else if (tl.paymentMethod == 'card') return tl.payload.pay_resp_card_auth_code;
			return '';
		},
		async fetchRecords() {
			this.loading = true;
			try {
				const date = dayjs(this.date).format('YYYY-MM-DD');
				const queries = [
					`date=${date}`,
				];
				
				const res = await this.$api.request('GET', `orders/reports?${queries.join('&')}`);
				this.orders = res.orders;
				this.mealPayments = res.mealPayments;
				this.mealPenalties = res.mealPenalties;
				
				// Fetch posting record if required
				this.posting = null;
				if (this.$store.state.Auth.scopes.includes('admin:posting')) {
					this.posting = await this.$api.request('GET', `invoice/posting/daily/${date}`);
				}
				
				// Extract fields from orders
				for (const i in this.orders) {
					const o = this.orders[i];
					o.refNum = this.extractRefNum(o.terminalLog);
				}
				
				for (const i in this.mealPayments) {
					const o = this.mealPayments[i];
					o.refNum = this.extractRefNum(o.terminalLog);
				}
				
				for (const i in this.mealPenalties) {
					const o = this.mealPenalties[i];
					o.refNum = this.extractRefNum(o.terminalLog);
				}
				
				
			} catch (err) {
				console.error(err);
				this.$toast.error('Error fetching records');
			}
			this.loading = false;
		},
		async generateCSV() {
			const csvRows = [
				[ 'Order#', 'Type', 'Trace#', 'Ref#', 'User', 'Status', 'Payment', 'Voucher', 'Tax', 'Rounding', 'Timestamp' ]
			];
			for (const i in this.shownOrders) {
				const o = this.shownOrders[i];
				const row = [
					o.label,
					o.terminalLog ? o.terminalLog.paymentMethod : '',
					o.terminalLog ? o.terminalLog.traceNum : '',
					o.refNum,
					o.user ? o.user.userName : '',
					o.status,
					o.paymentAmount,
					o.voucherAmount,
					o.taxAmount,
					o.roundingAmount,
					o.created,
				];
				csvRows.push(row);
			}
			
			for (const i in this.shownMealPayments) {
				const o = this.shownMealPayments[i];
				const row = [
					'V-Meal Walk In',
					o.terminalLog ? o.terminalLog.paymentMethod : '',
					o.terminalLog ? o.terminalLog.traceNum : '',
					o.refNum,
					o.paymentAmount,
					o.status,
					o.paymentMethod,
					'0.00',
					o.taxAmount,
					o.roundingAmount,
					o.created,
				];
				csvRows.push(row);
			}
			
			for (const i in this.shownMealPenalties) {
				const o = this.shownMealPenalties[i];
				const row = [
					'V-Meal Penalty',
					o.terminalLog ? o.terminalLog.paymentMethod : '',
					o.terminalLog ? o.terminalLog.traceNum : '',
					o.refNum,
					o.paymentAmount,
					o.status,
					o.paymentMethod,
					'0.00',
					o.taxAmount,
					o.roundingAmount,
					o.created,
				];
				csvRows.push(row);
			}
			
			const date = dayjs(this.date).format('YYYY-MM-DD');
			exportCSV(csvRows, `SalesReport_${date}.csv`, this.$refs.downloadLink);
		},
		showDetails(order) {
			this.activeOrder = order;
			this.detailsShown = true;
		},
		
		canCancel(order) {
			if (this.posting) return false;
			if (order.status != 'paid' && order.status != 'pending') return false;
			if (order.terminalLog && order.terminalLog.posted) return false;
			return true;
		},
		canBackdate(order) {
			if (this.posting) return false;
			if (order.status != 'pending' && order.status != 'cancelled') return false;
			if (order.terminalLog) return false;
			if (!this.$store.state.Auth.scopes.includes('admin:backdate')) return false;
			return true;
		},
		canUncancel(order) {
			if (this.posting) return false;
			if (order.status != 'cancelled') return false;
			if (!order.terminalLog) return false;
			if (!this.$store.state.Auth.scopes.includes('admin:backdate')) return false;
			return true;
		},
		
		canBackdatePayment(payment) {
			if (this.posting) return false;
			if (payment.status != 'pending') return false;
			if (payment.terminalLog) return false;
			if (!this.$store.state.Auth.scopes.includes('admin:backdate')) return false;
			return true;
		},
		
		async cancelOrder(order) {
			const confirm = await this.$confirm({
				title: 'Cancel Order',
				message: 'Cancel and refund this order?',
				buttons: 'YES_NO',
			});
			if (!confirm) return;
			
			// Show pending dialog while submitting
			this.pendingShown = true;
			this.submitting = true;
			try {
				const tl = order.terminalLog;
				if (tl) {
					await this.$devices.request('DELETE', `pay/cashier/cancel`, {
						orderID: order.orderID,
						traceNum: tl.traceNum,
						amount: tl.amount,
					});
					
					order.terminalLog.status = 'void';
					
				} else {
					// No terminal transaction, void order directly
					await this.$api.request('DELETE', `orders/${order.orderID}`);
				}
				
				// Update order status
				order.status = 'cancelled';
				this.$toast.info('Order refunded');
				
			} catch (err) {
				console.error(err);
				this.$toast.error('Failed to cancel sales record');
			}
			this.submitting = false;
		},
		
		async uncancelOrder(o) {
			const confirm = await this.$confirm({
				title: 'Uncancel Order',
				message: 'Uncancel this order?',
				buttons: 'YES_NO',
			});
			if (!confirm) return;
			
			this.submitting = true;
			try {
				await this.$api.request('POST', `backdate/orders/reverse/${o.orderID}`);
				o.terminalLog.status = 'paid';
				o.status = 'paid';
				
				this.$toast.success('Order uncancel successful');
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Failed to uncancel order');
			}
			this.submitting = false;
		},
		
		
		backdateOrder(o) {
			this.curBackdateOrder = o;
			this.backdateOrderShown = true;
		},
		orderBackdated(terminalLog) {
			const order = this.orders.find(o => o.orderID == terminalLog.paymentID);
			if (!order) return;
			
			order.terminalLog = terminalLog;
			order.status = 'paid';
			
			this.curBackdateOrder = null;
			this.backdateOrderShown = false;
		},
		
		
		backdatePayment(p, type) {
			this.curPaymentType = type;
			this.curBackdatePayment = p;
			this.backdatePaymentShown = true;
		},
		paymentBackdated(terminalLog) {
			const type = this.curPaymentType;
			
			let payment = null;
			if (type == 'meal') payment = this.mealPayments.find(p => p.paymentID == terminalLog.paymentID);
			else if (type == 'penalty') payment = this.mealPenalties.find(p => p.paymentID == terminalLog.paymentID);
			if (!payment) return;
			
			payment.terminalLog = terminalLog;
			payment.status = 'paid';
			
			this.curBackdatePayment = null;
			this.backdatePaymentShown = false;
		},
		
		async submitPosting() {
			this.submitting = true;
			try {
				this.posting = await this.$api.request('POST', `invoice/posting/daily`, {
					date: dayjs(this.date).format('YYYY-MM-DD'),
				});
				
				this.$toast.success('Posting queued');
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Posting failed');
			}
			this.submitting = false;
		},
		async deletePosting() {
			this.submitting = true;
			try {
				const date = dayjs(this.date).format('YYYY-MM-DD');
				await this.$api.request('DELETE', `invoice/posting/daily?date=${date}`);
				
				this.posting = null;
				this.$toast.info('Posting queue deleted');
				
			} catch (err) {
				console.error(err);
				this.$toast.error('Failed to delete');
			}
			this.submitting = false;
		}
	},
	mounted() {
		this.fetchRecords();
	}
}
</script>