<style lang="scss">
.pagination-link {
	min-width: 2rem;
	@apply px-3 py-1 inline-block text-center select-none border border-gray-400 transition-colors duration-200;
	&:not(:first-child) { @apply -ml-px; }
	&:hover { @apply bg-accent-200; }
	&:active { @apply bg-accent-200; }
	&:focus { @apply outline-none; }
	
	&:not(:first-child), &:not(:last-child) {
		@apply mx-1;
	}
	
	&.active {
		@apply bg-accent-600 border-accent-600 text-white cursor-default;
		&:hover { @apply bg-accent-600; }
	}
	
	&.disabled {
		@apply pointer-events-none text-gray-400 border-gray-200;
	}
}
</style>
<template>
	<!-- Page links -->
	<div v-if="numPages > 1" class="text-center">
		<button
			v-for="l in pageLinks"
			@click="linkClicked(l)"
			class="pagination-link"
			:class="l.classes">
			<span v-if="l.title">{{ l.title }}</span>
			<icon v-if="l.icon" :data="icons[l.icon]"></icon>
		</button>
	</div>
</template>
<script>
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js'
export default {
	props: {
		curPage: {
			type: Number,
			required: true
		},
		numLinks: {
			type: Number,
			default: 2
		},
		
		totalRows: Number,
		pageLimit: Number,
	},
	computed: {
		numPages() {
			const pageLimit = this.pageLimit || 1;
			return Math.ceil(this.totalRows / pageLimit);
		},
		rowOffset() {
			if (!this.totalRows) return 0;
			return this.curPage * this.pageLimit;
		},
		
		recordStart() {
			if (!this.totalRows) return 0;
			return this.rowOffset + 1;
		},
		recordEnd() {
			return Math.min(this.pageLimit * (this.curPage + 1), this.totalRows);
		},
		
		pageLinks() {
			const curPage = this.curPage;
			const numLinks = this.numLinks;
			
			let links = [];
			
			// First page
			links.push({
				title: 'First',
				goto: 0,
				classes: [],
				disabled: curPage == 0,
			});
			
			// Previous link
			links.push({
				icon: 'mdiChevronLeft',
				goto: curPage - 1,
				classes: [],
				disabled: curPage == 0
			});
			
			// Before
			for (let i = curPage - numLinks; i < curPage; ++i) {
				if (i < 0) continue;
				links.push({
					title: i + 1,
					goto: i,
					classes: [],
				});
			}
			
			
			// Current page
			links.push({
				title: curPage + 1,
				goto: curPage,
				classes: [ 'active' ],
			});
			
			// After
			for (let i = 1; i <= numLinks; ++i) {
				if (curPage + i >= this.numPages) break;
				links.push({
					title: curPage + i + 1,
					goto: curPage + i,
					classes: [],
				});
			}
			
			// Next link
			links.push({
				icon: 'mdiChevronRight',
				goto: curPage + 1,
				classes: [],
				disabled: curPage == this.numPages - 1
			});
			
			// Last page
			links.push({
				title: 'Last',
				goto: this.numPages - 1,
				classes: [],
				disabled: curPage == this.numPages - 1
			});
			
			
			// Set link classes
			links.forEach((link, li) => {
				//if (li == 0) link.classes.push('rounded-l');
				//else if (li == links.length - 1) link.classes.push('rounded-r');
				
				if (link.disabled) link.classes.push('disabled');
			})
			
			return links;
		}
	},
	data() {
		return {
			icons: {
				mdiChevronLeft,
				mdiChevronRight,
			}
		}
	},
	methods: {
		linkClicked(link) {
			if (link.goto == this.curPage) return;
			this.$emit('goto', link.goto);
		}
	}
}
</script>