<template>
	<div class="md:w-3/5 md:mx-auto text-center py-4">
		
		<div v-if="details">
			<div class="text-3xl font-semibold mb-4">V-Meal Unregistered</div>
			
			<div>Successfully unregistered your V-Meal for <span class="font-bold">{{ details.date }}</span></div>
			
		</div>
		<div v-else>
			<div class="text-3xl font-semibold mb-4 text-danger-600">Unregistration Unsuccessful</div>
			<div>
				An error occured while unregistering your V-Meal.<br>
				The link you provided might be malformed or the validity has expired.
			</div>
		</div>
		
	</div>
</template>
<script>
export default {
	data() {
		return {
			loading: false,
			details: null,
		};
	},
	async mounted() {
		const token = this.$route.query.token;
		if (!token) {
			this.$router.replace('/');
			return;
		}
		
		this.loading = true;
		try {
			const res = await this.$api.request('POST', `meal/unregister`, { token });
			this.details = res;
			
		} catch (err) {
			console.error(err);
			this.$toast.error('Failed to submit request');
		}
		this.loading = false;
	}
}
</script>