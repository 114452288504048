<template>
	<Container>
		<div class="text-3xl font-semibold">Stock Report</div>
		<hr class="hr">
		
		<div class="mb-4">
			<input type="text" class="input w-full" v-model.trim="q" placeholder="Search by product name or partID">
		</div>
		
		<preloader v-if="loading" />
		<div v-else>
			
			<table class="table w-full">
				<thead>
					<tr>
						<th width="200px">PartID</th>
						<th>Product Name</th>
						<th>Variant</th>
						<th width="100px" class="text-right">Stock</th>
					</tr>
				</thead>
				<tbody>
					<tr v-if="!filteredRows.length">
						<td colspan="4">No inventory products available</td>
					</tr>
					
					
					<tr v-for="s in filteredRows" :key="s.variantID" :class="s.stockQty == '0.00' ? 'text-gray-400' : ''">
						<td>{{ s.epicorPartID }}</td>
						<td>{{ s.product.productName }}</td>
						<td>{{ s.variantName }}</td>
						<td class="text-right">{{ s.stockQty }}</td>
					</tr>
				</tbody>
			</table>
			
			
		</div>
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
export default {
	components: {
		Container,
	},
	computed: {
		filteredRows() {
			if (!this.q) return this.stocks;
			return this.stocks.filter(s => {
				const productName = s.product.productName.toUpperCase();
				const epicorPartID = s.epicorPartID.toUpperCase();
				const variantName = s.variantName.toUpperCase();
				const q = this.q.toUpperCase();
				
				if (productName.includes(q)) return true;
				if (epicorPartID.includes(q)) return true;
				if (variantName.includes(q)) return true;
				return false;
			});
		}
	},
	data() {
		return {
			loading: false,
			
			q: '',
			stocks: [],
		};
	},
	methods: {
		async loadStocks() {
			this.loading = true;
			try {
				this.stocks = await this.$api.request('GET', 'stocks');
			} catch (err) {
				console.error(err);
				this.$toast.error('Failed to load stocks');
			}
			this.loading = false;
		}
	},
	async mounted() {
		this.loadStocks();
	}
}
</script>