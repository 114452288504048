<style lang="scss" scoped>
.modal-container {
	max-height: calc(100vh - 6rem);
}

// Modal dialog transition
.modal-enter { opacity: 0; }
.modal-leave-active { opacity: 0; }

.modal-enter .modal-container,
.modal-leave-active .modal-container {
	transform: scale(1.1);
}

</style>
<template>
	<transition name="modal">
		<!-- Modal backdrop -->
		<div
			v-if="value"
			@mousedown="$emit('input', false)"
			class="bg-black fixed z-10 inset-0 w-full h-full flex justify-center items-center transition-opacity duration-300 bg-opacity-75">
			
			<!-- This is the container for the modal -->
			<div
				@click.stop
				@mousedown.stop
				class="modal-container bg-white rounded-lg p-4 transition-all duration-300 overflow-y-auto"
				:class="containerClass">
				<slot></slot>
			</div>
			
		</div>
	</transition>
</template>
<script>
export default {
	props: {
		value: {
			type: Boolean,
			required: true
		},
		containerClass: [ String, Array, Object ],
	}
}
</script>