<style lang="scss" scoped>
.year-list {
	@apply select-none w-full h-full text-center;
	.year {
		@apply cursor-pointer rounded px-4 py-2 transition-colors m-auto;
		&:hover { @apply bg-gray-200; }
		&.active {
			@apply bg-accent-500 text-white font-semibold;
			&:hover { @apply bg-accent-500; }
		}
		&.disabled {
			pointer-events: none;
			@apply text-gray-300;
		}
	}
}
</style>
<template>
	<div class="year-list grid grid-cols-3">
		<div
			v-for="y in yearList"
			class="year"
			:class="{ active: yearActive(y), disabled: yearDisabled(y) }"
			@click="yearSelected(y)">
			{{ y }}
		</div>
	</div>
</template>
<script>
export default {
	props: [ 'year', 'value', 'range' ],
	computed: {
		yearMin() { return Math.floor(this.year / 12) * 12; },
		yearMax() { return this.yearMin + 11; },
		yearList() {
			const years = [];
			for (let i = this.yearMin; i <= this.yearMax; ++i) {
				years.push(i);
			}
			return years;
		}
	},
	methods: {
		yearSelected(y) { this.$emit('input', y); },
		yearActive(y) {
			if (this.value instanceof Date) {
				const sd = this.value;
				return sd.getFullYear() == y;
			}
			return false;
		},
		yearDisabled(y) {
			if (this.range) {
				if (this.range.from && y < this.range.from.year()) return true;
				else if (this.range.to && y > this.range.to.year()) return true;
			}
			return false;
		}
	},
}
</script>