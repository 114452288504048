import jsonwebtoken from 'jsonwebtoken'


function verifyAuthResponse(res, commit) {
	if (!res) return false;
	
	try {
		const jwt = res.jwt;
		const decoded = jsonwebtoken.verify(jwt, process.env.VUE_APP_JWT_PUBLIC_KEY, { algorithms: [ 'RS256' ] });
		commit('authOK', { decoded, ...res });
	} catch(err) {
		console.error(err);
		return false;
	}
	
	return true;
}

export default {
	namespaced: true,
	
	state: {
		gauth: null,
		voneUserID: '',
		pending: false,
		
		jwt: null,
		userID: '',
		userName: '',
		scopes: [],
	},
	getters: {
		authenticated(state) {
			return state.jwt != null;
		}
	},
	mutations: {
		setGAuthInstance(state, gauth) { state.gauth = gauth; },
		
		authOK(state, payload) {
			const { jwt, decoded, pending } = payload;
			
			state.jwt = jwt;
			state.userID = decoded.sub;
			state.pending = pending == true;
			state.userName = decoded.name;
			state.scopes = decoded.scopes;
			
			// Assign JWT and company to REST client
			this._vm.$api.setToken(`Bearer ${jwt}`, 'vwallet-auth');
			this._vm.$api.setCompany(state.activeCompany);
		},
		
	},
	
	actions: {
		// Refresh session from stored token
		async refresh({ commit, dispatch }) {
			const token = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);
			if (!token) return false;
			
			const $api = this._vm.$api;
			const res = await $api.request('POST', 'auth/refresh', { token });
			const verified = verifyAuthResponse(res, commit);
			
			// Store token if refresh success
			if (verified) {
				localStorage.setItem(process.env.VUE_APP_TOKEN_NAME, res.jwt);
				await dispatch('Constants/load', null, { root: true });
			}
			
			return verified;
		},
		
		
		async authenticateLDAP({ commit, dispatch }, payload) {
			const $api = this._vm.$api;
			const res = await $api.request('POST', 'auth/ldap', payload);
			const verified = verifyAuthResponse(res, commit);
			if (verified) {
				localStorage.setItem(process.env.VUE_APP_TOKEN_NAME, res.jwt);
				await dispatch('Constants/load', null, { root: true });
			}
			
			return verified;
		},
		
		
		async authenticateVOne({ commit, dispatch }, { voneUserID }) {
			const $api = this._vm.$api;
			const res = await $api.request('POST', 'auth/vone', { userID: voneUserID });
			const verified = verifyAuthResponse(res, commit);
			if (verified) await dispatch('Constants/load', null, { root: true });
			
			return verified;
		},
		
		async authenticateGoogle({ commit, dispatch }, idToken) {
			const $api = this._vm.$api;
			let verified = false;
			try {
				// Authenticate with our own backend using the idToken
				const res = await $api.request('POST', 'auth/google', { idToken });
				verified = verifyAuthResponse(res, commit);
				
				
				if (verified) {
					localStorage.setItem(process.env.VUE_APP_TOKEN_NAME, res.jwt);
					await dispatch('Constants/load', null, { root: true });
				}
				
			} catch(err) {
				console.error(err);
				return false;
			}
			return verified;
		},
		
		
		async switchUser({ commit, dispatch }, userID) {
			const $api = this._vm.$api;
			let verified = false;
			try {
				const res = await $api.request('POST', `auth/switch/${userID}`);
				verified = verifyAuthResponse(res, commit);
				
				if (verified) {
					localStorage.setItem(process.env.VUE_APP_TOKEN_NAME, res.jwt);
					await dispatch('Constants/load', null, { root: true });
				}
				
			} catch(err) {
				console.error(err);
				return false;
			}
			return verified;
		},
		
		async userOnboarded({ commit, dispatch }, res) {
			const verified = verifyAuthResponse(res, commit);
			if (verified) {
				localStorage.setItem(process.env.VUE_APP_TOKEN_NAME, res.jwt);
				await dispatch('Constants/load', null, { root: true });
			}
			
			return verified;
		},
		
		
		async signOut({ dispatch, state }) {
			// Invalidate everything
			state.jwt = null;
			state.userID = '';
			state.userName = '';
			state.scopes = [];
			
			// Assign JWT to REST client
			this._vm.$api.setToken(null);
			
			// Delete localstorage
			localStorage.removeItem(process.env.VUE_APP_TOKEN_NAME);
			
			await dispatch('Constants/clear', null, { root: true });
		}
	}
}