<template>
	<Container>
		
		<div class="flex items-center gap-2">
			<div class="flex-grow text-3xl font-semibold">Gift Claim</div>
			<div class="text-right">
				<div class="font-medium">Claim Limit: RM {{ totalClaimLimit }}</div>
				<div class="font-medium">Item Cost: RM {{ totalAmount }}</div>
			</div>
			<div>
				<button class="button is-primary" :class="{ loading: submitting }" @click="submit">Submit</button>
			</div>
		</div>
		<hr class="hr">
		
		<TabBar v-bind="{ tabs }" v-model="activeTab" />
		
		<transition name="fade" mode="out-in">
			<component
				:is="tabs[activeTab].component"
				v-bind="{
					formData,
					companyList,
					buList,
					deptList,
				}" />
		</transition>
		
		
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import TabBar from '@/components/TabBar.vue'
import ClaimDetails from './ClaimDetails.vue'
import ClaimItems from './ClaimItems.vue'

export default {
	components: {
		Container,
		TabBar,
		ClaimDetails,
		ClaimItems,
	},
	computed: {
		totalClaimLimit() {
			if (!this.formData.claimLimit || !this.formData.headcount) return '0.00';
			return (this.formData.claimLimit * this.formData.headcount).toFixed(2);
		},
		totalAmount() {
			let sum = 0;
			for (const i in this.formData.items) {
				const item = this.formData.items[i];
				sum += parseFloat(item.price * item.qty);
			}
			return sum.toFixed(2);
		},
		hasCustomerType2() {
			if (!this.formData.customerType) return false;
			return [ 'Customer', 'Vendor', 'SCP/Distributor' ].includes(this.formData.customerType);
		}
	},
	data() {
		return {
			loading: false,
			submitting: false,
			
			formData: {
				collectBy: '',
				onBehalf: '',
				company: '',
				bu: '',
				dept: '',
				
				type: '',
				purpose: '',
				purposeOthers: '',
				customerType: '',
				customerType2: '',
				customerTypeRemarks: '',
				customerName: '',
				customerCompany: '',
				headcount: '',
				vgr: '',
				remarks: '',
				
				items: [],
				
				claimLimit: 0,
			},
			
			activeTab: 'details',
			tabs: {
				details: {
					label: 'Claim Details',
					component: 'ClaimDetails',
				},
				items: {
					label: 'Item List',
					component: 'ClaimItems'
				}
			},
			
			companyList: [],
			buList: [],
			deptList: [],
		};
	},
	methods: {
		validate() {
			const fd = this.formData;
			if (!fd.collectBy) {
				this.$toast.error('Please enter collected by user');
				return false;
			}
			
			if (!fd.type) {
				this.$toast.error('Please select gift type');
				return false;
			}
			
			
			
			if (fd.type == 'internal') {
				if (!fd.purpose) {
					this.$toast.error('Please select gift purpose');
					return false;
				}
				if (fd.purpose == 'Others' && !fd.purposeOthers) {
					this.$toast.error('Please enter real purpose');
					return false;
				}
				
			} else if (fd.type == 'external') {
				if (!fd.customerType) {
					this.$toast.error('Please select customer');
					return false;
				}
				if (this.hasCustomerType2 && !fd.customerType2) {
					this.$toast.error('Please select customer type 2');
					return false;
				}
				if (!fd.customerName) {
					this.$toast.error('Please enter customer name');
					return false;
				}
				if (!fd.customerCompany) {
					this.$toast.error('Please enter customer company');
					return false;
				}
				if (!fd.headcount) {
					this.$toast.error('Please enter a valid headcount');
					return false;
				}
				
				if (!fd.items.length) {
					this.$toast.error('No items selected');
					return false;
				}
				
				const totalClaimLimit = fd.claimLimit * fd.headcount;
				if (parseFloat(this.totalAmount) > totalClaimLimit) {
					this.$toast.error('Claim limit exceeded');
					return false;
				}
			}
			
			
			return true;
		},
		async submit() {
			if (!this.validate()) return;
			
			const confirm = await this.$confirm({
				title: 'Confirm Submission',
				message: 'Confirm to submit gift claim?',
				buttons: 'YES_NO',
			});
			if (!confirm) return;
			
			
			
			this.submitting = true;
			try {
				
				const data = {
					...this.formData,
					claimLimit: this.totalClaimLimit,
					claimAmount: this.totalAmount,
				};
				if (data.purpose == 'Others') data.purpose = this.formData.purposeOthers;
				if (data.customerType == 'Others') data.customerType = this.formData.customerTypeRemarks;
				
				const res = await this.$api.request('POST', `gifts/records`, data);
				
				this.$toast.success('Gift claim successful');
				this.$router.push('/admin/reports/gift');
				
			} catch(err) {
				if (err == 'ineligible_claimee') {
					await this.$confirm({
						title: 'Gift Claim Ineligible',
						message: 'The selected user is not eligible for gift claim',
						buttons: [{ label: 'Close', btnClass: 'is-danger', value: false }],
					});
				} else {
					console.error(err);
					this.$toast.error('Error submitting claim');
				}
			}
			this.submitting = false;
		}
	},
	async mounted() {
		this.loading = true;
		try {
			const { company, bu, dept } = await this.$api.request('GET', `misc/bu`);
			this.companyList = company.filter(c => c.company);
			this.buList = bu.filter(b => b.bu);
			this.deptList = dept.filter(d => d.dept);
			
		} catch(err) {
			console.error(err);
			this.$toast.error('Error loading data');
		}
		this.loading = false;
	}
}
</script>