<template>
	<Container>
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
export default {
	components: {
		Container,
	},
	async mounted() {
		
	}
}
</script>