//import Registration from './Registration/Registration.vue'
import Registration from './RegisterForm/RegisterForm.vue'
import SelfScan from './SelfScan/SelfScan.vue'
import AppealForm from './AppealForm/AppealForm.vue'
import Unregister from './Unregister.vue'

export default [{
	path: '/meal/registration',
	component: Registration,
}, {
	path: '/meal/selfscan',
	component: SelfScan,
}, {
	path: '/meal/appeal',
	component: AppealForm,
}, {
	path: '/meal/unregister',
	component: Unregister,
	meta: {
		scopes: 'public'
	}
}]