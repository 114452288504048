<template>
	<div>
		
		<!-- Push notifications -->
		<preloader v-if="loading" />
		<div v-else class="space-y-2">
			<label class="label">Push Notifications</label>
			<div>
				<div>Receive system notifications and reminders.</div>
				<div class="font-medium">Status:
					<span v-if="pushSubscription" class="text-success-500">Enabled</span>
					<span v-else class="text-danger-500">Disabled</span>
				</div>
			</div>
			
			<div>
				<button v-if="!pushSubscription" @click="subscribePush" class="button is-primary" :class="{ loading: submitting }">Enable</button>
				<button v-else @click="unsubscribePush" class="button is-danger" :class="{ loading: submitting }">Disable</button>
			</div>
		</div>
		
	</div>
</template>
<script>
import { subscribePush, unsubscribePush } from '@/plugins/WebPush'


export default {
	data() {
		return {
			loading: false,
			submitting: false,
			
			pushSubscription: null,
		};
	},
	methods: {
		async subscribePush() {
			this.submitting = true;
			try {
				const subscription = await subscribePush();
				const res = await this.$api.request('POST', `users/webpush/subscription`, { subscription });
				this.pushSubscription = res.subscription;
				this.$toast.success('Push notifications subscribed');
				
			} catch(err) {
				if (err == 'permission_denied') {
					this.$toast.error('Notifications permission denied');
				} else {
					console.error(err);
					this.$toast.error('Failed to subscribe to push notifications');
				}
				
			}
			this.submitting = false;
		},
		
		async unsubscribePush() {
			this.submitting = true;
			try {
				await unsubscribePush();
				await this.$api.request('DELETE', `users/webpush/subscription`);
				
				this.pushSubscription = null;
				this.$toast.info('Push notifications unsubscribed');
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Failed to subscribe to push notifications');
			}
			this.submitting = false;
		}
	},
	async mounted() {
		this.loading = true;
		try {
			const res = await this.$api.request('GET', `users/webpush/subscription`);
			if (res) this.pushSubscription = res.subscription;
			
		} catch(err) {
			console.error(err);
		}
		this.loading = false;
	}
}
</script>
