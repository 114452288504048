import ConfirmDialog from './ConfirmDialog'
import { emitter } from '@/utils'


export default {
	install(app) {
		// Register container as global component
		app.component('confirm-dialog', ConfirmDialog);
		
		const handler = async (options) => {
			return new Promise((resolve, reject) => {
				if (!options) {
					reject('Invalid options');
					return;
				}
				// Trigger the confirm modal to show
				emitter.emit('confirm-dialog/open', {
					...options,
					callback: (res) => { resolve(res) }
				});
			});
		}
		app.prototype.$confirm = handler;
	}
}