<template>
	<div>
		<preloader v-if="loading" />
		<table v-else class="table w-full">
			<thead>
				<tr>
					<th width="10%">ID</th>
					<th>Name</th>
					<th width="200px">Date</th>
					<th width="10%">Company</th>
					<th width="10%">BU</th>
					<th width="10%">Dept</th>
					<th width="10%"></th>
				</tr>
			</thead>
			<tbody>
				<tr v-if="!penalties.length">
					<td colspan="6">No records available</td>
				</tr>
				<tr v-for="(p, pi) in penalties" :key="pi">
					<td>{{ p.userID }}</td>
					<td>{{ p.userName }}</td>
					<td>{{ p.date }}</td>
					<td>{{ p.company }}</td>
					<td>{{ p.bu }}</td>
					<td>{{ p.dept }}</td>
					<td class="space-x-2 text-right">
						<!-- Waive penalty -->
						<button v-if="p.isOpen"
							class="button is-success text-sm px-2 py-1"
							:class="{ loading: submitting }"
							@click="waivePenalty(p.userID, p.date)">Waive</button>
						
						<!--
						<button v-if="!p.isOpen"
							class="button is-danger text-sm px-2 py-1"
							:class="{ loading: submitting }"
							@click="unwaivePenalty(p.userID, p.date)">Unwaive</button>
						-->
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
import dayjs from 'dayjs'

export default {
	props: [ 'startDate', 'endDate', 'nonce', 'status' ],
	watch: {
		nonce() {
			this.loadPage()
		},
	},
	data() {
		return {
			loading: false,
			submitting: false,
			
			penalties: [],
		};
	},
	methods: {
		async loadPage() {
			this.loading = true;
			try {
				
				const queries = [
					`start=${dayjs(this.startDate).format('YYYY-MM-DD')}`,
					`end=${dayjs(this.endDate).format('YYYY-MM-DD')}`,
					`status=${this.status}`,
				];
				
				//const date = dayjs(this.date).format('YYYY-MM-DD');
				const { penalties, users } = await this.$api.request('GET', `meal/penalties?${queries.join('&')}`);
				const userMap = {};
				for (const i in users) {
					const u = users[i];
					userMap[u.userID] = u;
				}
				
				this.penalties = penalties.map(p => ({
					...p,
					...userMap[p.userID],
					status: '',
				}));
				
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error loading penalty list');
			}
			this.loading = false;
		},
		
		async waivePenalty(userID, date) {
			this.submitting = true;
			try {
				await this.$api.request('POST', `meal/penalties/waive`, { userID, date });
				
				const index = this.penalties.findIndex(p => p.userID == userID && p.date == date);
				if (index >= 0) this.penalties.splice(index, 1);
				
				this.$toast.success('Penalty waived');
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Failed to waive penalty');
			}
			this.submitting = false;
		},
		
		/*
		async unwaivePenalty(userID, date) {
			this.submitting = true;
			try {
				await this.$api.request('POST', `meal/penalties/unwaive`, { userID, date });
				
				const index = this.penalties.findIndex(p => p.userID == userID && p.date == date);
				if (index >= 0) this.penalties.splice(index, 1);
				
				this.$toast.error('Unwaived Penalty');
				
			} catch(err) {
				if (err == 'already_paid') {
					this.$toast.error('User has already paid penalty for given date');
				} else {
					console.error(err);
					this.$toast.error('Failed to waive penalty');
				}
			}
			this.submitting = false;
		}
		*/
	},
	async mounted() {
		this.loadPage();
	}
}
</script>