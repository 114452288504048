<template>
	<Container>
		
		<div class="flex items-center justify-between">
			<div class="text-3xl font-semibold">Product Categories</div>
			<button class="button is-primary" @click="addCategory">Add Category</button>
		</div>
		<hr class="hr">
		
		
		<div class="flex items-start gap-2">
			
			<!-- Main cat -->
			<div class="w-1/4 select-none">
				<div v-for="c in cats" :key="c.catID"
					class="font-medium hover:bg-gray-100 cursor-pointer px-2 py-1"
					:class="c.catID == activeCatID ? 'text-accent-500' : ''"
					@click="activeCatID = c.catID">
					{{ c.catName }}
				</div>
			</div>
			
			
			<div v-if="activeCatID" class="flex-grow">
				
				<div class="flex items-center gap-2 mb-2">
					<input type="text" class="input flex-grow" v-model.trim="catName" placeholder="Category name">
					<button class="button is-primary" :class="{ loading: submitting }" @click="updateCategory">Update</button>
					<button class="button is-danger" :class="{ loading: submitting }" @click="deleteCategory">Delete</button>
				</div>
				
				
				<preloader v-if="fetching" />
				<div v-else>
					<table class="table w-full mb-4">
						<tr>
							<th>Subcategory name</th>
							<th width="180px"></th>
						</tr>
						
						<tr v-if="!subcats.length">
							<td colspan="2">No subcategories defined</td>
						</tr>
						
						<tr v-for="sc in subcats" :key="sc.catID">
							<td>
								<input type="text" class="input w-full text-sm py-1 px-2" v-model.trim="sc.catName" placeholder="Subcategory name">
							</td>
							<td>
								<div class="text-right space-x-2">
									<button class="button is-primary text-sm px-2 py-1" :class="{ loading: submitting }" @click="saveSubcategory(sc)">Save</button>
									<button class="button is-danger text-sm px-2 py-1" :class="{ loading: submitting }" @click="deleteSubcategory(sc)">Delete</button>
								</div>
							</td>
						</tr>
					</table>
					
					<div class="text-right">
						<button class="button is-primary" @click="addSubcategory">Add Subcategory</button>
					</div>
				</div>
				
				
			</div>
			
			
		</div>
		
		
		<Modal v-model="showForm" containerClass="w-3/5">
			<CategoryForm
				v-bind="{
					parentID: activeCatID,
				}"
				@added="categoryAdded" />
		</Modal>
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import Modal from '@/components/Modal.vue'
import CategoryForm from './CategoryForm.vue'


export default {
	components: {
		Container,
		Modal,
		CategoryForm,
	},
	watch: {
		activeCatID() {
			if (!this.activeCatID) return;
			
			const cat = this.cats.find(c => c.catID == this.activeCatID);
			if (!cat) return;
			
			this.catName = cat.catName;
			this.fetchSubcat();
		}
	},
	data() {
		return {
			loading: false,
			fetching: false,
			submitting: false,
			
			showForm: false,
			
			activeCatID: null,
			
			catName: '',
			
			cats: [],
			subcats: [],
		};
	},
	methods: {
		addCategory() {
			this.activeCatID = null;
			this.showForm = true;
		},
		categoryAdded(cat) {
			if (!cat.parentID) this.cats.push(cat);
			else this.subcats.push(cat);
			
			this.$toast.success('Category added');
			this.showForm = false;
		},
		async updateCategory() {
			if (!this.activeCatID) return;
			const cat = await this.cats.find(c => c.catID == this.activeCatID);
			if (!cat) return;
			
			this.submitting = true;
			try {
				await this.$api.request('PUT', `products/categories/${this.activeCatID}`, { catName: this.catName });
				cat.catName = this.catName;
				
				this.$toast.success('Category updated');
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error updating category');
			}
			this.submitting = false;
		},
		async deleteCategory() {
			if (!this.activeCatID) return;
			const confirm = await this.$confirm({
				title: 'Delete Category',
				message: 'Confirm to delete this category?',
				buttons: 'YES_NO',
			});
			if (!confirm) return;
			
			this.submitting = true;
			try {
				await this.$api.request('DELETE', `products/categories/${this.activeCatID}`);
				let index = this.cats.findIndex(c => c.catID == this.activeCatID);
				if (index >= 0) this.cats.splice(index, 1);
				this.activeCatID = null;
				
				this.$toast.info('Category removed');
			} catch(err) {
				console.error(err);
				this.$toast.error('Error deleting category');
			}
			this.submitting = false;
			
		},
		
		
		async fetchSubcat() {
			if (!this.activeCatID) return;
			
			this.fetching = true;
			try {
				const subcats = await this.$api.request('GET', `products/categories?parentID=${this.activeCatID}`);
				this.subcats = subcats;
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error loading subcategories');
			}
			this.fetching = false;
		},
		addSubcategory() {
			this.showForm = true;
		},
		async saveSubcategory(subcat) {
			this.submitting = true;
			try {
				await this.$api.request('PUT', `products/categories/${subcat.catID}`, { catName: subcat.catName });
				this.$toast.success('Subcategory updated');
			} catch(err) {
				console.error(err);
				this.$toast.error('Error updating subcategory');
			}
			this.submitting = false;
		},
		async deleteSubcategory(subcat) {
			const confirm = await this.$confirm({
				title: 'Delete Subcategory',
				message: 'Confirm to delete this subcategory?',
				buttons: 'YES_NO',
			});
			if (!confirm) return;
			
			
			this.submitting = true;
			try {
				await this.$api.request('DELETE', `products/categories/${subcat.catID}`);
				let index = this.subcats.findIndex(sc => sc.catID == subcat.catID);
				if (index >= 0) this.subcats.splice(index, 1);
				
				this.$toast.info('Subcategory removed');
			} catch(err) {
				console.error(err);
				this.$toast.error('Error deleting subcategory');
			}
			this.submitting = false;
		}
	},
	async mounted() {
		this.loading = true;
		try {
			const categories = await this.$api.request('GET', `products/categories`);
			this.cats = categories;
			
		} catch(err) {
			console.error(err);
			this.$toast.error('Error loading page');
		}
		this.loading = false;
	}
}
</script>