<template>
	<Container>
		
		<preloader v-if="loading" />
		<div v-else-if="order">
			<div class="text-3xl font-medium">Order #{{ order.label }}</div>
			<hr class="hr">
			
			<table class="table w-full mb-8">
				<tbody>
					<tr>
						<td width="15%">Order Status</td>
						<td><span class="font-medium uppercase" :class="OrderStatusClass[order.status]">{{ order.status }}</span></td>
					</tr>
					<tr>
						<td>Order Placed</td>
						<td>{{ order.created }}</td>
					</tr>
					<tr>
						<td>Order Ready Time</td>
						<td>{{ order.ready || '-' }}</td>
					</tr>
					<tr>
						<td>Order Received Time</td>
						<td>{{ order.received || '-' }}</td>
					</tr>
					<tr>
						<td>Remarks</td>
						<td>
							<div class="whitespace-pre-wrap italic text-gray-500">{{ order.remarks }}</div>
						</td>
					</tr>
				</tbody>
			</table>
			
			<div class="text-2xl font-medium mb-2">Order Items</div>
			<table class="table w-full">
				<thead>
					<tr>
						<th>Product</th>
						<th width="200px" class="text-right">Unit Price (RM)</th>
						<th width="120px" class="text-right">Qty</th>
						<th width="200px" class="text-right">Total (RM)</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(it, ii) in order.items" :key="ii">
						<td>{{ it.productName }}: {{ it.variantName }}</td>
						<td class="text-right">{{ it.unitPrice }}</td>
						<td class="text-right">{{ it.qty }}</td>
						<td class="text-right">{{ it.totalPrice }}</td>
					</tr>
					
					<tr>
						<td colspan="3" class="text-right">Order Total</td>
						<td class="text-right">{{ orderAmount }}</td>
					</tr>
					
					<tr v-if="order.voucherAmount > 0">
						<td colspan="3" class="text-right">Voucher Applied</td>
						<td class="text-right">-{{ order.voucherAmount }}</td>
					</tr>
					
					<tr class="text-lg font-medium">
						<td colspan="3" class="text-right">Total Payment</td>
						<td class="text-right">RM {{ order.paymentAmount }}</td>
					</tr>
					
				</tbody>
			</table>
			
			
			<div class="text-right mt-6">
				
				<!-- Pay now button -->
				<button v-if="order.status == 'ready'" class="button is-primary" :class="{ loading: submitting }" @click="payNow">Pay Now</button>
				
				<!-- Confirm order button -->
				<button v-if="order.status == 'collected'" class="button is-primary" :class="{ loading: submitting }" @click="orderReceived">Confirm Order Received</button>
			</div>
			
		</div>
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import { mapState } from 'vuex'
import { generatePaymentURL } from '@/utils/payment'

export default {
	props: [ 'orderID' ],
	components: {
		Container,
	},
	computed: {
		...mapState('Constants', [ 'OrderStatusClass' ]),
		orderAmount() {
			if (!this.order) return '';
			return (this.order.paymentAmount * 1 + this.order.voucherAmount * 1).toFixed(2);
		}
	},
	data() {
		return {
			loading: false,
			submitting: false,
			
			order: null,
		};
	},
	methods: {
		async orderReceived() {
			this.submitting = true;
			try {
				await this.$api.request('PUT', `orders/${this.orderID}/received`);
				this.order.status = 'received';
				
				this.$toast.success('Order closed');
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Failed to acknowledge order');
			}
			this.submitting = false;
		},
		
		async payNow() {
			const confirm = await this.$confirm({
				title: 'Redirecting to Payment Gateway',
				messsage: 'You will be redirected to our third party payment gateway for payment.\nPlease do not close or refresh the page until transaction is complete.',
				buttons: 'OK_CANCEL'
			});
			if (!confirm) return;
			
			
			this.submitting = true;
			try {
				
				const res = await this.$api.request('GET', `orders/${this.orderID}/payment`);
				const { order, payment, user } = res;
				const paymentURL = generatePaymentURL({ order, payment, user });
				window.open(paymentURL, '_blank');
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Failed to initiate payment request');
			}
			this.submitting = false;
		}
	},
	async mounted() {
		this.loading = true;
		try {
			this.order = await this.$api.request('GET', `orders/${this.orderID}/details`);
			
		} catch (err) {
			console.error(err);
			this.$toast.error('Failed to load order');
			this.$router.replace('/orders');
		}
		this.loading = false;
	}
}
</script>