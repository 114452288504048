<style lang="scss" scoped>
.router-link-exact-active {
	@apply bg-gray-300 transition-colors duration-200;
}

@media print {
	._sidenav {
		display: none;
	}
}

</style>
<template>
	<div class="_sidenav h-full flex flex-col transition-all"
		@mouseover="isCollapsed = false"
		@mouseleave="isCollapsed = true">
		
		<SidenavLinks v-bind="{ collapse }" class="flex-grow" />
		
		
		<!-- Lower section -->
		<div class="flex-shrink-0">
			<!-- Collapse button -->
			<div
				v-if="collapsible"
				@click="toggleCollapse"
				class="px-4 h-12 hover:bg-gray-200 transition-colors hover:no-underline select-none cursor-pointer flex items-center space-x-4">
				<icon class="w-5 h-5" :data="forceOpen ? mdiArrowCollapseLeft : mdiArrowCollapseRight" />
				<transition name="sidenav-link" mode="out-in">
					<span v-show="!collapse" class="md:block font-medium whitespace-nowrap overflow-hidden w-40">V-{{ forceOpen ? 'Collapse' : 'Expand' }}</span>
				</transition>
			</div>
			
			
			<template v-if="showLogout">
				<div class="h-px bg-gray-300 my-2"></div>
				
				<!-- Sign out link -->
				<div
					@click="signOut"
					class="px-4 h-12 hover:bg-gray-200 transition-colors hover:no-underline select-none cursor-pointer flex items-center space-x-4">
					<icon class="w-5 h-5" :data="mdiLogout" />
					<transition name="sidenav-link" mode="out-in">
					<span v-show="!collapse" class="md:block font-medium whitespace-nowrap overflow-hidden w-40">V-Sign Out</span>
				</transition>
				</div>
			</template>
		</div>
	</div>
</template>
<script>
import SidenavLinks from './SidenavLinks'
import './transitions.scss'
import { mdiLogout, mdiArrowCollapseLeft, mdiArrowCollapseRight } from '@mdi/js'


export default {
	props: [ 'collapsible', 'showLogout' ],
	components: {
		SidenavLinks,
	},
	computed: {
		collapse() {
			if (!this.collapsible || this.forceOpen) return false;
			return this.isCollapsed;
		}
	},
	data() {
		return {
			mdiLogout,
			mdiArrowCollapseLeft,
			mdiArrowCollapseRight,
			
			isCollapsed: true,
			forceOpen: false,
		};
	},
	
	
	methods: {
		async signOut() {
			this.$sidebar.hide();
			await this.$store.dispatch('Auth/signOut');
			this.$router.replace('/login');
		},
		toggleCollapse() {
			//this.collapse = !this.collapse;
			this.forceOpen = !this.forceOpen;
		}
	},
	
	async mounted() {
		
	}
	
}
</script>