import Vuex from 'vuex'
import VueRouter from 'vue-router'

import REST from '@/plugins/REST'
import Toast from '@/plugins/Toast'
import ConfirmDialog from '@/plugins/ConfirmDialog'
import Sidebar from '@/plugins/Sidebar'

// dayjs
import dayjs from 'dayjs'
import RelativeTime from 'dayjs/plugin/relativeTime'
import CustomParseFormat from 'dayjs/plugin/customParseFormat'

import Icon from '@/components/Icon'
import Preloader from '@/components/Preloader'


export default (Vue, options) => {
	Vue.use(Vuex);
	Vue.use(VueRouter);
	
	// Toast message plugin
	Vue.use(Toast, { duration: 2000, align: 'center', maxToasts: 5 });
	
	// Confirmation dialog
	Vue.use(ConfirmDialog);
	Vue.use(Sidebar);
	
	// Init dayjs plugins
	dayjs.extend(RelativeTime);
	dayjs.extend(CustomParseFormat);
	
	
	// Global components
	Vue.component('icon', Icon);
	Vue.component('preloader', Preloader);
	
	
	// Default API request client
	Vue.use(new REST, {
		host: (options && options.apiPath) ? options.apiPath : process.env.VUE_APP_API_BASEPATH,
		handle: 'api',			// Can be accessed from components using this.$api
	});
	
	
	// Printer client
	Vue.use(new REST, {
		host: process.env.VUE_APP_DEVICES_HOST,
		handle: 'devices',
	});
	
}