<template>
	<Container>
		<div class="text-3xl font-semibold">{{ pageTitle }}</div>
		<hr class="hr">
		
		<TabBar v-model="activeTab" v-bind="{ tabs: shownTabs }" />
		
		<preloader v-if="loading" />
		<div v-else>
			<div class="px-2 py-2">
				<component :is="tabs[activeTab].component" v-bind="{ productID }" />
			</div>
		</div>
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import TabBar from '@/components/TabBar.vue'
import ProductDetails from './ProductDetails.vue'
import VariantList from './VariantList.vue'
import ImageList from './ImageList.vue'

export default {
	props: [ 'productID' ],
	components: {
		Container,
		TabBar,
		ProductDetails,
		VariantList,
		ImageList,
	},
	computed: {
		pageTitle() {
			if (this.productID) return 'Edit Product';
			return 'New Product';
		},
		shownTabs() {
			const tabs = { ...this.tabs };
			
			// Some tabs are only shown after product is created
			if (!this.productID) {
				delete tabs.variants;
				delete tabs.images;
			}
			
			return tabs;
		}
	},
	data() {
		return {
			loading: false,
			submitting: false,
			
			activeTab: 'details',
			tabs: {
				details: {
					label: 'Details',
					component: 'product-details',
				},
				variants: {
					label: 'Variants',
					component: 'variant-list',
				},
				images: {
					label: 'Images',
					component: 'image-list',
				}
			},
		};
	},
}
</script>