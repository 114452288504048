<template>
	<div>
		<div class="text-2xl font-medium">Filter Users</div>
		<hr class="hr">
		
		<div class="space-y-2">
			
			<div>
				<label class="label">Company</label>
				<select class="input w-full" v-model="filters.company">
					<option value="">All companies</option>
					<option v-for="c in companyList" :key="c.company">{{ c.company }}</option>
				</select>
			</div>
			
			<div>
				<label class="label">BU</label>
				<select class="input w-full" v-model="filters.bu">
					<option value="">All BUs</option>
					<option v-for="bu in buList" :key="bu.bu">{{ bu.bu }}</option>
				</select>
			</div>
			
			<div>
				<label class="label">Dept</label>
				<select class="input w-full" v-model="filters.dept">
					<option value="">All departments</option>
					<option v-for="dept in deptList" :key="dept.dept">{{ dept.dept }}</option>
				</select>
			</div>
			
			<div>
				<label class="label">External Employees</label>
				<select class="input w-full" v-model="filters.isExternal">
					<option :value="null">All</option>
					<option :value="false">Internal</option>
					<option :value="true">External</option>
				</select>
			</div>
			
			<div>
				<label class="label">Status</label>
				<select class="input w-full" v-model="filters.status">
					<option value="">All</option>
					<option value="active">Active</option>
					<option value="inactive">Inactive</option>
				</select>
			</div>
			
		</div>
		<hr class="hr">
		
		<div>
			<button class="button is-primary" @click="apply">Apply</button>
		</div>
		
		
	</div>
</template>
<script>
export default {
	props: [ 'filters', 'companyList', 'buList', 'deptList' ],
	methods: {
		apply() {
			this.$emit('apply');
		}
	}
}
</script>