<template>
	<div>
		
		<preloader v-if="loading" />
		<table v-else class="table w-full mt-4">
			<thead>
				<tr>
					<th width="200px">Invoice #</th>
					<th width="200px">Type</th>
					<th width="120px">Date</th>
					<th width="100px">Status</th>
					<th>Timestamp</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr v-if="!records.length">
					<td colspan="6">No records available</td>
				</tr>
				<tr v-for="r in records" :key="r.logID">
					<td>{{ r.invoiceNum }}</td>
					<td class="font-medium uppercase">{{ r.type }}</td>
					<td>{{ r.date }}</td>
					<td class="font-medium uppercase" :class="StatusClass[r.status]">{{ r.status }}</td>
					<td>{{ r.timestamp }}</td>
					<td class="text-right">
						<button class="button is-accent px-2 py-1 text-sm" @click="showPayload(r)">Details</button>
					</td>
				</tr>
			</tbody>
		</table>
		
		
		<Modal v-model="payloadShown" containerClass="w-5/6">
			<div v-if="activeRecord" class="whitespace-pre-wrap">{{ activeRecord.payload }}</div>
		</Modal>
		
	</div>
</template>
<script>
import Modal from '@/components/Modal.vue'
import dayjs from 'dayjs'

export default {
	props: [ 'date' ],
	components: {
		Modal,
	},
	watch: {
		date: 'fetchRecords'
	},
	data() {
		return {
			loading: false,
			payloadShown: false,
			
			StatusClass: {
				success: 'text-success-500',
				error: 'text-danger-500',
			},
			
			activeRecord: null,
			records: [],
		};
	},
	methods: {
		showPayload(r) {
			this.activeRecord = r;
			this.payloadShown = true;
		},
		async fetchRecords() {
			this.loading = true;
			try {
				const date = dayjs(this.date).format('YYYY-MM-DD');
				const res = await this.$api.request('GET', `invoice/reports/logs?date=${date}`);
				this.records = res;
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error fetching data');
			}
			this.loading = false;
		}
	},
	mounted() {
		this.fetchRecords();
	}
}
</script>