<template>
	<div class="h-full flex flex-col">
		
		<div>
			<div class="flex space-x-2">
				<input type="text" class="input w-full" placeholder="Search or scan barcode" v-model.trim="query" @keydown.enter="search">
				<button class="button" @click="clear">Clear</button>
			</div>
			
			<hr class="hr">
		</div>
		<div class="overflow-y-auto">
			<!-- Search results -->
			<preloader v-if="fetching" />
			<div v-else>
				
				<!-- Pinned items -->
				<div v-for="(it, ii) in pinnedItems"
					:key="`pin-${ii}`"
					@click="selectItem(it, true)"
					class="px-2 py-0.5 hover:bg-gray-100 cursor-pointer select-none border-b border-gray-200 flex items-center">
					
					<div v-if="updating" class="mx-auto">
						<preloader />
					</div>
					<template v-else>
						<div class="flex-grow">
							<div class="font-medium">{{ it.productName }}</div>
							<div class="text-sm text-gray-400">{{ it.variantName }}</div>
							<div v-if="it.isTaxable" class="font-medium text-sm text-white bg-danger-500 inline-block px-2 rounded">SST</div>
						</div>
						
						<div class="font-medium">RM {{ it.price }}</div>
						
						<button @click.stop="unpinItem(it)" class="text-accent-500 px-2 ml-2">
							<icon :data="mdiPinOff" />
						</button>
					</template>
				</div>
				
				
				<div v-for="(it, ii) in items"
					:key="ii"
					@click="selectItem(it, false)"
					class="px-2 py-0.5 hover:bg-gray-100 cursor-pointer select-none border-b border-gray-200 flex items-center">
					<div class="flex-grow">
						<div class="font-medium">{{ it.productName }}</div>
						<div class="text-sm text-gray-400">{{ it.variantName }}</div>
						
						<div class="flex items-center gap-2">
							<div v-if="it.isTaxable" class="font-medium text-sm text-white bg-danger-500 inline-block px-2 rounded">SST</div>
							<div v-if="it.isInventory && it.stockQty == '0.00'"
								class="font-medium text-sm text-white bg-danger-500 inline-block px-2 rounded">
								Out of Stock
							</div>
						</div>
					</div>
					
					<div class="font-medium">RM {{ it.price }}</div>
					
					<button @click.stop="pinItem(it)" class="text-gray-400 hover:text-accent-500 px-2 ml-2">
						<icon :data="mdiPin" />
					</button>
				</div>
			</div>
		</div>
		
	</div>
</template>
<script>
import { mdiPin, mdiPinOff } from '@mdi/js'
import { mapState } from 'vuex'
export default {
	computed: {
		...mapState('Cashier', [ 'pinnedItems' ]),
	},
	data() {
		return {
			mdiPin,
			mdiPinOff,
			
			fetching: false,
			updating: false,
			query: '',
			items: [],
		};
	},
	methods: {
		async search() {
			this.fetching = true;
			try {
				const res = await this.$api.request('GET', `products/search?q=${encodeURIComponent(this.query)}`);
				this.items = res.map(it => ({
					productID: it.productID,
					variantID: it.variantID,
					productName: it.product.productName,
					variantName: it.variantName,
					price: it.price,
					stockQty: it.stockQty,
					isTaxable: it.isTaxable,
					isInventory: it.isInventory,
				})).filter(item => {
					const index = this.pinnedItems.findIndex(it => it.productID == item.productID && it.variantID == item.variantID);
					return index < 0;
				});
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error fetching items');
			}
			this.fetching = false;
		},
		
		
		async selectItem(it, pinned) {
			if (!pinned) {
				this.$emit('select', it);
				return;
			}
			
			// If pinned item, we need to fetch its current first
			this.updating = true;
			try {
				const res = await this.$api.request('GET', `cashier/pinned/${it.productID}/${it.variantID}`);
				
				it.price = res.price;
				it.stockQty = res.stockQty;
				it.isInventory = res.isInventory;
				it.isTaxable = res.isTaxable;
				it.isActive = res.isActive;
				await this.$store.dispatch('Cashier/updatePinnedItems');
				
				if (it.isInventory && it.stockQty <= 0) {
					this.$toast.error('Item is out of stock');
				} else if (!it.isActive) {
					this.$toast.error('Item is inactive');
				} else {
					this.$emit('select', it);
				}
				
			} catch (err) {
				console.error(err);
				this.$toast.error('Item invalid');
			}
			this.updating = false;
		},
		pinItem(item) {
			this.$store.dispatch('Cashier/addPinnedItem', item);
			// Remove from search list if pinned
			const index = this.items.findIndex(it => it.productID == item.productID && it.variantID == item.variantID);
			if (index >= 0) this.items.splice(index, 1);
		},
		unpinItem(it) {
			this.$store.dispatch('Cashier/removePinnedItem', it);
		},
		clear() {
			this.query = '';
			this.items = [];
		}
	},
}
</script>