<template>
	<Accordion v-bind="{ forceClose: collapse }">
		<template #header>
			<div
				class="px-4 h-12 hover:bg-gray-200 transition-colors hover:no-underline select-none cursor-pointer flex items-center space-x-4">
				<icon class="w-5 h-5 flex-shrink-0" :data="icon" />
				
				<transition name="sidenav-link" mode="out-in">
					<span v-show="!collapse" class="md:block font-medium whitespace-nowrap overflow-hidden w-40">{{ title }}</span>
				</transition>
			</div>
		</template>
		
		<template #default="{ close }">
			<ul>
				<li
					v-for="link in links"
					:key="link.path"
					class="hover:bg-gray-200 hover:no-underline transition-colors duration-200 cursor-pointer">
					<router-link
						:to="link.path"
						@click.native.prevent="itemSelected(close)"
						class="block px-4 py-1 text-gray-400 text-sm font-medium hover:text-gray-600 hover:no-underline"
						:class="collapse ? 'pl-4' : 'pl-8'">
						{{ link.title }}
					</router-link>
				</li>
			</ul>
		</template>
	</Accordion>
</template>
<script>
import Accordion from '@/components/Accordion'
export default {
	components: {
		Accordion
	},
	props: [ 'icon', 'title', 'links', 'collapse' ],
	methods: {
		itemSelected(close) {
			this.$sidebar.hide();
			close();
		}
	}
}
</script>