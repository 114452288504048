import VoucherIssue from './Voucher/VoucherIssue.vue'
import UserRoutes from './Users/routes'
import ReportRoutes from './Reports/routes'
import ProductRoutes from './Products/routes'
import OrderRoutes from './Orders/routes'
import MealRoutes from './Meal/routes'
import GiftRoutes from './GiftClaim/routes'
import VolunteerRoutes from './Volunteer/routes'
import SettingsPage from './Settings/SettingsPage'

export default [{
	path: '/admin/voucher/issue',
	component: VoucherIssue,
	meta: {
		scope: [ 'admin:vouchers' ],
	}
}, {
	path: '/admin/settings',
	component: SettingsPage,
	meta: {
		scopes: [ 'admin:vmeal', 'cashier' ]
	}
},
	...UserRoutes,
	...ReportRoutes,
	...ProductRoutes,
	...OrderRoutes,
	...MealRoutes,
	...GiftRoutes,
	...VolunteerRoutes,
]