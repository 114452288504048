import papaparse from 'papaparse'

export default function(csvRows, exportName, linkElement) {
	const csv = papaparse.unparse(csvRows);
	const csvData = 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv);
	
	linkElement.setAttribute('href', csvData);
	linkElement.setAttribute('download', exportName);
	linkElement.click();
}

