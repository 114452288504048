<template>
	<div class="flex items-center gap-2 h-10 bg-white shadow-sm">
		
		<button class="w-10 h-10 text-center" @click="toggleSidenav">
			<icon class="w-5 h-5" :data="mdiMenu" />
		</button>
		
		<div class="flex-grow font-medium">V-Wallet</div>
		
		<div v-if="prompt">
			<button @click="installPWA" class="font-medium text-gray-400 hover:text-primary-500 space-x-1 px-2">
				<icon :data="mdiCellphoneArrowDown" />
				<span>Install</span>
			</button>
		</div>
		
		<div class="w-10 text-center">
			<router-link v-if="showSettings" to="/admin/settings">
				<icon class="w-5 h-5" :data="mdiCog" />
			</router-link>
		</div>
		
	</div>
</template>
<script>
import { mdiMenu, mdiCog, mdiCellphoneArrowDown } from '@mdi/js'
import { mapState } from 'vuex'
import { installPrompt } from '@/utils/pwaInstall'

export default {
	computed: {
		...mapState('Auth', [ 'scopes' ]),
		showSettings() {
			return (this.scopes.includes('admin:vmeal') || this.scopes.includes('cashier'));
		}
	},
	data() {
		return {
			mdiMenu,
			mdiCog,
			mdiCellphoneArrowDown,
			
			prompt: null,
		};
	},
	methods: {
		toggleSidenav() {
			this.$sidebar.toggle();
		},
		installPWA() {
			if (!this.prompt) return;
			this.prompt.prompt();
		}
	},
	mounted() {
		this.prompt = installPrompt;
	}
}
</script>