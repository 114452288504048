<template>
	<table class="table w-full">
		<thead>
			<tr>
				<th>Collected By</th>
				<th>Charge To</th>
				<th width="140px">Company</th>
				<th width="140px">BU</th>
				<th width="140px">Dept</th>
				<th width="300px">Cust. Company</th>
				<th width="300px">Cust. Name</th>
				<th class="text-right" width="140px">Amount</th>
				<th width="200px">Timestamp</th>
				<th width="80px"></th>
			</tr>
		</thead>
		<tbody>
			<tr v-if="records.length > 0" class="font-medium">
				<td colspan="7">Total</td>
				<td class="text-right">{{ totalCost }}</td>
			</tr>
			<tr v-for="r in records">
				<td>{{ r.user ? r.user.userName : '-' }}</td>
				<td>{{ r.behalf ? r.behalf.userName : '-' }}</td>
				<td>{{ r.company }}</td>
				<td>{{ r.bu }}</td>
				<td>{{ r.dept }}</td>
				<td>{{ r.customerCompany }}</td>
				<td>{{ r.customerName }}</td>
				<td class="text-right">{{ r.claimAmount }}</td>
				<td>{{ r.created }}</td>
				<td class="text-right">
					<router-link class="button small is-info" :to="`/admin/gift/view/${r.giftID}`">View</router-link>
				</td>
			</tr>
			<tr v-if="!records.length">
				<td colspan="8">No records available</td>
			</tr>
		</tbody>
	</table>
</template>
<script>

export default {
	props: [ 'records' ],
	computed: {
		totalCost() {
			let sum = 0;
			for (const i in this.records) {
				const r = this.records[i];
				sum += parseFloat(r.claimAmount);
			}
			
			return sum.toFixed(2);
		}
	}
}
</script>