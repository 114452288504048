import OrderReport from './OrderReport/OrderReport.vue'
import OrderInvoice from './OrderReport/OrderInvoice.vue'
import VoucherReport from './VoucherReport.vue'
import VoucherBalanceReport from './VoucherBalanceReport.vue'
import IntercoReport from './IntercoReport/IntercoReport.vue'
import MealPayment from './MealReport/MealPayment.vue'
import SalesReport from './SalesReport/SalesReport.vue'
import PenaltyReport from './PenaltyReport/PenaltyReport.vue'
import AppealList from './AppealList.vue'
import InvoiceReport from './InvoiceReport/InvoiceReport.vue'
import MRFReport from './MRFReport.vue'
import ProductSalesReport from './ProductSalesReport/ProductSalesReport.vue'
import GiftClaimReport from './GiftClaimReport/GiftClaimReport.vue'
import StockReport from './StockReport/StockReport.vue'

export default [{
	path: '/admin/reports/orders',
	component: OrderReport,
	meta: {
		scopes: [ 'admin:shops', 'admin:vmeal', 'cashier' ]
	}
}, {
	path: '/admin/reports/order/:orderID/invoice',
	component: OrderInvoice,
	props: true,
	meta: {
		scopes: [ 'admin:shops' ]
	}
},

{
	path: '/admin/reports/voucher',
	component: VoucherReport,
	meta: {
		scopes: [ 'admin:vouchers' ],
	}
},
{
	path: '/admin/reports/voucher/balance',
	component: VoucherBalanceReport,
	meta: {
		scopes: [ 'admin:vouchers' ],
	}
},

{
	path: '/admin/reports/interco',
	component: IntercoReport,
	meta: {
		scopes: [ 'finance' ]
	}
},
{
	path: '/admin/reports/meal',
	component: MealPayment,
	meta: {
		scopes: [ 'admin:vmeal', 'finance' ],
	}
}, {
	path: '/admin/reports/penalty',
	component: PenaltyReport,
	meta: {
		scopes: [ 'admin:vmeal', 'cashier' ]
	}
}, {
	path: '/admin/reports/appeal',
	component: AppealList,
	meta: {
		scopes: [ 'admin:vmeal' ]
	}
},

{
	path: '/admin/reports/sales',
	component: SalesReport,
	meta: {
		scopes: [ 'admin:vmeal', 'cashier', 'admin:shops' ]
	}
},

{
	path: '/admin/reports/invoice',
	component: InvoiceReport,
	meta: {
		scopes: [ 'finance' ]
	}
},

{
	path: '/admin/reports/mrf',
	component: MRFReport,
	meta: {
		scopes: [ 'admin:vmeal', 'cashier', 'admin:shops', 'finance' ]
	}
},
{
	path: '/admin/reports/products',
	component: ProductSalesReport,
	meta: {
		scopes: [ 'cashier', 'admin:shops' ]
	}
},
{
	path: '/admin/reports/gift',
	component: GiftClaimReport,
	meta: {
		scopes: [ 'admin:shops', 'cashier', 'finance' ],
	}
}, {
	path: '/admin/reports/stock',
	component: StockReport,
	meta: {
		scopes: [ 'admin:shops', 'cashier' ],
	}
}]