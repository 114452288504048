<template>
	<Container>
		<div class="flex items-center justify-between">
			<div class="text-3xl font-semibold">Meal Registration</div>
			<div class="text-3xl text-gray-400">{{ date }}</div>
		</div>
		<hr class="hr">
		
		
		<div>
			<SuggestionInput
				@selected="addUser"
				v-bind="{
					inputClass: 'input w-full',
					placeholder: 'Start typing to search user',
					suggest: suggestUser,
				}">
				
				<template v-slot="{ item }">
					<span class="font-medium mr-4">{{ item.userID }}</span>
					<span class="">{{ item.userName }}</span>
				</template>
				
			</SuggestionInput>
		</div>
		<hr class="hr">
		
		<table class="table w-full">
			<thead>
				<tr>
					<th width="150px">UserID</th>
					<th>Name</th>
					<th width="100px">BU</th>
					<th width="100px">Dept</th>
					<th width="100px"></th>
				</tr>
			</thead>
			<tbody>
				<tr v-if="!users.length">
					<td colspan="5">No users added</td>
				</tr>
				
				<tr v-for="u in users" :key="u.userID">
					<td>{{ u.userID }}</td>
					<td>{{ u.userName }}</td>
					<td>{{ u.bu }}</td>
					<td>{{ u.dept }}</td>
					<td class="text-right">
						<button class="button is-danger px-2 py-1" @click="removeUser(u.userID)">
							<icon :data="mdiClose" class="w-5 h-5" />
						</button>
					</td>
				</tr>
			</tbody>
		</table>
		
		<div class="mt-4">
			<button class="button is-accent" :class="{ loading: submitting }" @click="registerUsers">Register Users</button>
		</div>
		
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import SuggestionInput from '@/components/SuggestionInput.vue'
import { mdiClose } from '@mdi/js'

export default {
	props: [ 'date' ],
	components: {
		Container,
		SuggestionInput,
	},
	data() {
		return {
			mdiClose,
			fetching: false,
			submitting: false,
			
			users: [],
		};
	},
	methods: {
		async suggestUser(q) {
			try {
				const users = await this.$api.request('GET', `suggest/users?q=${encodeURIComponent(q)}`)
				return users;
			} catch(err) {
				console.error(err);
				this.$toast.error('Error fetching user suggestions');
			}
			return [];
		},
		addUser(user) {
			// Check if user already exists in list
			const exist = this.users.find(u => u.userID == user.userID);
			if (exist) return;
			
			this.users.push(user);
		},
		removeUser(userID) {
			const index = this.users.findIndex(u => u.userID == userID);
			if (index >= 0) this.users.splice(index, 1);
		},
		
		async registerUsers() {
			if (!this.users.length) {
				this.$toast.error('No users in list');
				return;
			}
			
			const confirm = await this.$confirm({
				title: 'Confirm Registration',
				message: `Register V-Meal for ${this.date} for selected users?`,
				buttons: 'YES_NO',
			});
			if (!confirm) return;
			
			
			this.submitting = true;
			try {
				await this.$api.request('POST', 'meal/registrations/date', {
					date: this.date,
					userIDs: this.users.map(u => u.userID),
				});
				this.users = [];
				
				this.$toast.success('Registered users successfully');
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Failed to issue voucher');
			}
			this.submitting = false;
		}
	}
}
</script>