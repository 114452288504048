<template>
	<div>
		
		<div>
			<div class="text-3xl font-semibold">#{{ order.label }}</div>
			<div class="text-2xl font-medium text-gray-400">RM {{ order.paymentAmount }}</div>
		</div>
		<hr class="hr">
		
		<div class="space-y-2 mb-4">
			<div>
				<label class="font-medium mb-1">Payment Method</label>
				<select class="input w-full" v-model="formData.paymentMethod">
					<option value="qr">QR</option>
					<option value="card">Card</option>
				</select>
			</div>
			
			<div>
				<label class="font-medium mb-1">Trace Num</label>
				<input type="text" class="input w-full" placeholder="Trace Number on receipt" v-model.trim="formData.traceNum">
			</div>
			
			<div>
				<label class="font-medium mb-1">Auth Num</label>
				<input type="text" class="input w-full" placeholder="Authentication Number on receipt" v-model.trim="formData.authNum">
			</div>
			
		</div>
		
		<div>
			<button class="button is-primary" :class="{ loading: submitting }" @click="submit">Submit</button>
		</div>
		
	</div>
</template>
<script>
export default {
	props: [ 'order' ],
	data() {
		return {
			submitting: false,
			formData: {
				paymentMethod: 'qr',
				traceNum: '',
				authNum: '',
			},
		};
	},
	methods: {
		async submit() {
			this.submitting = true;
			try {
				const res = await this.$api.request('POST', `backdate/orders`, {
					orderID: this.order.orderID,
					...this.formData,
				});
				
				this.$emit('backdate', res);
				this.$toast.success('Backdate successful');
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Failed to backdate');
			}
			this.submitting = false;
		}
	}
}
</script>