
export default {
	namespaced: true,
	state: {
		SST_RATE: 0,
		SST_INCLUSIVE: false,
		
		PENALTY_AMT: 8,
		
		OrderStatus: {
			pending: 'Pending',
			ready: 'Ready',
			paid: 'Paid',
			collected: 'Collected',
			received: 'Received',
			cancelled: 'Cancelled',
			refunded: 'Refunded',
		},
		OrderStatusClass: {
			pending: 'text-warning-500',
			ready: 'text-success-500',
			paid: 'text-accent-500',
			collected: 'text-success-600',
			received: 'text-gray-300',
			cancelled: 'text-danger-500',
			refunded: 'text-gray-400',
		},
		
		OrderTypeMap: {
			online: 'Online',
			cashier: 'Cashier',
			gift: 'Gift',
			visitorMeal: 'Visitor Meal',
		},
		
		ServiceEventType: [
			'Corporate Event: Annual Dinner',
			'Corporate Event: Night of the Stars',
			'Corporate Event: Gratitude Night',
			'Team building',
			'Visitation',
			'Training',
			'Others',
		],
		CustomerType: [
			'Customer',
			'Vendor',
			'SCP/Distributor',
			'CSR',
			'Vitroxian',
			'SubCon',
			'Others',
		],
		
		CompanyNames: {
			'': 'Uncategorized',
			VT: 'ViTrox Technologies Sdn Bhd',
			VA: 'ViTrox Academy Sdn Bhd',
			VC: 'ViTrox Corporation Bhd',
			VAG: 'ViTrox AgriTech Sdn Bhd',
			VG: 'ViTrox Green Sdn Bhd',
			ViE: 'ViE Technologies Sdn Bhd',
			LMSB: 'Luvotech Msc Sdn Bhd',
		},
		
		MealErrorsAdmin: {
			ok: {
				message: 'Claimed successfully',
				class: 'text-success-500',
			},
			claimed: {
				message: 'Already claimed today',
				class: 'text-info-500',
			},
			invalid_card: {
				message: 'Unrecognized card',
				class: 'text-danger-500',
			},
			unregistered: {
				message: 'User not registered',
				class: 'text-danger-500'
			},
			penalty: {
				message: 'Penalty pending',
				class: 'text-danger-500'
			},
			insufficient_balance: {
				message: 'Insufficient balance',
				class: 'text-danger-500',
			}
		},
		MealErrorsUser: {
			ok: {
				message: 'Attendance claimed successfully',
				class: 'text-success-500',
			},
			claimed: {
				message: 'You have already claimed your attendance today',
				class: 'text-info-500',
			},
			unregistered: {
				message: 'You did not register for V-Meal today',
				class: 'text-danger-500'
			},
			penalty: {
				message: 'You have a pending penalty record',
				class: 'text-danger-500'
			},
			invalid_payload: {
				message: 'Invalid QR code, please try again',
				class: 'text-danger-500'
			},
			
			invalid_card: {
				message: 'Unrecognized card',
				class: 'text-danger-500',
			},
			insufficient_balance: {
				message: 'Insufficient balance',
				class: 'text-danger-500',
			}
		}
	},
	getters: {
	},
	actions: {
		async load({ state }) {
			const $api = this._vm.$api;
			const res = await $api.request('GET', `constants`);
			state.SST_RATE = res.sstRate;
			state.SST_INCLUSIVE = res.sstInclusive;
		},
		clear({ state }) {
			
		}
	}
}