<template>
	<Container>
		<div class="text-3xl font-semibold">MRF Requests</div>
		<hr class="hr">
		
		<div>
			<div class="inline-flex space-x-2">
				<Datepicker v-model="startDate" />
				<Datepicker v-model="endDate" />
				<button class="button is-primary" @click="loadPage()">
					Update
				</button>
			</div>
			
			<div>
				
			</div>
		</div>
		<hr class="hr">
		
		<preloader v-if="loading" />
		<div v-else>
			<table class="table w-full text-sm">
				<thead>
					<tr>
						<th width="50px">#</th>
						<th width="120px">MRF #</th>
						<th width="100px">Type</th>
						<th width="140px">PartID</th>
						<th>Description</th>
						<th width="100px" class="text-right">Qty</th>
						<th width="100px" class="text-center">Status</th>
						<th width="120px">LegalNum</th>
						<th width="120px">PartTranNum</th>
						<th width="160px">Created</th>
						<th width="160px">Completed</th>
					</tr>
				</thead>
				<tbody>
					<tr v-if="!mrfLines.length">
						<td colspan="10">No records</td>
					</tr>
					<tr v-for="(l, li) in mrfLines" :key="li">
						<td>{{ li + 1 }}</td>
						<td>{{ l.mrfNumber }}</td>
						<td class="uppercase">{{ l.type }}</td>
						<td>{{ l.partID }}</td>
						<td>{{ l.partDesc }}</td>
						<td class="text-right">{{ l.qty }}</td>
						<td class="text-center font-medium uppercase">{{ l.status }}</td>
						<td>{{ l.legalNum }}</td>
						<td>{{ l.partTranNum }}</td>
						<td>{{ l.created }}</td>
						<td>{{ l.completed }}</td>
					</tr>
				</tbody>
			</table>
			
		</div>
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import Datepicker from '@/components/Datepicker'
import dayjs from 'dayjs'


export default {
	components: {
		Container,
		Datepicker,
	},
	data() {
		return {
			loading: false,
			
			startDate: null,
			endDate: null,
			
			mrfLines: [],
		};
	},
	methods: {
		async loadPage() {
			this.loading = true;
			try {
				
				const queries = [
					`start=${dayjs(this.startDate).format('YYYY-MM-DD')}`,
					`end=${dayjs(this.endDate).format('YYYY-MM-DD')}`,
				];
				
				
				const mrfRequests = await this.$api.request('GET', `mrf/reports?${queries.join('&')}`);
				
				const mrfLines = [];
				for (const i in mrfRequests) {
					const mrf = mrfRequests[i];
					
					for (const j in mrf.lines) {
						const l = mrf.lines[j];
						mrfLines.push({
							mrfNumber: mrf.mrfNumber,
							type: mrf.type,
							legalNum: l.legalNum,
							partTranNum: l.partTranNum,
							partID: l.partID,
							partDesc: l.partDesc,
							qty: l.qty,
							status: l.status,
							created: mrf.created,
							completed: mrf.completed,
							action: mrf.action,
							isComplete: mrf.isComplete,
						});
					}
				}
				this.mrfLines = mrfLines;
				
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error loading penalty list');
			}
			this.loading = false;
		},
	},
	mounted() {
		this.date = new Date();
		this.startDate = dayjs().startOf('month').toDate();
		this.endDate = dayjs().toDate();
		this.loadPage();
	}
}
</script>