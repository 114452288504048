<template>
	<Container>
		
		<div class="text-3xl font-semibold">Manage User</div>
		<hr class="hr">
		
		<preloader v-if="loading" />
		<div v-else>
			
			<div v-if="user" class="space-y-4">
				<div>
					<label class="font-medium mb-1">Employee ID</label>
					<input type="text" class="input w-full" readonly :value="user.userID">
				</div>
				
				<div>
					<label class="font-medium mb-1">Name</label>
					<input type="text" class="input w-full" v-model.trim="user.userName">
				</div>
				
				<div>
					<label class="font-medium mb-1">Email</label>
					<input type="email" class="input w-full" v-model.trim="user.email">
				</div>
				
				<div class="flex items-center space-x-2">
					<div class="w-1/2">
						<label class="font-medium mb-1">Badge Number</label>
						<input type="text" class="input w-full" v-model="user.badgeNum" placeholder="Scan or enter badge number">
					</div>
					<div class="w-1/2">
						<label class="font-medium mb-1">CardID</label>
						<input type="text" class="input w-full" :value="user.cardID" @change="updateBadgeNum" placeholder="Enter badge number to show cardID">
					</div>
				</div>
				
				
				<div>
					<label class="font-medium mb-1">Group</label>
					<select class="input w-full" v-model="user.groupID">
						<option v-for="g in groups" :key="g.groupID" :value="g.groupID">{{ g.groupName }}</option>
					</select>
				</div>
				
				<div>
					<label class="font-medium mb-1">Voucher Balance</label>
					<input type="text" class="input w-full" readonly :value="user.balance">
				</div>
			</div>
			
			<hr class="hr">
			
			<div v-if="user" class="space-x-2">
				<button class="button is-primary" :class="{ loading: submitting }" @click="saveUser">Save</button>
				<button class="button is-info" :class="{ loading: submitting }" @click="switchUser">Switch to User</button>
				
				<!-- Activate/deactivate -->
				<button v-if="user.status == 'active'" class="button is-danger" :class="{ loading: submitting }" @click="deactivateUser">Deactivate User</button>
				<button v-else-if="user.status == 'inactive' || user.status == 'new'" class="button is-success" :class="{ loading: submitting }" @click="activateUser">Activate User</button>
			</div>
			
			
		</div>
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import { decToHex, hexToDec } from '@/utils'

export default {
	props: [ 'userID' ],
	components: {
		Container,
	},
	watch: {
		'user.badgeNum': function() {
			if (!this.user) return '';
			this.user.cardID = decToHex(this.user.badgeNum);
		},
	},
	data() {
		return {
			loading: false,
			submitting: false,
			user: null,
			groups: [],
		}
	},
	methods: {
		async saveUser() {
			this.submitting = true;
			try {
				
				await this.$api.request('PUT', `users/${this.userID}`, {
					userName: this.user.userName,
					groupID: this.user.groupID,
					badgeNum: this.user.badgeNum,
					email: this.user.email,
				});
				this.$toast.success('User saved');
				
			} catch (err) {
				console.error(err);
				this.$toast.error('Error saving changes');
			}
			this.submitting = false;
		},
		
		async activateUser() {
			const confirm = await this.$confirm({
				title: 'Activate user',
				message: 'Confirm to activate user?',
				buttons: 'YES_NO',
			});
			if (!confirm) return;
			
			
			this.submitting = true;
			try {
				await this.$api.request('PUT', `users/${this.userID}/status?status=active`);
				this.user.status = 'active';
				
				this.$toast.info('User activated');
				
			} catch (err) {
				console.error(err);
				this.$toast.error('Error deactivating user');
			}
			this.submitting = false;
		},
		
		async deactivateUser() {
			const confirm = await this.$confirm({
				title: 'Deactivate user',
				message: 'Confirm to deactivate user?',
				buttons: 'YES_NO',
			});
			if (!confirm) return;
			
			
			this.submitting = true;
			try {
				await this.$api.request('DELETE', `users/${this.userID}`);
				
				this.$toast.info('User deactivated');
				this.$router.replace('/admin/users');
				
			} catch (err) {
				console.error(err);
				this.$toast.error('Error deactivating user');
			}
			this.submitting = false;
		},
		
		async switchUser() {
			this.submitting = true;
			try {
				
				await this.$store.dispatch('Auth/switchUser', this.userID);
				this.$router.replace('/home');
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error switching user');
			}
			this.submitting = false;
		},
		
		updateBadgeNum(e) {
			//045F343A
			if (!this.user) return '';
			this.user.badgeNum = hexToDec(e.target.value);
		}
	},
	async mounted() {
		this.loading = true;
		try {
			this.groups = await this.$api.request('GET', `groups`);
			this.user = await this.$api.request('GET', `users/${this.userID}`);
			
		} catch(err) {
			console.error(err);
			this.$toast.error('Error loading user');
		}
		this.loading = false;
	}
}
</script>