import AttendanceScan from './AttendanceScan/AttendanceScan.vue'
import RegisterList from './RegisterList.vue'
import RegisterUsers from './RegisterUsers.vue'
import MenuEntry from './MenuEntry2/MenuEntry.vue'


export default [{
	path: '/admin/meal/scan',
	component: AttendanceScan,
	meta: {
		scopes: [ 'admin:vmeal' ],
	}
}, {
	path: '/admin/meal/list',
	component: RegisterList,
	meta: {
		scopes: [ 'admin:vmeal' ],
	}
}, {
	path: '/admin/meal/register/:date',
	component: RegisterUsers,
	props: true,
	meta: {
		scopes: [ 'admin:vmeal:register' ]
	}
}, {
	path: '/admin/meal/menu',
	component: MenuEntry,
	meta: {
		scopes: [ 'admin:vmeal' ]
	}
}]