<template>
	<Container>
		<div class="text-3xl font-semibold">Appeal Reason List</div>
		<hr class="hr">
		
		<div>
			<div class="inline-flex space-x-2">
				<button class="button is-primary" @click="modifyDate(-1)">
					<icon :data="mdiArrowLeft" class="w-5 h-5" />
				</button>
				<Datepicker v-model="date" />
				<button class="button is-primary" @click="modifyDate(1)">
					<icon :data="mdiArrowRight" class="w-5 h-5" />
				</button>
			</div>
		</div>
		<hr class="hr">
		
		<preloader v-if="loading" />
		<table v-else class="table w-full">
			<thead>
				<tr>
					<th width="10%">ID</th>
					<th>Name</th>
					<th width="100px">Company</th>
					<th width="100px">BU</th>
					<th width="100px">Dept</th>
					<th width="100px">Date</th>
					<th width="200px">Timestamp</th>
					<th width="40%">Reason</th>
					<th width="40px"></th>
				</tr>
			</thead>
			<tbody>
				<tr v-if="!appeals.length">
					<td colspan="6">No records available</td>
				</tr>
				<tr v-for="(p, pi) in appeals" :key="pi">
					<td>{{ p.userID }}</td>
					<td>{{ p.user ? p.user.userName : '-' }}</td>
					<td>{{ p.user ? p.user.company : '-' }}</td>
					<td>{{ p.user ? p.user.bu : '-' }}</td>
					<td>{{ p.user ? p.user.dept : '-' }}</td>
					<td>{{ p.date }}</td>
					<td>{{ p.submitted }}</td>
					<td>
						<div class="whitespace-pre-wrap">{{ p.reason }}</div>
					</td>
					<td class="text-right">
						<div v-if="p.unwaived" class="font-medium">UNWAIVED</div>
						<button v-else
							class="button is-danger text-sm px-2 py-1"
							:class="{ loading: submitting }"
							@click="unwaivePenalty(p.userID, p.date)">Unwaive</button>
					</td>
				</tr>
			</tbody>
		</table>
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import Datepicker from '@/components/Datepicker'
import { mdiArrowLeft, mdiArrowRight } from '@mdi/js'
import dayjs from 'dayjs'

export default {
	components: {
		Container,
		Datepicker,
	},
	watch: {
		date: 'loadPage',
	},
	data() {
		return {
			mdiArrowLeft,
			mdiArrowRight,
			
			loading: false,
			submitting: false,
			
			date: null,
			appeals: [],
		};
	},
	methods: {
		modifyDate(step) {
			if (!this.date) return;
			this.date = dayjs(this.date).add(step, 'day').toDate();
		},
		async loadPage() {
			this.loading = true;
			try {
				const date = dayjs(this.date).format('YYYY-MM-DD');
				this.appeals = await this.$api.request('GET', `meal/appeal/report?date=${date}`);
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error loading appeal list');
			}
			this.loading = false;
		},
		async unwaivePenalty(userID, date) {
			this.submitting = true;
			try {
				await this.$api.request('POST', `meal/penalties/unwaive`, { userID, date });
				
				const index = this.appeals.findIndex(a => a.userID == userID && a.date == date);
				if (index >= 0) this.appeals[index].unwaived = true;
				
				this.$toast.error('Unwaived Penalty');
				
			} catch(err) {
				if (err == 'already_paid') {
					this.$toast.error('User has already paid penalty for given date');
				} else if (err == 'invalid_record') {
					this.$toast.error('Already unwaived');
				} else {
					console.error(err);
					this.$toast.error('Failed to waive penalty');
				}
			}
			this.submitting = false;
		}
	},
	mounted() {
		this.date = new Date();
	}
}
</script>