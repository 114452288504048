<template>
	<div class="px-4 py-2">
		<div v-if="payment">
			
			<div class="text-center">
			
				<!-- Payment pending -->
				<div v-if="payment.status == 'pending'">
					<div class="text-lg font-medium">Payment pending, please pay via terminal</div>
					<preloader />
				</div>
				
				<!-- Payment complete -->
				<div v-if="payment.status == 'paid'" class="flex items-center justify-center">
					<icon class="mr-4 w-8 h-8 text-success-500" :data="mdiCheckCircle" />
					<div class="text-2xl font-medium">Payment complete</div>
				</div>
			
			</div>
			<hr class="hr">
			
			<div class="text-center space-x-2">
				<button v-if="payment.status == 'pending'" class="button is-danger" :class="{ loading: submitting }" @click="cancelPayment">Cancel</button>
				
				<button v-if="payment.status == 'paid'" class="button is-primary" @click="printReceipt">Print Receipt</button>
				<button v-if="payment.status == 'paid'" class="button" @click="done">Close</button>
			</div>
			
		</div>
		
	</div>
</template>
<script>
import { mdiCheckCircle } from '@mdi/js'

export default {
	props: [ 'payment', 'penalties' ],
	data() {
		return {
			mdiCheckCircle,
			submitting: false,
		};
	},
	methods: {
		async cancelPayment() {
			if (!this.payment) return;
			
			this.submitting = true;
			try {
				// Cancel order from terminal
				await this.$devices.request('DELETE', `pay/penalty/cancel`, {
					paymentID: this.payment.paymentID,
					traceNum: null,
					amount: null,
				});
				
				//await this.$api.request('DELETE', `meal/penalties/payment/${this.payment.paymentID}`);
				
				this.$toast.info('Payment cancelled');
				this.$emit('close');
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error cancelling payment');
			}
			this.submitting = false;
		},
		
		async printReceipt() {
			if (!this.payment) return;
			
			this.submitting = true;
			try {
				const printData = {
					orderNum: this.payment.paymentID,
					paymentAmt: this.payment.paymentAmount,
					items: [],
				};
				
				const unitPrice = parseFloat(this.payment.paymentAmount) / this.penalties.length;
				printData.items = this.penalties.map(p => ({
					name: `V-Meal Penalty: ${p.date}`,
					qty: 1,
					price: unitPrice.toFixed(2),
				}));
				
				// Send to printer service
				await this.$devices.request('POST', 'print', printData);
				
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error printing receipt');
			}
			this.submitting = false;
		},
		
		done() {
			this.$emit('close');
		}
	},
}
</script>