<template>
	<div>
		<div class="text-3xl font-semibold text-center mb-4 select-none">{{ displayedDate }}</div>
		
		<div v-if="menu">
			<div v-if="menu.typeName" class="text-center font-semibold text-gray text-2xl my-2 text-gray-500">~ {{ menu.typeName }} ~</div>
			<img :src="menu.imageURL" style="height:400px" class="mx-auto object-contain object-top">
		</div>
		<div v-else>
			<div class="text-center text-gray-400 font-medium text-2xl">Menu has not been updated yet</div>
		</div>
	</div>
</template>
<script>
import dayjs from 'dayjs'

export default {
	props: [ 'menu', 'menuDate' ],
	computed: {
		displayedDate() {
			if (this.menuDate == dayjs().format('YYYY-MM-DD')) return 'Today\'s Menu';
			return `Menu for ${dayjs(this.menuDate).format('D MMM')}`;
		}
	},
}
</script>