<template>
	<Container>
		
		<div class="text-3xl font-semibold">Manage User Groups</div>
		<hr class="hr">
		
		<preloader v-if="loading" />
		<div v-else>
			
			<div class="flex items-center space-x-2">
				<select class="input flex-grow" v-model="activeGroup">
					<option :value="null" disabled>Select group</option>
					<option v-for="g in groups" :key="g.groupID" :value="g.groupID">{{ g.groupName }}</option>
				</select>
				
				<button class="button is-primary" @click="showCreateForm = true">Create Group</button>
			</div>
			<hr class="hr">
			
			<div v-if="activeGroup != null">
				<table class="table w-full">
					<tr>
						<th width="40px" class="text-center">
							<input type="checkbox" v-model="selectAll" @change="toggleAll">
						</th>
						<th>Description</th>
					</tr>
					<tr v-for="s in scopes" :key="s.scopeID">
						<td class="text-center">
							<input type="checkbox" v-model="selectedScopes" :value="s.scopeID">
						</td>
						<td>{{ s.description }}</td>
					</tr>
				</table>
				<hr class="hr">
				
				
				<div class="space-x-2">
					<button class="button is-primary" :class="{ loading: submitting }" @click="save">Save</button>
					<button class="button is-danger" :class="{ loading: submitting }" @click="deleteGroup">Delete</button>
				</div>
			</div>
			
			
		</div>
		
		
		<Modal v-model="showCreateForm" containerClass="w-3/5 px-4 py-2">
			<div class="text-2xl font-medium">Create Group</div>
			<hr class="hr">
			
			<div>
				<label class="label">Group Name</label>
				<input type="text" class="input w-full" v-model.trim="newGroupName" placeholder="Group name">
			</div>
			<hr class="hr">
			
			<div class="space-x-2">
				<button class="button is-primary" :class="{ loading: submitting }" @click="createGroup">Create</button>
			</div>
			
		</Modal>
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import Modal from '@/components/Modal.vue'

export default {
	components: {
		Container,
		Modal,
	},
	watch: {
		activeGroup() {
			const group = this.groups.find(g => g.groupID == this.activeGroup);
			if (!group) return;
			
			this.selectedScopes = group.scopes;
		}
	},
	data() {
		return {
			loading: false,
			submitting: false,
			
			showCreateForm: false,
			selectAll: false,
			newGroupName: '',
			activeGroup: null,
			selectedScopes: [],
			
			groups: [],
			scopes: [],
		};
	},
	methods: {
		toggleAll() {
			if (this.selectAll) this.selectedScopes = this.scopes.map(s => s.scopeID);
			else this.selectedScopes = [];
		},
		async createGroup() {
			if (!this.newGroupName) {
				this.$toast.error('Please enter group name');
				return;
			}
			
			this.submitting = true;
			try {
				
				const res = await this.$api.request('POST', `groups`, { groupName: this.newGroupName });
				this.groups.push(res);
				
				this.newGroupName = '';
				this.showCreateForm = false;
				this.$toast.success('Group created');
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error creating group');
			}
			this.submitting = false;
		},
		async save() {
			const group = this.groups.find(g => g.groupID == this.activeGroup);
			if (!group) return;
			
			this.submitting = true;
			try {
				await this.$api.request('PUT', `groups/${this.activeGroup}`, {
					groupName: group.groupName,
					scopes: this.selectedScopes,
				});
				
				this.$toast.success('Group saved');
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error saving group');
			}
			this.submitting = false;
		},
		async deleteGroup() {
			const confirm = await this.$confirm({
				title: 'Delete Group',
				message: 'Confirm to delete this group?',
				buttons: 'YES_NO',
			});
			if (!confirm) return;
			
			
			this.submitting = true;
			try {
				
				await this.$api.request('DELETE', `groups/${this.activeGroup}`);
				
				this.activeGroup = 0;
				this.$toast.info('Group deleted');
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error deleting group');
			}
			this.submitting = false;
		}
	},
	async mounted() {
		this.loading = true;
		try {
			this.groups = await this.$api.request('GET', `groups`);
			this.scopes = await this.$api.request('GET', `scopes`);
			
		} catch(err) {
			console.error(err);
			this.$toast.error('Error loading page');
		}
		this.loading = false;
	}
}
</script>