<template>
	<Container>
		<div class="text-3xl font-semibold">Gift Claim Details</div>
		<hr class="hr">
		
		<preloader v-if="loading" />
		<div v-else-if="record">
			<div class="flex space-x-4">
				<div class="w-1/2 space-y-4">
					<div>
						<label class="label">Collect By</label>
						<input type="text" class="input w-full" readonly :value="record.user ? record.user.userName : '-'">
					</div>
					<div>
						<label class="label">On Behalf</label>
						<input type="text" class="input w-full" readonly :value="record.behalf ? record.behalf.userName : '-'">
					</div>
					<div>
						<label class="label">Company</label>
						<input type="text" class="input w-full" readonly :value="record.company">
					</div>
					<div>
						<label class="label">Company</label>
						<input type="text" class="input w-full" readonly :value="record.company">
					</div>
					
					<div>
						<label class="label">BU</label>
						<input type="text" class="input w-full" readonly :value="record.bu">
					</div>
					
					<div>
						<label class="label">Department</label>
						<input type="text" class="input w-full" readonly :value="record.dept">
					</div>
				</div>
				
				<div class="w-1/2 space-y-4">
					<div>
						<label class="label">Type</label>
						<input type="text" class="input w-full uppercase" readonly :value="record.type">
					</div>
					
					<div>
						<label class="label">Purpose</label>
						<input type="text" class="input w-full" readonly :value="record.purpose">
					</div>
					
					<template v-if="record.type == 'external'">
						<div>
							<label class="label">Customer Type</label>
							<input type="text" class="input w-full" readonly :value="record.customerType">
						</div>
						
						<div>
							<label class="label">Customer Company</label>
							<input type="text" class="input w-full" readonly :value="record.customerCompany">
						</div>
						
						<div>
							<label class="label">Customer Name</label>
							<input type="text" class="input w-full" readonly :value="record.customerName">
						</div>
						
						<div>
							<label class="label">VGR #</label>
							<input type="text" class="input w-full" readonly :value="record.vgr">
						</div>
					</template>
					<div>
						<label class="label">Remarks</label>
						<textarea class="input w-full" readonly>{{ record.remarks }}</textarea>
					</div>
				</div>
			</div>
			
			<hr class="hr">
			
			<div class="text-2xl font-medium mb-4">Claim Items</div>
			<table class="table w-full">
				<tr>
					<th>Item</th>
					<th width="200px" class="text-right">Qty</th>
					<th width="200px" class="text-right">Unit Price (RM)</th>
					<th width="200px" class="text-right">Total Price (RM)</th>
				</tr>
				<tr v-for="(it, ii) in record.items" :key="ii">
					<td>{{ it.productName }} - {{ it.variantName }}</td>
					<td class="text-right">{{ it.qty }}</td>
					<td class="text-right">{{ it.unitPrice }}</td>
					<td class="text-right">{{ it.totalPrice }}</td>
				</tr>
				<tr>
					<td class="text-xl text-right font-medium" colspan="3">Total</td>
					<td class="text-xl text-right font-medium">RM {{ totalPrice }}</td>
				</tr>
			</table>
			
			
		</div>
		
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
export default {
	props: [ 'giftID' ],
	components: {
		Container,
	},
	data() {
		return {
			loading: false,
			record: null,
			totalPrice: '',
		};
	},
	async mounted() {
		this.loading = true;
		try {
			this.record = await this.$api.request('GET', `gifts/records/${this.giftID}`);
			let totalPrice = 0;
			for (const i in this.record.items) {
				const item = this.record.items[i];
				const price = parseFloat(item.totalPrice);
				totalPrice += price;
			}
			this.totalPrice = totalPrice.toFixed(2);
			
		} catch(err) {
			console.error(err);
			this.$toast.error('Error loading page');
		}
		this.loading = false;
	}
}
</script>