<template>
	<div>
		
		<div>
			<div class="flex gap-2">
			<input type="text" class="input flex-grow" placeholder="Scan badge" v-model.trim="badgeNum" @keydown.enter="fetchUser">
			<button v-if="user" class="button is-primary px-3" @click="clearUser">
				<icon :data="mdiClose" class="w-5 h-5" />
			</button>
			</div>
			
			<div v-if="user" class="px-2 mt-2">
				<div class="text-xl font-medium">
					<span class="text-gray-400 inline-block mr-2">{{ user.userID }}</span>
					<span class="inline-block">{{ user.userName }}</span>
				</div>
				<div><span class="text-gray-400">Voucher Balance: </span><span class="font-medium">RM {{ voucherBalance }}</span></div>
			</div>
			
		</div>
		<hr class="hr">
		
		<preloader v-if="loading" />
		<div v-else class="">
			<div class="flex items-end justify-between">
				<div class="text-sm text-gray-500">Order Total</div>
				<div class="text-xl font-medium">{{ orderTotal.toFixed(2) }}</div>
			</div>
			<hr class="hr my-1">
			
			
			<div v-if="SST_RATE">
				<div class="flex items-end justify-between">
					<div class="text-sm text-gray-500">SST</div>
					<div class="text-xl font-medium">{{ taxAmount.toFixed(2) }}</div>
				</div>
				<hr class="hr my-1">
			</div>
			
			<!-- Rounding adjustment -->
			<div class="flex items-end justify-between">
				<div class="text-sm text-gray-500">Rounding Adjustment</div>
				<div class="text-xl font-medium">{{ roundingAmount.toFixed(2) }}</div>
			</div>
			<hr class="hr my-1">
			
			<div class="flex items-end justify-between">
				<div class="text-sm text-gray-500">Voucher Discount</div>
				<div class="text-xl font-medium">-{{ voucherDiscount.toFixed(2) }}</div>
			</div>
			<hr class="hr my-1">
			
			
			<div class="flex items-end justify-between">
				<div class="text-sm text-gray-500">Amount Due</div>
				<div class="text-2xl font-medium">RM {{ paymentAmount.toFixed(2) }}</div>
			</div>
			<hr class="hr mt-1">
			
			
			
			<div class="text-center text-xl font-medium mb-1">Payment Method</div>
			<div class="flex item-center space-x-2">
				<button class="button is-primary w-1/2 text-xl font-medium" :class="{ loading: submitting }" @click="makePayment('card')">CARD</button>
				<button class="button is-primary w-1/2 text-xl font-medium" :class="{ loading: submitting }" @click="makePayment('qr')">QR</button>
			</div>
			
		</div>
		
		
	</div>
</template>
<script>
import { mapState } from 'vuex'
import { mdiClose } from '@mdi/js'

export default {
	props: [ 'checkoutItems', 'user' ],
	computed: {
		...mapState('Cashier', [ 'queueNumEnabled' ]),
		...mapState('Constants', [ 'SST_RATE', 'SST_INCLUSIVE' ]),
		voucherBalance() {
			return this.balance ? this.balance : '0.00';
		},
		orderTotal() {
			let sum = 0;
			for (const i in this.checkoutItems) {
				const c = this.checkoutItems[i];
				
				const price = c.qty * c.price;
				if (c.isTaxable && this.SST_INCLUSIVE) sum += price * (100 - this.SST_RATE) / 100;
				else sum += price;
			}
			return sum;
		},
		taxAmount() {
			let sum = 0;
			for (const i in this.checkoutItems) {
				const c = this.checkoutItems[i];
				if (!c.isTaxable) continue;
				const taxRaw = (c.qty * c.price) * (this.SST_RATE / 100);
				sum += parseFloat(taxRaw.toFixed(2));
			}
			return sum;
		},
		voucherDiscount() {
			if (!this.balance) return 0;
			const due = this.orderTotal + this.taxAmount + this.roundingAmount;
			if (due > this.balance) return parseFloat(this.balance);
			return due;
		},
		roundingAmount() {
			const due = this.orderTotal + this.taxAmount;
			let rem = Math.round((due * 100) % 5);
			if (rem < 3) rem = -rem;
			else rem = 5 - rem;
			return rem / 100;
		},
		paymentAmount() {
			return this.orderTotal + this.taxAmount - this.voucherDiscount + this.roundingAmount;
		},
		voucherTaxLines() {
			const paymentBeforeDiscount = this.orderTotal + this.taxAmount;
			
			const lines = {};
			for (const i in this.checkoutItems) {
				const c = this.checkoutItems[i];
				const key = `${c.productID}-${c.variantID}`;
				
				const price = c.qty * c.price;
				
				let tax = 0;
				if (c.isTaxable) {
					const taxRaw = (c.qty * c.price) * (this.SST_RATE / 100);
					tax = parseFloat(taxRaw.toFixed(2));
				}
				
				const pricePortion = (price / paymentBeforeDiscount) * this.voucherDiscount;
				const taxPortion = (tax / paymentBeforeDiscount) * this.voucherDiscount;
				
				if (pricePortion > 0) {
					lines[key] = {
						voucherPrice: pricePortion.toFixed(2),
						voucherTax: taxPortion.toFixed(2),
						voucherWasTaxed: c.isTaxable,
					};
				}
			}
			return lines;
		}
	},
	watch: {
		user() {
			if (!this.user) {
				this.badgeNum = '';
				this.balance = '';
			}
		},
	},
	data() {
		return {
			mdiClose,
			loading: false,
			submitting: false,
			badgeNum: '',
			
			//user: null,
			balance: '',
		};
	},
	methods: {
		async fetchUser() {
			this.loading = true;
			try {
				
				const res = await this.$api.request('GET', `cashier/badge?badge=${this.badgeNum}`);
				if (res) {
					//this.user = res.user;
					this.balance = res.balance;
					this.$emit('scan', res.user);
				} else {
					//this.user = null;
					//this.balance = '';
					this.$emit('scan', null);
				}
				
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error fetching user');
			}
			this.loading = false;
		},
		clearUser() {
			//this.user = null;
			this.badgeNum = '';
			this.balance = '';
			this.$emit('clear');
		},
		async makePayment(method) {
			if (!this.checkoutItems.length) return;
			
			this.submitting = true;
			try {
				
				const checkoutItems = this.checkoutItems.map(c => {
					const l = this.voucherTaxLines[`${c.productID}-${c.variantID}`];
					
					// Calculate per line tax amount
					const price = c.qty * c.price;
					const sstRate = this.SST_RATE / 100.0;
					
					let taxPrice = 0;
					if (c.isTaxable) taxPrice = price * sstRate;
					
					return {
						...c,
						...l,
						taxPrice,
					}
				});
				
				
				// Submit payment request to backend, which should trigger the terminal
				// Display pending modal in parent component until payment is complete, which triggers the backend
				// Backend will then trigger a cashier/payment_done event through ws
				const order = await this.$api.request('POST', 'cashier/payment', {
					userID: this.user ? this.user.userID : null,
					queueNumEnabled: this.queueNumEnabled,
					
					paymentAmount: this.paymentAmount.toFixed(2),
					voucherAmount: this.voucherDiscount.toFixed(2),
					roundingAmount: this.roundingAmount.toFixed(2),
					taxAmount: this.taxAmount.toFixed(2),
					
					items: checkoutItems,
				});
				
				// Update user balance
				if (this.user && order.voucherBalance != null) {
					this.balance = order.voucherBalance.toFixed(2);
				}
				
				// Attach payment method here
				order.paymentMethod = method;
				this.$emit('pending', order);
				
			} catch(err) {
				console.error(err);
				
				if (err == 'invalid_stock_balance') {
					await this.$confirm({
						title: 'Inventory Shortage',
						message: 'This item is out of stock.',
						buttons: 'OK_CANCEL'
					}); 
				} else {
					this.$toast.error('Error making payment');
				}
			}
			this.submitting = false;
		}
	}
}
</script>