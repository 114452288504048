import OrderList from './OrderList.vue'
import OrderPage from './OrderPage.vue'
import PaymentResult from './PaymentResult.vue'

export default [{
	path: '/orders',
	component: OrderList
}, {
	path: '/order/:orderID',
	component: OrderPage,
	props: true,
},


{
	// Order payment result page, redirected from payment gateway after payment done
	path: '/order/:orderID/payment',
	component: PaymentResult,
	props(route) {
		return {
			orderID: route.params.orderID,
			errCode: route.query.err,
		}
	},
	meta: {
		scopes: 'public'
	},
}]