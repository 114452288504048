// We will be compilng our app as a library, for embedding inside V-One
// This file will still serve as the main entry point if we wish to test our app outside of V-One

import Vue from 'vue'
import App from '@/views/App/WebApp'
Vue.config.productionTip = false


import router from '@/router'
import routeGuard from '@/route_guard'
import store from '@/store'
import plugins from '@/plugins'
import { registerServiceWorker } from '@/plugins/ServiceWorker'



(async function() {
	await registerServiceWorker();
	
	// Initialize plugins
	await plugins(Vue);
	
	try {
		const $store = store();
		await $store.dispatch('Auth/refresh');
		
		// Install guards for each route
		router.beforeEach(routeGuard($store));
		
		//if (!sessionRecovered) router.replace('/login');
		new Vue({
			store: $store,
			router,
			render: h => h(App),
		}).$mount('#app')
		
	} catch(err) {
		console.error(err);
	}
})();
