import axios from 'axios'


export default function() {
	return {
		install(Vue, options) {
			let _authType = options.authType || 'Authorization';
			let _token = null;
			let _company = '';
			
			// Init axios instance
			const _rest = axios.create({
				baseURL: options.host
			});
			
			// Used to catch errors
			_rest.interceptors.response.use(res => {
				return res.data;
			}, err => {
				let errMsg = `Unknown Error: ${err.message}`;
				
				// Response is a valid response from the server, return the error message
				if (err.response) errMsg = err.response.data;
				else if (err.request) errMsg = `Request error: ${err.message}`;
				
				return Promise.reject(errMsg);
			});
			
			
			
			Vue.prototype[`$${options.handle}`] = {
				setToken: (t, authType) => {
					_token = t;
					if (authType) _authType = authType;
				},
				
				setCompany: (company) => {
					_company = company;
				},
				
				request: (method, path, data, options) => {
					// Default headers
					const headers = {};
					
					// Add authorization header if token is set
					if (_token) headers[_authType] = _token;
					
					// Header to identify originating company
					if (_company) headers['company'] = _company;
					
					
					if (options) {
						// Merge extra headers with our current headers
						headers = {
							...headers,
							...options.headers,
						};
					}
					
					// Return request promise
					return _rest.request({
						method,
						url: path,
						data,
						headers,
					});
				},
				
			}
		}
	}
}