<template>
	<Container>
		<preloader v-if="loading" />
		<div v-else-if="order">
			<div class="text-3xl font-medium mb-2">Invoice #{{ order.label }}</div>
			<div v-if="order.user" class="text-xl text-gray-500 font-medium mb-4">{{ order.user.userName }}</div>
			
			<div class="mb-4">
				<div class="font-medium text-sm">Invoice Date</div>
				<div>{{ invoiceDate }}</div>
			</div>
			
			<!--
			<div class="flex space-x-2">
				<div class="w-1/2">
					<div class="font-medium text-sm">Billing Address</div>
					<div>1, Lorong Pertama,<br>12345 Pulau Pinang</div>
				</div>
				<div class="w-1/2">
					<div class="font-medium text-sm">Shipping Address</div>
					<div>1, Lorong Pertama,<br>12345 Pulau Pinang</div>
				</div>
			</div>
			-->
			<hr class="hr">
			
			
			<table class="table w-full text-sm">
				<thead>
					<tr>
						<th>Product</th>
						<th width="15%" class="text-right">Unit Price (RM)</th>
						<th width="10%" class="text-right">Qty</th>
						<th width="15%" class="text-right">Total (RM)</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(it, ii) in order.items" :key="ii">
						<td>{{ it.productName }}: {{ it.variantName }}</td>
						<td class="text-right">{{ it.unitPrice }}</td>
						<td class="text-right">{{ it.qty }}</td>
						<td class="text-right">{{ it.totalPrice }}</td>
					</tr>
					
					<tr>
						<td colspan="3" class="text-right">Order Total</td>
						<td class="text-right">{{ orderAmount }}</td>
					</tr>
					
					<tr v-if="order.voucherAmount > 0">
						<td colspan="3" class="text-right">Voucher Applied</td>
						<td class="text-right">-{{ order.voucherAmount }}</td>
					</tr>
					
					<tr class="text-lg font-medium">
						<td colspan="3" class="text-right">Total Payment</td>
						<td class="text-right">RM {{ order.paymentAmount }}</td>
					</tr>
					
				</tbody>
			</table>
			
			
			<div class="mt-4">
				<div class="font-medium text-sm">Remarks</div>
				<div class="whitespace-pre-wrap">{{ order.remarks || 'No remarks written' }}</div>
			</div>
			
		</div>
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import dayjs from 'dayjs'

export default {
	props: [ 'orderID' ],
	components: {
		Container,
	},
	computed: {
		invoiceDate() {
			if (!this.order) return '';
			return dayjs(this.order.created).format('D MMMM YYYY');
		},
		orderAmount() {
			if (!this.order) return '';
			return (this.order.paymentAmount * 1 + this.order.voucherAmount * 1).toFixed(2);
		}
	},
	data() {
		return {
			loading: false,
			order: null,
		};
	},
	async mounted() {
		this.loading = true;
		try {
			this.order = await this.$api.request('GET', `orders/${this.orderID}/details`);
			
		} catch (err) {
			console.error(err);
			this.$toast.error('Error loading invoice');
		}
		this.loading = false;
	}
}
</script>