<template>
	<Container>
		<div class="text-3xl font-semibold">V-Engagement Summary</div>
		<hr class="hr">
		
		<div class="mb-4">
			<label class="font-medium mb-1">Select Quarter</label>
			<select class="input md:w-1/4 w-full" v-model="season" @change="fetchRecords">
				<option v-for="s in seasons" :key="s">{{ s }}</option>
			</select>
		</div>
		
		<preloader v-if="loading" />
		<div v-else class="px-2">
			<table class="table w-full">
				<thead>
					<tr>
						<th width="50px"></th>
						<th width="140px">ManagerID</th>
						<th>Manager Name</th>
						<th width="120px">Company</th>
						<th width="120px">BU</th>
						<th width="120px">Dept</th>
						<th class="text-right" width="100px">Progress</th>
						<th class="text-right" width="100px">Rate %</th>
					</tr>
				</thead>
				<tbody>
					<tr v-if="!records.length">
						<td colspan="8">No records available</td>
					</tr>
					<tr v-for="(r, ri) in records" :key="ri">
						<td>{{ ri + 1 }}</td>
						<td>{{ r.managerID }}</td>
						<td>{{ r.managerName }}</td>
						<td>{{ r.company }}</td>
						<td>{{ r.bu }}</td>
						<td>{{ r.dept }}</td>
						<td class="text-right">{{ r.complete }} / {{ r.complete + r.incomplete }}</td>
						<td class="text-right font-medium">{{ completionRate(r) }}</td>
					</tr>
					
					<tr class="text-lg font-medium">
						<td class="text-right" colspan="6">Total Claimed / Headcount</td>
						<td class="text-right">{{ totals.claimed }} / {{ totals.total }}</td>
						<td class="text-right">{{ totals.rate }}%</td>
					</tr>
				</tbody>
			</table>
			
			
		</div>
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import dayjs from 'dayjs'

export default {
	components: {
		Container,
	},
	computed: {
		totals() {
			const sums = {
				claimed: 0,
				total: 0,
				rate: 0,
			};
			for (const i in this.records) {
				const r = this.records[i];
				sums.claimed += r.complete;
				sums.total += r.complete + r.incomplete;
			}
			sums.rate = (sums.claimed / sums.total * 100).toFixed(2);
			return sums;
		}
	},
	data() {
		return {
			loading: false,
			
			season: '',
			seasons: [],
			records: [],
		};
	},
	methods: {
		completionRate(r) {
			const rate = r.complete / (r.complete + r.incomplete) * 100;
			return rate.toFixed(2);
		},
		async fetchRecords() {
			this.loading = true;
			try {
				const res = await this.$api.request('GET', `reports/engagement/1v1?season=${encodeURIComponent(this.season)}`);
				const userMap = res.userMap;
				
				const recordBins = {};
				for (const i in res.records) {
					const r = res.records[i];
					if (!recordBins[r.managerID]) {
						const m = userMap[r.managerID];
						if (!m) {
							console.log(r.managerID);
							continue;
						}
						
						recordBins[r.managerID] = {
							managerID: r.managerID,
							managerName: m.userName,
							company: m.company,
							bu: m.bu,
							dept: m.dept,
							incomplete: 0,
							complete: 0,
						};
					}
					if (r.pending == 1) recordBins[r.managerID].incomplete = r.count;
					else recordBins[r.managerID].complete = r.count;
					
				}
				
				this.records = [];
				for (const i in recordBins) {
					this.records.push({
						...recordBins[i],
					});
				}
				this.records.sort((a, b) => {
					return a.managerName.localeCompare(b.managerName);
				});
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error loading page');
			}
			this.loading = false;
		}
	},
	async mounted() {
		// Generate seasons
		const today = dayjs();
		const curYear = today.year();
		const curQ = Math.floor(today.month() / 3) + 1;
		
		const seasons = [];
		let startYear = 2023;
		while (startYear <= curYear) {
			for (let i = 0; i < 4; ++i) {
				seasons.push(`Q${i + 1} ${startYear}`);
			}
			++startYear;
		}
		this.seasons = seasons;
		this.season = `Q${curQ} ${curYear}`;
		
		this.fetchRecords();
	}
}
</script>