<template>
	<div>
		
		<div class="text-right mb-2">
			<button class="button is-primary" @click="selectFile">Add Image</button>
			<input type="file" class="hidden" ref="fileInput" @change="fileSelected">
		</div>
		
		<preloader v-if="loading" />
		<div v-else>
			<div v-if="!images.length" class="text-center text-2xl text-gray-400 py-4">
				No image added
			</div>
			<div class="grid grid-cols-4 gap-4">
				<div v-for="img in images" :key="img.attachmentID" style="height:300px;">
					
					<div class="text-center border border-gray-300 p-2 relative h-full">
						<button class="absolute button is-danger top-0 right-0 mt-2 mr-2 px-2 py-1 text-sm" @click="deleteImage(img.attachmentID)">
							<icon :data="mdiDelete" />
						</button>
						<img class="object-contain mx-auto h-full mb-4" :src="img.url" />
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>
<script>
import { mdiDelete } from '@mdi/js'
export default {
	props: [ 'productID' ],
	data() {
		return {
			mdiDelete,
			loading: false,
			submitting: false,
			
			images: [],
		};
	},
	methods: {
		selectFile() { this.$refs.fileInput.click(); },
		async fileSelected(e) {
			const file = e.target.files[0];
			if (![ 'image/png', 'image/jpeg' ].includes(file.type)) {
				e.target.value = null;
				this.$toast.error('Invalid file type');
				return;
			}
			
			this.submitting = true;
			try {
				const formData = new FormData();
				formData.append('image', file);
				
				const res = await this.$api.request('POST', `products/${this.productID}/images`, formData);
				this.images.push(res);
				
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error uploading image');
			}
			this.submitting = false;
		},
		
		async deleteImage(attachmentID) {
			const confirm = await this.$confirm({
				title: 'Delete Product Image',
				message: 'Confirm to delete this product image?',
				buttons: 'YES_NO',
			});
			if (!confirm) return;
			
			
			try {
				await this.$api.request('DELETE', `products/${this.productID}/images/${attachmentID}`);
				
				const index = this.images.findIndex(im => im.attachmentID == attachmentID);
				if (index >= 0) this.images.splice(index, 1);
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Failed to delete image');
			}
		}
	},
	async mounted() {
		this.loading = true;
		try {
			this.images = await this.$api.request('GET', `products/${this.productID}/images`);
			
		} catch(err) {
			console.error(err);
			this.$toast.error('Error loading images');
		}
		this.loading = false;
	}
}
</script>