export let registration = null;

export async function registerServiceWorker() {
	// Check for feature support
	if ('serviceWorker' in navigator) {
		window.addEventListener('load', async () => {
			registration = await navigator.serviceWorker.register(`${process.env.BASE_URL}service-worker.js`);
			console.log('Service worker registered');
		});
	}
}
