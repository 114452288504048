<template>
	<li class="hover:bg-gray-200 transition-colors duration-200 list-none">
		<router-link
			:to="path"
			@click.native.prevent="itemSelected"
			class="px-4 h-12 hover:no-underline select-none cursor-pointer flex items-center space-x-4">
			<icon class="w-5 h-5 flex-shrink-0" :data="icon" />
			<transition name="sidenav-link" mode="out-in">
				<span v-show="!collapse" class="md:block font-medium whitespace-nowrap overflow-hidden w-40">{{ title }}</span>
			</transition>
		</router-link>
	</li>
</template>
<script>
export default {
	props: [ 'icon', 'path', 'title', 'collapse' ],
	methods: {
		itemSelected() {
			this.$sidebar.hide();
		}
	}
}
</script>