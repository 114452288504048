<template>
	<Container>
		
		<div class="flex items-center space-x-2">
			<div class="flex-grow text-3xl font-semibold">Manage Products</div>
			<router-link :to="`/admin/products/new`" class="button is-accent">New Product</router-link>
			<router-link :to="`/admin/product/categories`" class="button is-primary">Manage Categories</router-link>
		</div>
		<hr class="hr">
		
		<div class="flex items-center space-x-2 mb-4">
			<input type="text" class="input w-full" v-model.trim="query" @keydown.enter="loadPage(0)" placeholder="Enter search query">
			<button class="button is-primary" :class="{ loading }" @click="loadPage(0)">Search</button>
			<button class="button" :class="{ loading }" @click="clearSearch">Clear</button>
		</div>
		
		
		<preloader v-if="loading" />
		<div v-else>
			
			<table class="table w-full">
				<thead>
					<tr>
						<th>Product Name</th>
						<th width="20%">Category</th>
						<th width="20%">Sub Category</th>
						<th width="100px" class="text-center">Variants</th>
						<th width="40px" class="text-center">Active</th>
						<th width="200px"></th>
					</tr>
				</thead>
				
				<tbody>
					<tr v-if="!products.length">
						<td colspan="6">No products available</td>
					</tr>
					<tr v-for="p in products" :key="p.productID">
						<td>{{ p.productName }}</td>
						<td>{{ p.catName }}</td>
						<td>{{ p.subcatName }}</td>
						<td class="text-center">{{ p.numVariants }}</td>
						<td class="text-center">
							<icon v-if="p.isActive" :data="mdiCheck" />
						</td>
						<td class="text-right">
							<router-link :to="`/admin/products/edit/${p.productID}`" class="button is-primary text-sm px-2 py-1">Edit</router-link>
						</td>
					</tr>
				</tbody>
			</table>
			
			
			<Pagination class="mt-4" v-bind="{ curPage, totalRows, pageLimit }" @goto="loadPage" />
			
		</div>
		
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import Pagination from '@/mixins/Pagination'
import { mdiCheck } from '@mdi/js'

export default {
	components: {
		Container,
	},
	mixins: [ Pagination ],
	data() {
		return {
			mdiCheck,
			loading: false,
			
			query: '',
			products: [],
		};
	},
	methods: {
		clearSearch() {
			this.query = '';
			this.loadPage(0);
		},
		async loadPage(page) {
			this.loading = true;
			try {
				const queries = [
					`page=${page}`,
					`limit=${this.pageLimit}`,
				];
				if (this.query) queries.push(`q=${encodeURIComponent(this.query)}`);
				
				const res = await this.$api.request('GET', `products?${queries.join('&')}`);
				this.curPage = page;
				this.products = res.results.map(p => {
					return {
						productID: p.productID,
						productName: p.productName,
						numVariants: p.variants.length,
						catName: p.category ? p.category.catName : '',
						subcatName: p.subcategory ? p.subcategory.catName : '',
						isActive: p.isActive,
					};
				});
				this.totalRows = res.total;
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error loading page');
			}
			this.loading = false;
		}
	},
	mounted() {
		this.loadPage(0);
	}
}
</script>