<template>
	<div class="h-full">
		<toast-container></toast-container>
		<confirm-dialog></confirm-dialog>
		
		
		<div class="flex flex-col h-full">
			<Navbar v-if="authenticated" class="flex-grow-0 flex-shrink-0" />
			
			
			<sidebar-wrap>
				<Sidenav
					class="overflow-y-auto bg-white h-full shadow-md flex-grow-0 flex-shrink-0"
					v-if="authenticated"
					v-bind="{ collapsible: false, showLogout: true }" />
			</sidebar-wrap>
			
			<!-- Side navigation bar -->
			<!--
			<Sidenav
				class="overflow-y-auto bg-white h-full shadow-md flex-grow-0 flex-shrink-0"
				v-if="authenticated"
				v-bind="{ collapsible: false, showLogout: true }" />
			-->
			
			<!-- Main router view -->
			<transition name="fade" mode="out-in">
				<router-view :key="$route.fullPath" class="overflow-y-auto flex-grow"></router-view>
			</transition>
		</div>
	
	</div>
</template>
<script>
import '@/scss/main.scss'
import Navbar from '@/views/Navbar/Navbar.vue'
import Sidenav from '@/views/Sidenav/Sidenav'
import { mapGetters } from 'vuex'
export default {
	components: {
		Navbar,
		Sidenav,
	},
	computed: {
		...mapGetters('Auth', [ 'authenticated' ]),
	},
};


</script>