<template>
	<!-- This chunk contains the search input and it's suggestion dropdown -->
	<div class="relative">
		<!-- Search input -->
		<div>
			<input
				type="text"
				class="input"
				:class="_inputClass"
				:placeholder="placeholder"
				:disabled="disabled"
				v-model.trim="query"
				@keydown.enter="enterPressed"
				@keydown.esc="escPressed"
				@keydown.up="cycleSelect(-1)"
				@keydown.down="cycleSelect(1)">
		</div>
		
		<!-- Search suggestions dropdown -->
		<div
			v-show="showSuggestions"
			v-on-clickaway="escPressed"
			class="bg-gray-100 py-2 border border-gray-400 rounded-b-lg shadow-lg absolute z-10 w-full">
			
			<preloader v-if="fetching" class="my-2" />
			
			<ul v-else>
				<!-- Show each suggestion -->
				<li v-for="(s, si) in suggestions"
					:key="si"
					:class="suggestionClass(si)"
					@mouseover="hoverSuggestion(si)"
					@click="selectSuggestion(s)">
					
					<!-- Slot in case we need to customize the suggestion dropdown -->
					<slot v-bind="{ item: s }">{{ s.title }}</slot>
				</li>
				
				<!-- No suggestions indicator -->
				<li v-if="!suggestions.length" class="px-4 py-1 select-none text-sm">No suggestions available.</li>
			</ul>
		</div>
	</div>
</template>
<script>
import { debounce } from '@/utils'
import clickaway from '@/mixins/clickaway'

export default {
	props: {
		placeholder: String,
		inputClass: String,
		
		disabled: Boolean,
		
		// The function invoked to retrieve suggestions
		suggest: Function,
		
		// The initial value to be assigned
		value: String,
		
		allowManualEntry: Boolean,
		clearOnSelect: {
			type: Boolean,
			default: false
		}
	},
	mixins: [ clickaway ],
	computed: {
		
		// Classes for the input element
		_inputClass() {
			let classes = [ this.inputClass ];
			if (this.icon) classes.push('pl-12');
			
			return classes;
		}
	},
	watch: {
		value(n, o) {
			if (n == '') this.clearSearch();
		},
		query(n, o) {
			if (n == '') {
				this.clearSearch();
				return;
			}
			if (this.selected) return;
			
			// Assign suggest function to debounce search if not set
			if (!this.debouncedSearch) {
				this.debouncedSearch = debounce(() => {
					this.getSuggestions();
				}, 500);
			}
			this.debouncedSearch();
		}
	},
	data() {
		return {
			fetching: false,
			showSuggestions: false,
			selected: false,
			
			query: '',
			suggestions: [],
			
			selectedIndex: -1,
		}
	},
	
	methods: {
		// Fetch suggestions from the provided suggest function
		async getSuggestions() {
			this.showSuggestions = true;
			this.fetching = true;
			
			this.selected = false;
			this.selectedIndex = -1;
			this.suggestions = [];
			try {
				// Try calling an API, replace with actual search later
				if (this.suggest) {
					this.suggestions = await this.suggest(this.query);	
				}
			} catch(err) {
				console.error(err);
			}
			this.fetching = false;
		},
		
		// Determine classes for the suggestion items (whether it's active)
		suggestionClass(si) {
			let classes = [ 'px-4 py-1 select-none cursor-pointer text-sm hover:bg-gray-600 ' ];
			let activeClasses = [ 'bg-gray-600', 'text-white' ];
			if (this.selectedIndex == si) classes.push(...activeClasses);
			return classes;
		},
		
		enterPressed() {
			// Trigger suggestions if it's not shown for some reason
			if (!this.showSuggestions) {
				this.getSuggestions();
				return;
			}
			
			
			// If nothing was selected and manual entry is enabled, hide suggestions and do nothing else
			if (this.selectedIndex < 0) {
				if (this.allowManualEntry) {
					this.selected = true;
					this.showSuggestions = false;
					this.$emit('input', this.query);
				}
				
			} else {
				// Else trigger selection of the item
				this.selectSuggestion(this.suggestions[this.selectedIndex]);
			}
		},
		escPressed() {
			const query = this.query;
			this.clearSearch();
			if (this.allowManualEntry) {
				this.query = query;
				this.$emit('input', this.query);
			}
		},
		clearSearch() {
			this.query = '';
			this.selectedIndex = -1;
			this.suggestions = [];
			this.showSuggestions = false;
			this.selected = false;
		},
		
		// Called when user presses up/down arrow keys while the suggestion list is shown
		cycleSelect(n) {
			let si = this.selectedIndex;
			si += n;
			if (si >= this.suggestions.length) si = 0;
			else if (si < 0) si = this.suggestions.length - 1;
			this.selectedIndex = si;
		},
		
		hoverSuggestion(si) { this.selectedIndex = si; },
		
		
		// Suggestion is selected either via click or enter pressed
		selectSuggestion(s) {
			this.clearSearch();
			this.selected = true;
			
			if (this.clearOnSelect) this.query = '';
			else this.query = s.title;
			
			this.$emit('input', this.query);
			this.$emit('selected', s);
		},
	},
	mounted() {
		if (this.value) {
			this.selected = true;
			this.query = this.value;
		}
	},
	beforeDestroy() {
		this.clearSearch();
	}
}
</script>