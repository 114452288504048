<template>
	<Container class="flex flex-col">
		<div class="flex items-center space-x-4">
			<div class="text-3xl font-medium flex-grow">Cashier Page</div>
			
			<div class="font-medium text-white">
				<div v-if="terminalConnected" class="bg-success-500 px-2 py-1 rounded">Connected</div>
				<div v-else class="bg-danger-500 px-2 py-1 rounded">Disconnected</div>
			</div>
			
			<div>
				<router-link to="/admin/gift" class="button is-accent">Gift Claim</router-link>
			</div>
			
		</div>
		<hr class="hr">
		
		<div class="flex-grow flex space-x-2 h-full">
			<!-- Item search -->
			<ItemList class="w-1/4" @select="addItem" />
			
			<CheckoutList class="flex-grow" v-bind="{ checkoutItems }" />
			
			<PaymentSummary class="w-1/5" v-bind="{ checkoutItems, user }" @scan="userScanned" @clear="clearUser" @pending="orderPending" />
		</div>
		
		
		<Modal v-model="paymentModalShown" containerClass="md:w-1/3" @close="closePayment">
			<PaymentDialog v-bind="{ order, items: checkoutItems }"  @close="closePayment" />
		</Modal>
		
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import Modal from '@/components/Modal.vue'
import ItemList from './ItemList.vue'
import CheckoutList from './CheckoutList.vue'
import PaymentSummary from './PaymentSummary.vue'
import PaymentDialog from './PaymentDialog.vue'
import { createWS } from '@/utils/ws'
import { mdiCog } from '@mdi/js'
import { mapGetters, mapState } from 'vuex'

export default {
	components: {
		Container,
		Modal,
		ItemList,
		CheckoutList,
		PaymentSummary,
		PaymentDialog,
	},
	computed: {
		...mapState('Cashier', [ 'queueNumEnabled' ]),
		...mapGetters('Cashier', [ 'activeTerminal' ]),
	},
	data() {
		return {
			mdiCog,
			
			paymentModalShown: false,
			order: null,
			user: null,
			
			checkoutItems: [],
			
			socket: null,
			
			intervalFn: null,
			terminalConnected: false,
		};
	},
	methods: {
		addItem(it) {
			if (it.isInventory && it.stockQty == '0.00') {
				this.$toast.error('Item is out of stock');
				return;
			}
			
			
			const existing = this.checkoutItems.find(itt => itt.productID == it.productID && itt.variantID == it.variantID);
			if (existing) {
				existing.qty += 1;
				//this.$toast.error('Item already added');
				return;
			}
			
			this.checkoutItems.push({
				productID: it.productID,
				variantID: it.variantID,
				productName: it.productName,
				variantName: it.variantName,
				price: it.price,
				isTaxable: it.isTaxable,
				qty: 1,
			});
		},
		
		// Init websocket
		initSocket() {
			const s = createWS(this.$store.state.Auth.jwt);
			s.on('order/order_paid', data => {
				if (!this.order) return;
				if (this.order.orderID != data.orderID) return;
				
				this.order.queueNum = data.queueNum;
				this.order.status = data.status;
			});
			
			this.socket = s;
		},
		
		userScanned(user) {
			this.user = user;
		},
		async orderPending(order) {
			this.order = order;
			this.paymentModalShown = true;
			console.log(order);
			if (this.order.status == 'pending') {
				// Trigger terminal payment
				try {
					await this.$devices.request('POST', 'pay/cashier', {
						orderID: this.order.orderID,
						amount: this.order.paymentAmount,
						paymentMethod: this.order.paymentMethod,
					})
				} catch(err) {
					if (err == 'Fail') {
						// Cancel order from API
						await this.$api.request('DELETE', `orders/${this.order.orderID}`);
						this.$toast.info('Payment cancelled');
						this.closePayment();
						
					} else if (err == 'terminal_timeout') {
						this.$toast.error('Payment timeout');
					} else {
						console.error(err);
						this.$toast.error('Payment failed');
					}
				}
			}
		},
		clearUser() {
			this.user = null;
		},
		closePayment() {
			this.order = null;
			this.user = null;
			this.checkoutItems = [];
			this.paymentModalShown = false;
		},
		async pingTerminal() {
			if (this.paymentModalShown) return;
			
			try {
				await this.$store.dispatch('Cashier/pingTerminal');
				
			} catch(err) {
				console.error(err);
				this.terminalConnected = false;
				await this.$confirm({
					title: 'Terminal Disconnected',
					message: 'Please restart terminal service, then refresh this page',
					buttons: 'OK',
				});
			}
		}
	},
	async mounted() {
		// Load settings and initialize
		try {
			await this.$store.dispatch('Cashier/init');
			// Init socket if cashier init is successful
			this.initSocket();
			
			this.terminalConnected = true;
			this.intervalFn = setInterval(this.pingTerminal, 60000);
			
		} catch(err) {
			if (err == 'terminal_disconnected') {
				this.terminalConnected = false;
				await this.$confirm({
					title: 'Terminal Disconnected',
					message: 'Terminal is not connected.\nPayment will not function properly.',
					buttons: 'OK'
				});
			} else {
				console.error(err);
				this.$toast.error('Failed to load settings');
			}
		}
	},
	beforeDestroy() {
		if (this.socket) this.socket.disconnect();
		if (this.intervalFn) {
			clearInterval(this.intervalFn);
			this.intervalFn = null;
		}
	}
}
</script>