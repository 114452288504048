<template>
	<div>
		
		<table class="table w-full">
			<thead>
				<tr>
					<th width="40px" class="text-center">
						<input type="checkbox" @change="toggleAll" v-model="selectAll">
					</th>
					<th width="100px">Day</th>
					<th width="200px">Date</th>
					<th>Event/Remarks</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(d, di) in dates" :key="di">
					<td class="text-center">
						<input
							v-if="!d.blocked"
							type="checkbox"
							:checked="registeredDates.includes(d.date)"
							:value="d.date"
							@change="toggleDate(d)">
						
						<!-- Show icon if already passed -->
						<icon v-else-if="d.isRegistered" :data="mdiCheck" class="w-5 h-5 text-success" />
					</td>
					<td>{{ d.day }}</td>
					<td>{{ d.date }}</td>
					<td>{{ d.holiday ? d.holiday.holidayName : '' }}</td>
				</tr>
			</tbody>
		</table>
		
		
	</div>
</template>
<script>
import dayjs from 'dayjs'
import { mdiCheck } from '@mdi/js'

export default {
	props: [ 'selectedDate', 'holidays', 'registeredDates' ],
	data() {
		return {
			mdiCheck,
			
			selectAll: false,
			dates: [],
		};
	},
	methods: {
		computeDates() {
			if (!this.selectedDate) return [];
			const y = this.selectedDate.getFullYear();
			const m = this.selectedDate.getMonth();
			
			
			const dates = [];
			const today = dayjs();
			const daysInMonth = dayjs(this.selectedDate).daysInMonth();
			for (let i = 1; i <= daysInMonth; ++i) {
				const d = dayjs(new Date(y, m, i));
				const date = d.format('YYYY-MM-DD');
				const day = d.day();
				
				const entry = {
					date,
					day: d.format('ddd'),
					holiday: null,
					blocked: false,
					isPast: false,
					isRegistered: false,
				};
				
				// Check if weekend
				if (day == 0 || day == 6) continue;
				
				// Check if date has passed
				if (d.isBefore(today, 'day') || d.isSame(today, 'day')) {
					entry.blocked = true;
					entry.isPast = true;
				}
				
				// Check if holiday
				const holiday = this.holidays.find(h => h.date == date);
				if (holiday) {
					entry.holiday = holiday;
					entry.blocked = true;
				}
				
				// Check if registered
				entry.isRegistered = this.registeredDates.includes(date);
				
				dates.push(entry);
			}
			this.dates = dates;
		},
		toggleAll() {
			for (const i in this.dates) {
				const d = this.dates[i];
				
				const index = this.registeredDates.findIndex(date => d.date == date);
				if (this.selectAll) {
					// Add those not in list
					if (index < 0) this.registeredDates.push(d.date);
				} else {
					// Remove if in list
					if (index >= 0) this.registeredDates.splice(index, 1);
				}
			}
			this.$emit('change');
		},
		toggleDate(d) {
			if (d.blocked) return;
			
			const index = this.registeredDates.findIndex(date => d.date == date);
			if (index >= 0) this.registeredDates.splice(index, 1);
			else this.registeredDates.push(d.date);
			
			// Something has changed, untick this checkbox
			this.selectAll = false;
			this.$emit('change');
		}
	},
	mounted() {
		this.computeDates();
	}
}
</script>