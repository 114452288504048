<template>
	<Container>
		<preloader v-if="loading" />
		<div v-else-if="order" class="md:w-2/5 md:mx-auto text-center">
		
			<div class="text-3xl font-semibold">Order #{{ order.label }}</div>
			<hr class="hr">
			
			
			<div v-if="orderPaid">
				<!-- Order paid, show payment details -->
				<div class="text-3xl text-success-500 font-medium mb-4">PAYMENT SUCCESSFUL</div>
				<table class="table w-full mb-4">
					<tr>
						<th class="text-right">Ref Code</th>
						<td>{{ order.transaction.tranID }}</td>
					</tr>
					<tr>
						<th class="text-right">Amount (RM)</th>
						<td>{{ order.transaction.amount }}</td>
					</tr>
					<tr>
						<th class="text-right">Transaction Timestamp</th>
						<td>{{ order.transaction.paydate }}</td>
					</tr>
				</table>
				
				<router-link to="/orders" class="button is-primary w-full">Back to My Orders</router-link>
			</div>
			
			
			<div v-else-if="errCode">
				<div class="text-3xl text-danger-500 font-medium mb-4">PAYMENT FAILED</div>
				
				<div v-if="order.transaction" class="bg-danger-100 text-danger-800 p-2 mb-4">
					<div class="font-medium mb-2">Your payment has failed with the following error:</div>
					
					<div class="">{{ order.transaction.errMsg }}</div>
				</div>
				
				<router-link :to="`/order/${orderID}`" class="button is-primary w-full">Back to Order Page</router-link>
			</div>
			
			
		</div>
		
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
export default {
	props: [ 'orderID', 'errCode' ],
	components: {
		Container,
	},
	computed: {
		orderPaid() {
			if ([ 'paid', 'ready' ].includes(this.order.status)) return true;
			return false;
		}
	},
	data() {
		return {
			loading: false,
			
			order: null,
		};
	},
	async mounted() {
		this.loading = true;
		try {
			this.order = await this.$api.request('GET', `orders/${this.orderID}/payment/details`);
			
		} catch(err) {
			console.error(err);
			this.$toast.error('Error loading page');
			this.$router.replace('/404');
		}
		this.loading = false;
	}
}
</script>