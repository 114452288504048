import ServiceRequestList from './ServiceRequestList.vue'
import ServiceRequestForm from './ServiceRequestForm.vue'
import ServiceView from './ServiceView.vue'

export default [{
	path: '/services',
	component: ServiceRequestList,
}, {
	// New service request
	path: '/service/request',
	component: ServiceRequestForm,
}, {
	// Modify service request
	path: '/service/edit/:serviceID',
	component: ServiceRequestForm,
	props: true,
}, {
	path: '/service/view/:serviceID',
	component: ServiceView,
	props: true,
}]