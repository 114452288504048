<template>
	<Container>
		
		<div class="text-3xl font-semibold">Create External User</div>
		<hr class="hr">
		
		<preloader v-if="loading" />
		<div v-else>
			
			<div class="space-y-4">
				<div>
					<label class="font-medium mb-1">User ID</label>
					<input type="text" class="input w-full" v-model.trim="formData.userID" placeholder="Employee number">
				</div>
				
				<div>
					<label class="font-medium mb-1">Name</label>
					<input type="text" class="input w-full" v-model.trim="formData.userName" placeholder="Display name">
				</div>
				
				<div>
					<label class="font-medium mb-1">Email</label>
					<input type="email" class="input w-full" v-model.trim="formData.email" placeholder="Gmail address">
					<div class="text-sm text-danger-500 italic">* Google enabled email address required</div>
				</div>
				
				<!-- Company selection -->
				<div>
					<label class="label">Company</label>
					<select class="input w-full" v-model="formData.company">
						<option value="" disabled>Select company</option>
						<option v-for="c in companyList" :key="c.company" :value="c.company">{{ c.company }}</option>
					</select>
				</div>
				
				
				<!-- BU selection -->
				<div>
					<label class="label">BU</label>
					<select class="input w-full" v-model="formData.bu">
						<option value="" disabled>Select BU</option>
						<option v-for="bu in buList" :key="bu.bu" :value="bu.bu">{{ bu.bu }}</option>
					</select>
				</div>
				
				<!-- Dept selection -->
				<div>
					<label class="label">Department</label>
					<select class="input w-full" v-model="formData.dept">
						<option value="" disabled>Select department</option>
						<option v-for="d in deptList" :key="d.dept" :value="d.dept">{{ d.dept }}</option>
					</select>
				</div>
				
				<div class="flex items-center space-x-2">
					<div class="w-1/2">
						<label class="font-medium mb-1">Badge Number</label>
						<input type="text" class="input w-full" v-model="badgeNum" placeholder="Scan or enter badge number">
					</div>
					<div class="w-1/2">
						<label class="font-medium mb-1">CardID</label>
						<input type="text" class="input w-full" readonly :value="formData.cardID" placeholder="Enter badge number to show cardID">
					</div>
				</div>
				
			</div>
			
			<hr class="hr">
			
			<div class="space-x-2">
				<button class="button is-primary" :class="{ loading: submitting }" @click="saveUser">Save</button>
			</div>
			
			
		</div>
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import { decToHex } from '@/utils'

export default {
	components: {
		Container,
	},
	watch: {
		badgeNum() {
			this.formData.cardID = decToHex(this.badgeNum);
		}
	},
	data() {
		return {
			loading: false,
			submitting: false,
			
			companyList: [],
			buList: [],
			deptList: [],
			
			badgeNum: '',
			
			formData: {
				userID: '',
				userName: '',
				email: '',
				company: '',
				bu: '',
				dept: '',
				cardID: '',
			}
		}
	},
	methods: {
		async saveUser() {
			if (!this.formData.company) {
				this.$toast.error('Please select company');
				return;
			}
			
			
			this.submitting = true;
			try {
				
				const res = await this.$api.request('POST', `users/external`, {
					...this.formData,
				});
				
				this.$router.replace(`/admin/user/${res.userID}`);
				this.$toast.success('External user created');
				
			} catch (err) {
				if (err == 'user_exists') {
					this.$toast.error('User with same ID/email already exists');
				} else {
					console.error(err);
					this.$toast.error('Error creating user');
				}
			}
			this.submitting = false;
		},
	},
	async mounted() {
		this.loading = true;
		try {
			const { company, bu, dept } = await this.$api.request('GET', `misc/bu`);
			this.companyList = company.filter(c => c.company);
			this.buList = bu.filter(b => b.bu);
			this.deptList = dept.filter(d => d.dept);
			
		} catch(err) {
			console.error(err);
			this.$toast.error('Error loading page');
		}
		this.loading = false;
	}
}
</script>