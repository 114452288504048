<template>
	<div>
		<preloader v-if="loading" />
		<div v-else>
			<div class="my-2 text-right">
				<button class="button is-primary" :class="{ loading }" @click="fetchRecords">Refresh</button>
			</div>
			<table class="table w-full mt-4">
				<thead>
					<tr>
						<th width="200px">PartNum</th>
						<th width="200px">Ref</th>
						<th>LineDesc</th>
						<th class="text-right">Qty</th>
						<th class="text-right">Unit Price</th>
						<th class="text-right">Discount</th>
						<th class="text-right">Tax</th>
						<th class="text-right">Total Price</th>
					</tr>
				</thead>
				<tbody>
					<tr v-if="!lines.length">
						<td colspan="5">No records available</td>
					</tr>
					<tr v-if="lines.length">
						<td colspan="4" class="font-medium text-xl">Total</td>
						<td class="font-medium text-xl text-right">
							<div>{{ grandTotals.payment }}</div>
							<div class="text-gray-400 text-xs">R: {{ grandTotals.rounding }}</div>
						</td>
						<td class="font-medium text-xl text-right">{{ grandTotals.discount }}</td>
						<td class="font-medium text-xl text-right">{{ grandTotals.tax }}</td>
						<td class="font-medium text-xl text-right">
							<div>{{ grandTotals.sum }}</div>
							<div class="text-gray-400 text-xs">+{{ grandTotals.rounding }} = {{ grandTotals.roundingSum }}</div>
						</td>
					</tr>
					<tr v-for="(l, li) in lines" :key="li">
						<td>{{ l.partNum }}</td>
						<td>{{ l.label }}</td>
						<td>{{ l.lineDesc }}</td>
						<td class="text-right">{{ l.qty.toFixed(2) }}</td>
						<td class="text-right">{{ l.unitPrice }}</td>
						<td class="text-right">{{ l.discount }}</td>
						<td class="text-right">{{ l.tax }}</td>
						<td class="text-right">{{ l.totalPrice }}</td>
					</tr>
					<tr v-if="lines.length">
						<td colspan="4" class="font-medium text-xl text-right">Total</td>
						<td class="font-medium text-xl text-right">{{ grandTotals.payment }}</td>
						<td class="font-medium text-xl text-right">{{ grandTotals.discount }}</td>
						<td class="font-medium text-xl text-right">{{ grandTotals.tax }}</td>
						<td class="font-medium text-xl text-right">{{ grandTotals.sum }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>
<script>
import dayjs from 'dayjs'

export default {
	props: [ 'date' ],
	computed: {
		discount() {
			let discount = 0;
			for (const i in this.lines) {
				const l = this.lines[i];
				if (l.totalPrice < 0) discount += parseFloat(l.totalPrice);
			}
			return discount.toFixed(2);
		},
		grandTotal() {
			let totalPrice = 0;
			for (const i in this.lines) {
				const l = this.lines[i];
				totalPrice += parseFloat(l.totalPrice);
			}
			return totalPrice.toFixed(2);
		},
		grandTotals() {
			let totals = {
				payment: 0,
				discount: 0,
				tax: 0,
				rounding: 0,
				sum: 0,
				roundingSum: 0,
			};
			
			for (const i in this.lines) {
				const l = this.lines[i];
				
				// Special handling for rounding
				if (l.partNum == '3RADJM-0001-00') {
					totals.rounding += parseFloat(l.discount);
					continue;
				}
				
				const p = parseFloat(l.totalPrice);
				if (l.totalPrice < 0) totals.discount += p;
				else totals.payment += p;
				
				totals.tax += parseFloat(l.tax);
				totals.sum += p;
			}
			totals.roundingSum = (totals.rounding + totals.sum).toFixed(2);
			totals.payment = totals.payment.toFixed(2);
			totals.discount = totals.discount.toFixed(2);
			totals.tax = totals.tax.toFixed(2);
			totals.rounding = totals.rounding.toFixed(2);
			totals.sum = totals.sum.toFixed(2);
			
			return totals;
		}
	},
	watch: {
		date: 'fetchRecords'
	},
	data() {
		return {
			loading: false,
			lines: [],
		};
	},
	methods: {
		async fetchRecords() {
			this.loading = true;
			try {
				const date = dayjs(this.date).format('YYYY-MM-DD');
				const res = await this.$api.request('GET', `invoice/reports/daily?date=${date}`);
				this.lines = res;
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error loading data');
			}
			this.loading = false;
		}
	},
	mounted() {
		this.fetchRecords();
	}
}
</script>