<template>
	<Container>
		<div class="text-3xl font-semibold flex-grow">V-Meal Payments</div>
		<hr class="hr">
		
		<div class="md:flex md:items-center md:justify-end md:space-x-2 mb-6">
			<Datepicker v-model="startDate"  v-bind="{ placeholder: 'Start date' }" />
			<Datepicker v-model="endDate" v-bind="{ placeholder: 'End date' }" />
			<button class="button is-primary" :class="{ loading }" @click="update">Update</button>
		</div>
		
		<preloader v-if="loading" />
		<div v-else class="overflow-x-auto">
			<table class="table w-full">
				<thead>
					<tr>
						<th>Company</th>
						<th width="200px">BU</th>
						<th width="200px">Dept</th>
						<th width="200px" class="text-right">Subsidy Count</th>
						<th width="200px" class="text-right">Payment (RM)</th>
					</tr>
				</thead>
				<tbody>
					<tr class="text-2xl font-semibold">
						<td colspan="3">Grand Total</td>
						<td class="text-right">{{ grandTotalCount }}</td>
						<td class="text-right">{{ grandTotalPayment }}</td>
					</tr>
				</tbody>
				<tbody v-for="bin, company in companyBins">
					<tr v-if="!bin.rows.length">
						<td colspan="5">No data available</td>
					</tr>
					
					<!-- Company totals -->
					<tr class="text-xl font-medium">
						<td class="" colspan="3">{{ CompanyNames[company] }} Total</td>
						<td class="text-right">{{ bin.totalCount }}</td>
						<td class="text-right">{{ bin.totalPayment }}</td>
					</tr>
					
					<!-- Company breakdown -->
					<tr v-for="(r, key) in bin.rows" :key="key">
						<td>{{ CompanyNames[r.company] || r.company || '-' }}</td>
						<td>{{ r.bu || '-' }}</td>
						<td>{{ r.dept || '-' }}</td>
						
						<td class="text-right">{{ r.count }}</td>
						<td class="text-right">{{ r.payment }}</td>
					</tr>
					
					
					<tr>
						<td colspan="5" class="h-10"></td>
					</tr>
				</tbody>
			</table>
			
		</div>
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import Datepicker from '@/components/Datepicker'
import dayjs from 'dayjs'
import { mapState } from 'vuex'

export default {
	components: {
		Container,
		Datepicker,
	},
	computed: {
		...mapState('Constants', [ 'CompanyNames' ])
	},
	data() {
		return {
			loading: false,
			
			startDate: null,
			endDate: null,
			
			companyBins: {},
			grandTotalCount: 0,
			grandTotalPayment: 0,
		};
	},
	methods: {
		async update() {
			if (!this.startDate || !this.endDate) return;
			const start = dayjs(this.startDate).format('YYYY-MM-DD');
			const end = dayjs(this.endDate).format('YYYY-MM-DD');
			
			this.loading = true;
			try {
				const queries = [
					`start=${start}`,
					`end=${end}`,
				];
				const { attendance, penalties, paymentAmount } = await this.$api.request('GET', `meal/reports/payment?${queries.join('&')}`);
				
				let grandTotalCount = 0;
				let grandTotalPayment = 0;
				
				// Group attendance into bins
				const companyBins = {};
				for (const i in attendance) {
					const r = attendance[i];
					const payment = paymentAmount * r.count;
					
					if (!companyBins[r.company]) {
						companyBins[r.company] = {
							company: r.company,
							bu: r.bu,
							dept: r.dept,
							rows: [],
							totalCount: 0,
							totalPayment: 0,
						};
					}
					
					// Add row to company bin
					companyBins[r.company].totalCount += r.count;
					companyBins[r.company].totalPayment += payment;
					companyBins[r.company].rows.push({
						company: r.company,
						bu: r.bu,
						dept: r.dept,
						count: r.count,
						payment: payment.toFixed(2),
					});
					
					grandTotalCount += r.count;
					grandTotalPayment += payment;
				}
				
				// Assign penalties to bin
				for (const i in penalties) {
					const p = penalties[i];
					
					// Shouldn't come to here
					if (!companyBins[p.company]) continue;
					
					companyBins[p.company].totalCount -= p.count;
					companyBins[p.company].totalPayment -= p.paymentAmount;
					companyBins[p.company].rows.push({
						company: p.company,
						bu: p.bu,
						dept: p.dept,
						count: -p.count,
						payment: (-p.paymentAmount).toFixed(2),
					});
					
					grandTotalCount -= p.count;
					grandTotalPayment -= p.paymentAmount;
				}
				
				
				for (const i in companyBins) {
					const bin = companyBins[i];
					bin.totalPayment = bin.totalPayment.toFixed(2);
				}
				
				this.companyBins = companyBins;
				this.grandTotalCount = grandTotalCount;
				this.grandTotalPayment = grandTotalPayment.toFixed(2);
				
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error loading report');
			}
			this.loading = false;
		}
	},
	mounted() {
		this.startDate = dayjs().startOf('month').toDate();
		this.endDate = dayjs().endOf('month').toDate();
		this.update();
	}
}

</script>