<style lang="scss" scoped>
.month-list {
	@apply grid grid-cols-3 select-none w-full h-full text-center;
	.month {
		@apply cursor-pointer rounded px-4 py-2 transition-colors m-auto;
		&:hover { @apply bg-gray-200; }
		&.active {
			@apply bg-accent-500 text-white font-semibold;
			&:hover { @apply bg-accent-500; }
		}
		&.disabled {
			pointer-events: none;
			@apply text-gray-300;
		}
	}
}
</style>
<template>
	<div class="month-list">
		<div
			v-for="(m, mi) in months"
			class="month"
			:class="{ active: monthActive(mi), disabled: monthDisabled(mi) }"
			@click="monthSelected(mi)">
			{{ m }}
		</div>
	</div>
</template>
<script>
export default {
	props: [ 'year', 'month', 'value', 'range' ],
	data() {
		return {
			months: [
				'Jan', 'Feb', 'Mar',
				'Apr', 'May', 'Jun',
				'Jul', 'Aug', 'Sep',
				'Oct', 'Nov', 'Dec',
			]
		};
	},
	methods: {
		monthSelected(mi) {
			this.$emit('input', mi);
		},
		monthActive(mi) {
			if (this.value instanceof Date) {
				const sd = this.value;
				if (sd.getFullYear() == this.year && sd.getMonth() == mi) {
					return true;
				}
			}
			return false;
		},
		monthDisabled(mi) {
			if (this.range) {
				const { from, to } = this.range;
				if (from) {
					if (this.year < from.year()) return true;
					else if (this.year == from.year()) return mi < from.month();
				}
				
				if (to) {
					if (this.year > to.year()) return true;
					else if (this.year == to.year()) return mi > to.month();
				}
			}
			return false;
		}
	}
}
</script>