<template>
	<div>
		
		<preloader v-if="loading" />
		<div v-else>
			
			<table class="table w-full">
				<thead>
					<tr>
						<th>Order #</th>
						<th width="160px" class="text-right">Payment (RM)</th>
						<th width="160px" class="text-right">Voucher (RM)</th>
						<th width="200px">Timestamp</th>
						<th width="200px"></th>
					</tr>
				</thead>
				<tbody>
					<tr v-if="!records.length">
						<td colspan="4">No records available</td>
					</tr>
					<tr v-for="r in records" :key="r.orderID">
						<td>{{ r.label }}</td>
						<td class="text-right">{{ r.paymentAmount }}</td>
						<td class="text-right">{{ r.voucherAmount }}</td>
						<td>{{ r.created }}</td>
						<td class="text-right">
							<button class="button small is-accent" @click="showOrderDetails(r)">View Details</button>
						</td>
					</tr>
				</tbody>
			</table>
			
			<Pagination class="mt-4" v-bind="{ curPage, totalRows, pageLimit }" @goto="loadPage" />
		</div>
		
		<!-- Details modal -->
		<Modal v-model="detailsShown" containerClass="md:w-3/5">
			<OrderDetails v-bind="{ order: activeOrder }" />
		</Modal>
		
	</div>
</template>
<script>
import Pagination from '@/mixins/Pagination'
import Modal from '@/components/Modal.vue'
import OrderDetails from './OrderDetails.vue'

export default {
	components: {
		Modal,
		OrderDetails,
	},
	mixins: [ Pagination ],
	data() {
		return {
			loading: false,
			records: [],
			
			detailsShown: false,
			activeOrder: null,
		};
	},
	methods: {
		async loadPage(page) {
			this.loading = true;
			try {
				const queries = [
					`page=${page}`,
					`limit=${this.pageLimit}`,
				];
				
				const res = await this.$api.request('GET', `vouchers/transactions?${queries.join('&')}`);
				this.curPage = page;
				this.totalRows = res.total;
				this.records = res.results;
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error loading page');
			}
			this.loading = false;
		},
		showOrderDetails(o) {
			this.activeOrder = o;
			this.detailsShown = true;
		}
	},
	mounted() {
		this.loadPage(0);
	}
}
</script>