<template>
	<Container>
		<div class="text-3xl font-semibold">Penalty Report</div>
		<hr class="hr">
		
		<div>
			<div class="inline-flex space-x-2">
				<Datepicker v-model="startDate" />
				<Datepicker v-model="endDate" />
				<button class="button is-primary" @click="loadPage()">
					Update
				</button>
			</div>
		</div>
		<hr class="hr">
		
		<TabBar v-model="activeTab" v-bind="{ tabs }" />
		<transition name="fade" mode="out-in">
			<component :is="tabs[activeTab].component" :key="activeTab" v-bind="{ startDate, endDate, nonce, status: activeTab }" />
		</transition>
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import TabBar from '@/components/TabBar.vue'
import Datepicker from '@/components/Datepicker'
import PenaltyList from './PenaltyList.vue'
import PaymentList from './PaymentList.vue'
import PenaltySummary from './PenaltySummary.vue'
import dayjs from 'dayjs'

export default {
	components: {
		Container,
		Datepicker,
		TabBar,
		
		PenaltyList,
		PaymentList,
		PenaltySummary,
	},
	computed: {
		nonce() {
			if (!this.startDate || !this.endDate) return '';
			return `${dayjs(this.startDate).format('YYYYMMDD')}-${dayjs(this.endDate).format('YYYYMMDD')}-${this.activeTab}`
		}
	},
	data() {
		return {
			loading: false,
			submitting: false,
			
			activeTab: 'pending',
			tabs: {
				pending: {
					label: 'Pending',
					component: 'PenaltyList',
				},
				paid: {
					label: 'Paid',
					component: 'PenaltyList'
				},
				payment: {
					label: 'Payments',
					component: 'PaymentList'
				},
				summary: {
					label: 'Summary',
					component: 'PenaltySummary',
				}
			},
			
			startDate: null,
			endDate: null,
			
			date: null,
			penalties: [],
		};
	},
	methods: {
		async loadPage() {
			this.loading = true;
			try {
				
				const queries = [
					`start=${dayjs(this.startDate).format('YYYY-MM-DD')}`,
					`end=${dayjs(this.endDate).format('YYYY-MM-DD')}`,
				];
				
				//const date = dayjs(this.date).format('YYYY-MM-DD');
				const { penalties, users } = await this.$api.request('GET', `meal/penalties?${queries.join('&')}`);
				const userMap = {};
				for (const i in users) {
					const u = users[i];
					userMap[u.userID] = u;
				}
				
				this.penalties = penalties.map(p => ({
					...p,
					...userMap[p.userID],
					status: '',
				}));
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error loading penalty list');
			}
			this.loading = false;
		},
		
		async waivePenalty(userID, date) {
			if (!this.date) return;
			
			
			this.submitting = true;
			try {
				await this.$api.request('POST', `meal/penalties/waive`, { userID, date });
				
				const index = this.penalties.findIndex(p => p.userID == userID && p.date == date);
				if (index >= 0) this.penalties.splice(index, 1);
				
				this.$toast.success('Penalty waived');
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Failed to waive penalty');
			}
			this.submitting = false;
		},
	},
	mounted() {
		this.startDate = dayjs().startOf('month').toDate();
		this.endDate = dayjs().toDate();
		
	}
}
</script>