<style lang="scss" scoped>
.picker-btn {
	&:hover { @apply text-accent-500; }
	&:focus { @apply outline-none; }
	&.disabled {
		pointer-events: none;
		@apply text-gray-300;
	}
}
</style>
<template>
	<div class="mb-2 flex items-center px-2 py-2">
		<button @click="$emit('prev')" class="picker-btn" :class="{ disabled: !enablePrev }">
			<Icon class="w-6 h-6" :data="mdiChevronLeft" />
		</button>
		
		<div class="flex-grow text-xl text-center mx-2 select-none">
			<slot></slot>
		</div>
		
		<button @click="$emit('next')" class="picker-btn" :class="{ disabled: !enableNext }">
			<Icon class="w-6 h-6" :data="mdiChevronRight" />
		</button>
	</div>
</template>
<script>
import Icon from '../Icon'
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js'
export default {
	props: [ 'enablePrev', 'enableNext' ],
	components: { Icon },
	data() {
		return {
			mdiChevronLeft,
			mdiChevronRight,
		};
	},
}
</script>