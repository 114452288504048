<template>
	<Container>
		<div class="text-3xl font-semibold">Menu Entry</div>
		<hr class="hr">
		
		
		<div class="md:flex md:items-center md:justify-between mb-6">
			<div class="inline-flex space-x-2">
				<button class="button is-primary" @click="modifyDate(-1)">
					<icon :data="mdiArrowLeft" class="w-5 h-5" />
				</button>
				<Datepicker v-model="date" v-bind="{
					format: 'DD MMM YYYY (ddd)'
				}" />
				<button class="button is-primary" @click="modifyDate(1)">
					<icon :data="mdiArrowRight" class="w-5 h-5" />
				</button>
				
				<button class="button is-accent" @click="setToday">Today</button>
				<button class="button is-primary" @click="typeFormShown = true">Add</button>
			</div>
		</div>
		
		
		<preloader v-if="loading" />
		<div v-else class="md:flex md:gap-2">
			
			<!-- Menu for given date -->
			<div class="md:w-1/2">
				<div class="flex gap-2 items-center mb-4">
					<select class="input" v-model="typeID">
						<option :value="null">Not set</option>
						<option v-for="t in menuTypes" :value="t.typeID">{{ t.typeName }}</option>
					</select>
					
					<button class="button is-primary" :class="{ loading: submitting }" @click="saveMenu">Save</button>
				</div>
				
				
				<div v-if="activeType">
					<img style="height:400px" v-if="activeType.imageURL" :src="activeType.imageURL">
				</div>
			</div>
			
			
			<!-- List of all menus -->
			<div class="md:flex-grow">
				<TypeList v-bind="{ menuTypes }" />
				
			</div>
			
		</div>
		
		
		<Modal v-model="typeFormShown" containerClass="md:w-3/5 w-full px-3 py-2">
			<TypeForm @added="typeAdded" />
		</Modal>
		
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import Datepicker from '@/components/Datepicker'
import Modal from '@/components/Modal.vue'
import TypeForm from './TypeForm.vue'
import TypeList from './TypeList.vue'

import { mdiArrowLeft, mdiArrowRight } from '@mdi/js'
import dayjs from 'dayjs'


export default {
	components: {
		Container,
		Datepicker,
		Modal,
		TypeForm,
		TypeList,
	},
	watch: {
		date() {
			this.typeID = null;
			this.fetchMenu();
		}
	},
	computed: {
		activeType() {
			if (!this.typeID) return null;
			return this.menuTypes.find(t => t.typeID == this.typeID);
		}
	},
	data() {
		return {
			mdiArrowLeft,
			mdiArrowRight,
			
			loading: false,
			submitting: false,
			typeFormShown: false,
			
			
			menuTypes: [],
			
			date: new Date(),
			typeID: null,
			
		};
	},
	methods: {
		setToday() { this.date = new Date(); },
		modifyDate(step) {
			if (!this.date) return;
			this.date = dayjs(this.date).add(step, 'day').toDate();
		},
		
		showMenuTypeForm() { this.typeFormShown = true; },
		typeAdded(type) {
			this.menuTypes.push(type);
			this.typeFormShown = false;
			this.$toast.success('Menu added');
		},
		
		async saveMenu() {
			
			this.submitting = true;
			try {
				await this.$api.request('PUT', `meal/menu/types/${dayjs(this.date).format('YYYY-MM-DD')}`, {
					typeID: this.typeID,
				});
				
			} catch (err) {
				console.error(err);
				this.$toast.error('Failed to update menu');
			}
			this.submitting = false;
		},
		async fetchMenu() {
			this.loading = true;
			try {
				
				const res = await this.$api.request('GET', `meal/menu/types/${dayjs(this.date).format('YYYY-MM-DD')}`);
				if (res) this.typeID = res.typeID;
				
			} catch (err) {
				console.error(err);
				this.$toast.error('Failed to fetch menu');
			}
			this.loading = false;
		}
	},
	async mounted() {
		this.loading = true;
		try {
			this.menuTypes = await this.$api.request('GET', `meal/menu/types`);
			await this.fetchMenu();
			
		} catch (err) {
			console.error(err);
			this.$toast.error('Failed to load page');
		}
		this.loading = false;
	}
}
</script>