<template>
	<div>
		<div class="text-2xl font-semibold">{{ title }}</div>
		<hr class="hr">
		
		<div>
			<div>
				<label class="label">Category Name</label>
				<input type="text" class="input w-full" v-model.trim="catName">
			</div>
			<hr class="hr">
			
			<div>
				<button class="button is-primary" :class="{ loading: submitting }" @click="add">Save</button>
			</div>
		</div>
		
		
	</div>
</template>
<script>
export default {
	props: [ 'parentID' ],
	computed: {
		title() {
			if (!this.parentID) return 'New Category';
			else return 'New Subcategory';
		}
	},
	data() {
		return {
			submitting: false,
			catName: ''
		};
	},
	methods: {
		async add() {
			this.submitting = true;
			try {
				const cat = await this.$api.request('POST', `products/categories`, {
					parentID: this.parentID,
					catName: this.catName,
				});
				this.$emit('added', cat);
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error saving category');
			}
			this.submitting = false;
		}
	}
}
</script>