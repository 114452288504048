<template>
	<Container>
		<div class="md:flex md:items-center md:space-x-2">
			<div class="md:flex-grow text-3xl font-medium">Order Dashboard</div>
			<div>
				<select class="input w-full" v-model="activeMode" @change="modeChanged">
					<option v-for="(m, key) in modes" :key="key" :value="key">{{ m }}</option>
				</select>
			</div>
			<router-link to="/admin/orders/display" target="_blank" class="button is-primary">Open Display</router-link>
		</div>
		<hr class="hr">
		
		<TabBar class="mb-4" v-bind="{ tabs }" v-model="activeTab" v-slot="{ tab, tabkey }">
			<span>{{ tab }}</span>
			<span v-if="countMap[tabkey] > 0" class="bg-danger-500 text-white font-medium px-2 ml-2 rounded-full text-sm">{{ countMap[tabkey] }}</span>
		</TabBar>
		
		<preloader v-if="fetching" />
		<div v-else>
			
			<div v-if="!orders.length" class="text-center text-2xl font-medium text-gray-300 mt-8">No records available</div>
			
			<div class="grid md:grid-cols-4 grid-cols-1 gap-4 mb-8">
				<OrderCard
					v-for="o in orders"
					:key="o.orderID"
					v-bind="{ mode: activeMode, order: o }"
					@ready="orderReady(o)"
					@pay="makePayment(o)"
					@collected="orderCollected(o)"
					class="h-full" />
			</div>
			
			<Pagination v-bind="{ curPage, pageLimit, totalRows }" @goto="loadPage" />
			
		</div>
		
		<Modal v-model="showPaymentForm" containerClass="md:w-1/3 w-3/4">
			<PaymentForm v-bind="{ order: activePaymentOrder }" @paid="orderPaid" />
		</Modal>
		
	</Container>
</template>
<script>
import Container from '@/layouts/Container.vue'
import TabBar from '@/components/TabBar.vue'
import Pagination from '@/mixins/Pagination'
import Modal from '@/components/Modal.vue'
import OrderCard from './OrderCard.vue'
import PaymentForm from './PaymentForm.vue'

export default {
	components: {
		Container,
		TabBar,
		Modal,
		OrderCard,
		PaymentForm,
	},
	mixins: [ Pagination ],
	computed: {
		tabs() {
			if (this.activeMode == 'online') return this.onlineTabs;
			else if (this.activeMode == 'cashier') return this.cashierTabs;
		}
	},
	watch: {
		activeTab() {
			this.loadPage(0);
		},
	},
	data() {
		return {
			loading: false,
			fetching: false,
			searching: false,
			
			
			activeTab: 'pending',
			onlineTabs: {
				pending: 'Pending',
				ready: 'Ready',
				paid: 'Paid',
			},
			cashierTabs: {
				paid: 'Paid',
				ready: 'Ready',
			},
			
			activeMode: 'cashier',
			modes: {
				cashier: 'Cashier',
				online: 'Pre-order Items',
			},
			
			//categories: [],
			orders: [],
			countMap: {},
			
			activePaymentOrder: null,
			showPaymentForm: false,
		};
	},
	methods: {
		clearSearch() {
			this.filters.catID = '';
			this.searching = false;
		},
		search() {
			this.searching = true;
			this.loadPage(0);
		},
		async loadPage(page) {
			this.fetching = true;
			try {
				// Fetch count for each status
				this.countMap = await this.$api.request('GET', `orders/count?type=${this.activeMode}`);
				
				
				const queries = [
					`page=${page}`,
					`limit=${this.pageLimit}`,
					`status=${this.activeTab}`,
					`type=${this.activeMode}`,
				];
				
				const res = await this.$api.request('GET', `orders/list?${queries.join('&')}`);
				this.curPage = page;
				this.orders = res.results;
				this.totalRows = res.total;
				
			} catch (err) {
				console.error(err);
				this.$toast.error('Error loading page');
			}
			this.fetching = false;
		},
		
		// Trigger order ready for payment
		async orderReady(o) {
			this.fetching = true;
			try {
				await this.$api.request('PUT', `orders/${o.orderID}/ready`);
				this.$toast.success('Order readied');
				
				await this.loadPage(0);
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error readying order');
			}
			this.fetching = false;
		},
		
		// Trigger payment request to POS terminal
		makePayment(o) {
			this.activePaymentOrder = o;
			this.showPaymentForm = true;
		},
		orderPaid(orderID) {
			this.showPaymentForm = false;
			this.loadPage(0);
		},
		
		// Acknowledge order received on behalf of user
		async orderCollected(o) {
			this.fetching = true;
			try {
				await this.$api.request('PUT', `orders/${o.orderID}/collected`);
				this.$toast.success('Order closed');
				
				await this.loadPage(0);
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error readying order');
			}
			this.fetching = false;
		},
		
		modeChanged() {
			this.$router.replace(`${this.$route.path}?m=${this.activeMode}`);
		},
	},
	async mounted() {
		// Determine mode and the tab to show
		const mode = this.$route.query.m;
		if (mode) this.activeMode = mode;
		if (this.activeMode == 'online') this.activeTab = 'pending';
		else if (this.activeMode == 'cashier') this.activeTab = 'paid';
		
		
		
		this.loading = true;
		try {
			await this.loadPage(0);
			
		} catch (err) {
			console.error(err);
			this.$toast.error('Error fetching data');
		}
		this.loading = false;
	}
}
</script>