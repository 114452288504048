<template>
	<div>
		<div @click="toggle"><slot name="header" v-bind="{ expanded, close }"></slot></div>
		
		<transition :name="forceClose ? '' : 'accordion'"
			@before-enter="beforeEnter" @enter="enter"
			@before-leave="beforeLeave" @leave="leave">
			
			<div v-if="!forceClose && expanded" class="overflow-hidden duration-100">
				<slot v-bind="{ expanded, close }"></slot>
			</div>
			
		</transition>
		
	</div>
</template>
<script>
export default {
	props: [ 'forceClose' ],
	data() {
		return {
			expanded: false,
		};
	},
	methods: {
		toggle() { this.expanded = !this.expanded; },
		close() { this.expanded = false; },
		
		// Transition methods
		beforeEnter(el) { el.style.height = '0'; },
		enter(el) { el.style.height = el.scrollHeight + 'px'; },
		beforeLeave(el) { el.style.height = el.scrollHeight + 'px'; },
		leave(el) { el.style.height = '0'; }
	}
}
</script>