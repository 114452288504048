<template>
	<div class="py-4">
		
		<div class="space-y-4 text-center">
			<div class="text-lg font-medium text-gray-400 text-center">
				Please enter the number of portions to purchase and select payment method.
			</div>
			
			<input type="text" class="input w-1/4 mx-auto text-center text-xl" placeholder="Portions" v-model.number="qty">
			<hr class="hr">
			
			<div>
				<div class="text-center text-xl font-medium mb-2">Payment Method</div>
				<div class="flex item-center space-x-2 md:w-2/3 md:mx-auto">
					<button class="button is-primary w-1/2 text-xl font-medium" :class="{ loading: submitting }" @click="makePayment('card')">CARD</button>
					<button class="button is-primary w-1/2 text-xl font-medium" :class="{ loading: submitting }" @click="makePayment('qr')">QR</button>
				</div>
			</div>
		</div>
		
		
		<Modal v-model="paymentDialogShown" containerClass="w-3/5">
			<WalkinPaymentDialog v-bind="{ payment }" @close="closePaymentDialog" />
		</Modal>
	</div>
</template>
<script>
import Modal from '@/components/Modal.vue'
import WalkinPaymentDialog from './WalkInPaymentDialog.vue'

export default {
	props: [ 'socket' ],
	components: {
		Modal,
		WalkinPaymentDialog,
	},
	data() {
		return {
			submitting: false,
			
			qty: 1,
			
			paymentDialogShown: false,
			payment: null,
		};
	},
	methods: {
		async makePayment(method) {
			this.submitting = true;
			try {
				const payment = await this.$api.request('POST', 'meal/walkin/payment', {
					qty: this.qty,
				});
				
				this.payment = payment;
				this.paymentDialogShown = true;
				
				// Send request to terminal service
				await this.$devices.request('POST', `pay/walkin`, {
					paymentID: this.payment.paymentID,
					amount: this.payment.paymentAmount,
					paymentMethod: method,
				});
				
				
			} catch(err) {
				if (err == 'Fail') {
					// Cancel order from API
					await this.$api.request('DELETE', `meal/walkin/payment/${this.payment.paymentID}`);
					this.$toast.info('Payment cancelled');
					this.closePaymentDialog();
					
				} else {
					console.error(err);
					this.$toast.error('Error making payment');
				}
			}
			this.submitting = false;
		},
		paymentDone(res) {
			if (!this.payment) return;
			if (res.paymentID != this.payment.paymentID) return;
			
			// Update status
			this.payment.status = res.status;
		},
		closePaymentDialog() {
			this.paymentDialogShown = false;
			this.payment = null;
			this.qty = 1;
		}
	},
	mounted() {
		if (this.socket) this.socket.on('meal/walkin_paid', res => this.paymentDone(res));
	},
	beforeDestroy() {
		if (this.socket) this.socket.off('meal/walkin_paid');
	}
}
</script>