<style lang="scss" scoped>
.date-list {
	@apply grid grid-cols-7 text-center text-sm leading-loose select-none;
	
	.wd { @apply px-1 text-sm font-medium; }
	.d {
		@apply px-1 mb-1;
		.dd {
			@apply rounded-full cursor-pointer;
			&:hover { @apply bg-gray-200; }
			&.active, &.active:hover {
				@apply bg-accent-500 text-white;
			}
			&.disabled {
				pointer-events: none;
				@apply text-gray-300;
			}
		}
	}
}
</style>
<template>
	<!-- Week headers and dates -->
	<div class="date-list">
		
		<!-- Row of week headers -->
		<div v-for="wd in weekdayNames" class="wd">{{ wd }}</div>
		
		<!-- Individual dates -->
		<div v-for="d in monthDates" class="d">
			<div
				@click="dateSelected(d)"
				:class="{ active: dateActive(d), disabled: dateDisabled(d) }"
				class="dd">
				{{ d }}
			</div>
		</div>
		
	</div>
</template>
<script>
import dayjs from 'dayjs'
export default {
	props: [ 'year', 'month', 'date', 'value', 'range' ],
	computed: {
		monthDates() {
			let dates = [];
			
			const firstDay = dayjs(new Date(this.year, this.month, 1));
			const blankDays = firstDay.day();
			for (let i = 0; i < blankDays; ++i) {
				dates.push('');
			}
			
			for (let i = 0; i < firstDay.daysInMonth(); ++i) {
				dates.push(i + 1);
			}
			
			return dates;
		}
	},
	data() {
		return {
			weekdayNames: [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ],
		};
	},
	methods: {
		dateSelected(d) { this.$emit('input', d); },
		
		dateActive(d) {
			if (this.value instanceof Date) {
				const sd = this.value;
				if (sd.getFullYear() == this.year && sd.getMonth() == this.month && sd.getDate() == d) {
					return true;
				}
			}
			return false;
		},
		dateDisabled(d) {
			if (this.range) {
				const { from, to } = this.range;
				const now = `${this.year}${(this.month + 1).toString().padStart(2, '0')}${d.toString().padStart(2, '0')}`;
				if (from && now < from.format('YYYYMMDD')) return true;
				else if (to && now > to.format('YYYYMMDD')) return true;
			}
			return false;
		}
	}
}
</script>