<template>
	<div>
		<div>Commit: {{ commit }}</div>
		<div>Build timestamp: {{ buildTimestamp }}</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			commit: process.env.VUE_APP_BUILD_COMMIT,
			buildTimestamp: process.env.VUE_APP_BUILD_TIMESTAMP,
		}
	}
}
</script>