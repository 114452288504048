<template>
	<div>
		
		<preloader v-if="loading" />
		<div v-else>
			
			<table class="table w-full">
				<thead>
					<tr>
						<th>Remarks</th>
						<th width="160px" class="text-right">Amount (RM)</th>
						<th width="200px">Timestamp</th>
					</tr>
				</thead>
				<tbody>
					<tr v-if="!records.length">
						<td colspan="3">No records available</td>
					</tr>
					<tr v-for="r in records" :key="r.logID">
						<td>{{ formatRemarks(r) }}</td>
						<td class="text-right">{{ r.amount }}</td>
						<td>{{ r.timestamp }}</td>
					</tr>
				</tbody>
			</table>
			
			<Pagination class="mt-4" v-bind="{ curPage, totalRows, pageLimit }" @goto="loadPage" />
		</div>
		
	</div>
</template>
<script>
import Pagination from '@/mixins/Pagination'

export default {
	mixins: [ Pagination ],
	data() {
		return {
			loading: false,
			records: [],
		};
	},
	methods: {
		formatRemarks(r) {
			if (!r.category) return r.remarks;
			if (r.remarks) return `${r.category.catName} (${r.remarks})`;
			return r.category.catName;
		},
		async loadPage(page) {
			this.loading = true;
			try {
				const queries = [
					`page=${page}`,
					`limit=${this.pageLimit}`,
				];
				
				const res = await this.$api.request('GET', `vouchers/history?${queries.join('&')}`);
				this.curPage = page;
				this.totalRows = res.total;
				this.records = res.results;
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error loading page');
			}
			this.loading = false;
		}
	},
	mounted() {
		this.loadPage(0);
	}
}
</script>