<template>
	<div>
		<preloader v-if="loading" />
		<table v-else class="table w-full">
			<thead>
				<tr>
					<th width="120px">ID</th>
					<th>Name</th>
					<th>Dates</th>
					<th class="text-right" width="140px">Amount</th>
					<th width="100px">Company</th>
					<th width="100px">BU</th>
					<th width="100px">Dept</th>
					<th width="180px">Timestamp</th>
				</tr>
			</thead>
			<tbody>
				<tr v-if="!payments.length">
					<td colspan="8">No records available</td>
				</tr>
				
				<tr v-if="payments.length" class="font-medium text-lg">
					<td class="text-right" colspan="3">Total</td>
					<td class="text-right">{{ totalPayment }}</td>
				</tr>
				
				<tr v-for="(p, pi) in payments" :key="pi">
					<td>{{ p.userID }}</td>
					<td>{{ p.user.userName }}</td>
					<td>{{ p.dates.join(', ') }}</td>
					<td class="text-right">{{ p.paymentAmount }}</td>
					<td>{{ p.user.company }}</td>
					<td>{{ p.user.bu }}</td>
					<td>{{ p.user.dept }}</td>
					<td>{{ p.completed }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
import dayjs from 'dayjs'

export default {
	props: [ 'startDate', 'endDate', 'nonce', 'status' ],
	watch: {
		nonce() {
			this.loadPage()
		},
	},
	computed: {
		totalPayment() {
			let sum = 0;
			for (const i in this.payments) {
				sum += parseFloat(this.payments[i].paymentAmount);
			}
			return sum.toFixed(2);
		}
	},
	data() {
		return {
			loading: false,
			submitting: false,
			
			payments: [],
		};
	},
	methods: {
		async loadPage() {
			this.loading = true;
			try {
				
				const queries = [
					`start=${dayjs(this.startDate).format('YYYY-MM-DD')}`,
					`end=${dayjs(this.endDate).format('YYYY-MM-DD')}`,
					`status=${this.status}`,
				];
				
				this.payments = await this.$api.request('GET', `meal/penalties/payments?${queries.join('&')}`);
				
			} catch(err) {
				console.error(err);
				this.$toast.error('Error loading penalty list');
			}
			this.loading = false;
		},
		
	},
	async mounted() {
		this.loadPage();
	}
}
</script>